import { combineSlices } from '@reduxjs/toolkit';
import { canvasMapSlice } from './canvasMapSlice';
import { canvasModesSlice } from '@/store/slices/canvasModesSlice';
import { sharedSlice } from '@/store/slices/sharedSlice';
import { projectSlice } from '@/store/slices/projectSlice';
import { canvasBuildingSlice } from '@/store/slices/canvasBuildingSlice';
import { canvasExternalElementsSlice } from '@/store/slices/canvasExternalElementsSlice';
import { canvasCamerasSlice } from '@/store/slices/canvasCamerasSlice';

export const canvasReducer = combineSlices(
  canvasMapSlice,
  canvasModesSlice,
  sharedSlice,
  projectSlice,
  canvasBuildingSlice,
  canvasExternalElementsSlice,
  canvasCamerasSlice
);
