import React from 'react';
import BrowserPanelItem from '../BrowserPanelItem';
import {
  NodeType,
  PanelItemProps,
  SurroundingBuildingsStructure,
} from '@/models';
import { useUpdateProjectEnvironmentData } from '@/shared/hooks/updateProjectDataHooks/useUpdateProjectEnvironmentData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { useAppSelector } from '@/store/hooks';
import { isSurroundingBuildingSelected } from '@/store/slices/canvasBuildingSlice';

interface SurroundingBuildingsPanelItemProps extends PanelItemProps {
  surroundingBuildings: SurroundingBuildingsStructure;
}

const SurroundingBuildingsPanelItem: React.FC<
  SurroundingBuildingsPanelItemProps
> = ({
  depthLevel,
  surroundingBuildings,
  isParentSelected,
  isParentLocked,
  isParentHidden,
}) => {
  const { surroundingBuildingsUtils } = useUpdateProjectEnvironmentData();

  const { isIsolateModeEnabled } = useIsolationHandlers();
  const isNodeSelected = useAppSelector(isSurroundingBuildingSelected);

  const handleShowHideSurroundingBuildings = () => {
    surroundingBuildingsUtils.updateSurroundingBuildingsUserData(
      'isHidden',
      !surroundingBuildings.userData?.isHidden
    );
  };

  const handleLockUnlockSurroundingBuildings = () => {
    surroundingBuildingsUtils.updateSurroundingBuildingsUserData(
      'isLocked',
      !surroundingBuildings.userData?.isLocked
    );
  };

  return (
    <BrowserPanelItem
      text={'Surrounding Buildings'}
      depthLevel={depthLevel}
      isParentSelected={isParentSelected || isNodeSelected}
      isParentLocked={isParentLocked || surroundingBuildings.userData?.isLocked}
      isParentHidden={isParentHidden || surroundingBuildings.userData?.isHidden}
      isLocked={!!surroundingBuildings.userData?.isLocked}
      isHidden={!!surroundingBuildings.userData?.isHidden}
      nodeType={NodeType.SurroundingBuildings}
      elementGUID={surroundingBuildings.guid}
      lockUnlockAction={handleLockUnlockSurroundingBuildings}
      showHideAction={
        !isIsolateModeEnabled ? handleShowHideSurroundingBuildings : undefined
      }
      deleteAction={surroundingBuildingsUtils.deleteAllSurroundingBuildings}
    />
  );
};

export default SurroundingBuildingsPanelItem;
