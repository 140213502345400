import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface UseDebouncedClickProps {
  clickAction?: () => void;
  doubleClickAction: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const useDebouncedClick = ({
  clickAction,
  doubleClickAction,
}: UseDebouncedClickProps) => {
  const [clickCount, setClickCount] = useState(0);

  const debouncedSingleClick = useDebouncedCallback(() => {
    if (clickCount === 1 && clickAction) {
      clickAction();
    }
    setClickCount(0);
  }, 300);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setClickCount((prev) => prev + 1);

    if (clickCount === 1) {
      doubleClickAction(e);
      setClickCount(0);
    } else {
      debouncedSingleClick();
    }
  };

  return handleClick;
};

export default useDebouncedClick;
