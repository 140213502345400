import React, { useEffect, useMemo, useRef, useState } from 'react';
import { UnitSystemTypes } from '@/models';
import { Group, Label, Line, Tag, Text } from 'react-konva';
import {
  ACTIVE_ELEMENT_COLOR,
  DEFAULT_ELEMENT_COLOR,
  DISABLED_ELEMENT_COLOR,
  ERROR_ELEMENT_COLOR,
  INITIAL_SCALE,
  MEASUREMENT_PADDING,
} from '@/components/WindowCreator/constants';
import Konva from 'konva';
import {
  get2DAngle,
  get2DCenter,
  get2DDistance,
  rotateAroundPoint,
} from '@/shared/helpers/konva';
import {
  convertFtInchToMillimeters,
  convertMillimetersToFtInch,
} from '@/shared/helpers/distance';
import { isLeftClick } from '@/shared/helpers';
import { KonvaEventObject } from 'konva/lib/Node';

import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
} from '@/components/WindowCreator/models/konva-model';
import { degToRad } from 'three/src/math/MathUtils';
import { getWindowMeasurementLimits } from '@/components/WindowCreator/helpers/form.validators';
import { removeSpacesFromThousands } from '@/shared/helpers/format-data';
import Vector2d = Konva.Vector2d;
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getWindowCreatorMode,
  resetSelectedArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { WindowCreatorModes } from '@/models/shared.model';
import { MeasurementInput } from './MeasurementInput';
import { MeasurementArrow } from './MeasurementArrow';

interface MeasurementProps {
  points: FlatVector2Axis;
  scale: number;
  units: UnitSystemTypes;
  disabled?: boolean;
  type: MeasurementElementType;
  onMeasurementSubmit?: (data: MeasurementUpdateData) => void;
  level?: number;
}

const TEXT_PADDING = 4;
const FONT_SIZE = 12;

export const Measurement = ({
  points,
  scale,
  disabled,
  units,
  type,
  onMeasurementSubmit,
  level = 1,
}: MeasurementProps) => {
  const dispatch = useAppDispatch();

  const selectionMode =
    useAppSelector(getWindowCreatorMode) === WindowCreatorModes.Selection;
  const [measurementPoints, setMeasurementPoints] =
    useState<FlatVector2Axis>(points);
  const measurementRef = useRef<Konva.Group>(null!);
  const [measurementPadding, setMeasurementPadding] =
    useState(MEASUREMENT_PADDING);
  const labelRef = useRef<Konva.Label>(null!);
  const [text, setText] = useState<string>('');
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const [absolute, setAbsolute] = useState<Vector2d>({ y: 0, x: 0 });
  const [distance, setDistance] = useState<number>(get2DDistance(...points));
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setMeasurementPadding(
      measurementPadding > 0
        ? MEASUREMENT_PADDING * level
        : -MEASUREMENT_PADDING * level
    );
  }, [level]);

  const measurementLineCenter = useMemo(
    () => get2DCenter(...measurementPoints),
    [measurementPoints]
  );

  useEffect(() => {
    setDistance(get2DDistance(...points));
  }, [points]);

  useEffect(() => {
    labelRef?.current &&
      (absolute.x !== labelRef?.current?.absolutePosition().x ||
        absolute.y !== labelRef?.current?.absolutePosition().y) &&
      setAbsolute(labelRef?.current?.absolutePosition());
  }, [labelRef?.current?.absolutePosition()]);

  const [labelParams, setLabelParams] = useState({
    x: 0,
    y: 0,
  });

  const measurementRotationAngle = useMemo(() => {
    const angle = get2DAngle(points[0], points[1], [
      points[1][0] - measurementPadding / scale,
      points[1][1],
    ]);
    points[0][1] > points[1][1] && setMeasurementPadding(-measurementPadding);

    return angle;
  }, []);

  useEffect(() => {
    const paddingMultiplierXCoefficient = Math.round(
      Math.sin(degToRad(measurementRotationAngle))
    );
    const paddingMultiplierYCoefficient = 1 - paddingMultiplierXCoefficient;

    const updatedMeasurementPoints: FlatVector2Axis = [
      [
        points[0][0] +
          (measurementPadding / scale) * paddingMultiplierXCoefficient,
        points[0][1] -
          (measurementPadding / scale) * paddingMultiplierYCoefficient,
      ],
      [
        points[1][0] +
          (measurementPadding / scale) * paddingMultiplierXCoefficient,
        points[1][1] -
          (measurementPadding / scale) * paddingMultiplierYCoefficient,
      ],
    ];

    setMeasurementPoints(updatedMeasurementPoints);
  }, [measurementRotationAngle, measurementPadding, points]);

  const textRotationAngle = useMemo(() => {
    const angle = measurementRotationAngle;
    if (angle >= 0 && angle < 90) {
      return angle;
    }
    if (angle >= 90 && angle < 180) {
      return 360 - angle;
    }
    if (angle >= 180 && angle < 270) {
      return 180 - angle;
    }
    return angle;
  }, []);

  const onMouseEnter = () => {
    !disabled && !hovered && selectionMode && setHovered(true);
  };

  const onMouseClick = (event: KonvaEventObject<MouseEvent>) => {
    if (disabled || active || !isLeftClick(event.evt) || !selectionMode) return;
    //must be before setActive to avoid losing focus in measurement input
    dispatch(resetSelectedArea());
    setHovered(false);
    setActive(true);

    rotateAroundPoint(
      // Label being inherited from Shape, however seems types cannot track it properly
      labelRef.current as unknown as Konva.Shape,
      0,
      [measurementLineCenter[0], measurementLineCenter[1]],
      labelParams.x,
      labelParams.y
    );
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setText(
      units === UnitSystemTypes.Metric
        ? distance.toString()
        : convertMillimetersToFtInch(distance)
    );
  }, [units, distance]);

  useEffect(() => {
    if (labelRef.current) {
      setLabelParams({
        x: measurementLineCenter[0] - (labelRef.current?.getWidth() || 0) / 2,
        y: measurementLineCenter[1] - (labelRef.current?.getHeight() || 0) / 2,
      });
    }
  }, [scale, text, measurementLineCenter]);

  const resetInputToInitialState = () => {
    resetMeasurementLine();
    setText(
      units === UnitSystemTypes.Metric
        ? distance.toString()
        : convertMillimetersToFtInch(distance)
    );
  };

  useEffect(() => {
    if (labelRef.current && labelParams.x !== 0 && !active) {
      rotateMeasurementLabel(textRotationAngle);
    }
  }, [labelParams]);

  const lineColor = useMemo(() => {
    if (hasError) return ERROR_ELEMENT_COLOR;
    if (disabled) return DISABLED_ELEMENT_COLOR;
    return active ? ACTIVE_ELEMENT_COLOR : DEFAULT_ELEMENT_COLOR;
  }, [active, disabled, hasError]);

  const textColor = useMemo(() => {
    if (disabled) return DISABLED_ELEMENT_COLOR;
    return DEFAULT_ELEMENT_COLOR;
  }, [disabled, hasError]);

  const handleEscape = () => {
    resetInputToInitialState();
  };

  const handleMeasurementChange = (val: string, error: boolean) => {
    setHasError(error);
    setText(val);
  };

  const rotateMeasurementLabel = (angle: number) => {
    rotateAroundPoint(
      // Label being inherited from Shape, however seems types cannot track it properly
      labelRef.current as unknown as Konva.Shape,
      angle,
      [measurementLineCenter[0], measurementLineCenter[1]],
      labelParams.x,
      labelParams.y
    );
  };

  const resetMeasurementLine = () => {
    setHovered(false);
    setActive(false);
    setHasError(false);
    rotateMeasurementLabel(textRotationAngle);
  };

  const handleSubmit = () => {
    const distance = removeSpacesFromThousands(
      text,
      units === UnitSystemTypes.Imperial
    );
    const newLength: number =
      units === UnitSystemTypes.Metric
        ? Number(distance)
        : Math.round(Number(convertFtInchToMillimeters(distance)));
    onMeasurementSubmit &&
      onMeasurementSubmit({
        pointsToUpdate: points,
        distance: newLength,
        type,
      });

    resetMeasurementLine();
  };

  return (
    <Group
      onPointerEnter={onMouseEnter}
      onPointerLeave={onMouseLeave}
      onClick={onMouseClick}
      ref={measurementRef}
    >
      <MeasurementArrow
        scale={scale}
        color={lineColor}
        angle={measurementRotationAngle}
        point={measurementPoints[0]}
      />
      <MeasurementArrow
        scale={scale}
        angle={measurementRotationAngle}
        color={lineColor}
        point={measurementPoints[1]}
      />

      {active && (
        <MeasurementInput
          scale={scale}
          width={labelRef.current?.getWidth() || 0}
          value={text}
          top={absolute.y}
          left={absolute.x}
          onEscape={handleEscape}
          units={units}
          onChange={handleMeasurementChange}
          onSubmit={handleSubmit}
          limits={getWindowMeasurementLimits(points, type)}
        ></MeasurementInput>
      )}

      <Line
        hitStrokeWidth={FONT_SIZE / scale}
        points={[...measurementPoints[0], ...measurementPoints[1]].flat()}
        stroke={lineColor}
        strokeWidth={(INITIAL_SCALE * 2) / scale}
      ></Line>
      <Label
        ref={labelRef}
        x={labelParams.x}
        y={labelParams.y}
        opacity={active ? 0 : 1}
      >
        <Tag
          strokeWidth={1 / scale}
          stroke={hovered || active ? '#65BD51' : ''}
          cornerRadius={6 / scale}
          fill="#F2F3F3"
        ></Tag>
        <Text
          fontFamily="Rubik"
          fill={textColor}
          padding={TEXT_PADDING / scale}
          text={text}
          lineHeight={(FONT_SIZE / 2) * INITIAL_SCALE}
          fontSize={FONT_SIZE / scale}
        ></Text>
      </Label>
    </Group>
  );
};
