import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useMouse } from 'react-use';
import useMouseOverCanvas from '@/shared/hooks/useMouseOverCanvas';

interface TextTooltipProps {
  text?: string | ReactNode;
  visible: boolean;
}

const TextTooltip: React.FC<TextTooltipProps> = ({ text, visible }) => {
  const ref = useRef(null);

  const [position, setPosition] = useState<{ x: number; y: number }>(null!);
  const mouse = useMouse(ref);

  useMouseOverCanvas(setPosition);

  useEffect(() => {
    mouse.docX !== 0 && setPosition({ x: mouse.docX, y: mouse.docY });
  }, [mouse]);

  if (!position) return null;

  return (
    <div
      className={`absolute text-xs p-2.5 rounded-lg border border-solid border-light-gray-40 shadow-toolbar bg-light-gray-10 z-10
        ${visible ? 'visible' : 'hidden'}`}
      style={{
        top: position.y - 35,
        left: position.x + 17,
      }}
      ref={ref}
    >
      <div>
        <div className="flex items-center min-h-6">{text}</div>
      </div>
    </div>
  );
};

export default TextTooltip;
