import React from 'react';

const PerspectiveIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.308 17H3.5C3.22386 17 3 16.7761 3 16.5V4.82606C3 4.62605 3.1192 4.44528 3.30304 4.36649L6.39719 3.04043C6.45943 3.01375 6.52643 3 6.59415 3H16.5C16.7761 3 17 3.22386 17 3.5V14.308C17 14.4406 16.9473 14.5678 16.8536 14.6616L14.6616 16.8536C14.5678 16.9473 14.4406 17 14.308 17Z"
        stroke="currentColor"
      />
      <path
        d="M3.57141 4.99888H6.90474H14.0476M14.0476 4.99888V14.0465V16.4275M14.0476 4.99888L16.4286 3.57031"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PerspectiveIcon;
