import { ModalProps } from 'antd';

export enum HeaderModes {
  Default = 'Default',
  Project = 'Project',
}

export enum WindowCreatorModes {
  Selection = 'Selection',
  MullionCreator = 'MullionCreator',
}

export enum WindowPlacementModes {
  Selection = 'Selection',
  WindowPlacement = 'WindowPlacement',
}

export interface CustomModalProps extends ModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export interface PointerPosition {
  x: number;
  y: number;
}
