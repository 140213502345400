import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
} from '@/config/environment/environment.dev';

import { store } from '@/store';
import { Provider } from 'react-redux';
import { initMapBox } from '@/store/actions/initActions';

store.dispatch(initMapBox());

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/auth`,
      audience: AUTH0_AUDIENCE,
    }}
  >
    <ConfigProvider theme={{ hashed: false, token: { fontFamily: 'Rubik' } }}>
      <Provider store={store}>
        <App></App>
      </Provider>
    </ConfigProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
