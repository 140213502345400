import React, { useMemo } from 'react';
import * as THREE from 'three';
import {
  BUILDING_SELECTED_CONTOUR_COLOR,
  C_FatLineBorderMaterial,
} from '@/shared/materials';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three-stdlib';

interface BorderProps {
  geometry: THREE.BufferGeometry;
  isSelected?: boolean;
  isHovered?: boolean;
}

const Border: React.FC<BorderProps> = ({ geometry, isSelected, isHovered }) => {
  const borderGeometry = useMemo(() => {
    const points = Array.from(geometry.getAttribute('position').array);
    return new LineGeometry().setPositions(points);
  }, [geometry]);

  new THREE.EdgesGeometry(geometry.clone());
  const borderMaterial = useMemo(() => {
    const material = C_FatLineBorderMaterial.clone();
    if (isSelected || isHovered) {
      material.color = BUILDING_SELECTED_CONTOUR_COLOR;
    }
    return material;
  }, [isSelected, isHovered]);

  return <primitive object={new Line2(borderGeometry, borderMaterial)} />;
};

export default Border;
