import React, { useEffect, useRef, useState } from 'react';
import { Form, InputRef } from 'antd';
import { IntusInput } from '@/shared/elements';
import { Html } from 'react-konva-utils';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { limitsValidator } from '@/shared/form/validators';
import { UnitSystemTypes } from '@/models';
import { useClickAway } from 'react-use';
import {
  addSpacesToThousands,
  removeSpacesFromThousands,
  sanitizeInputMetricValue,
} from '@/shared/helpers/format-data';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';

interface MeasurementInputProps {
  scale: number;
  width: number;
  top: number;
  left: number;
  value: string;
  units: UnitSystemTypes;
  onEscape: () => void;
  onChange: (val: string, hasError: boolean) => void;
  onSubmit: () => void;
  limits?: { min: number; max: number };
}
export const MeasurementInput = ({
  scale,
  width,
  top,
  left,
  value,
  onEscape,
  onChange,
  onSubmit,
  limits,
  units,
}: MeasurementInputProps) => {
  const inputWrapperRef = useRef<HTMLInputElement>(null!);
  const inputRef = useRef<InputRef>(null!);
  const [form] = useForm<{ size: string }>();
  const distance = useWatch('size', form);
  const [hasError, setHasError] = useState(false);
  const isImperialUnits = units === UnitSystemTypes.Imperial;

  useEffect(() => {
    inputRef.current && inputRef.current.focus({ cursor: 'all' });
  }, []);

  useClickAway(inputWrapperRef, (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onEscape();
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      onEscape();
    }
  };

  useEffect(() => {
    setHasError(!!form.getFieldError('size').length);
    distance && onChange(distance, !!form.getFieldError('size').length);
  }, [distance]);

  useEffect(() => {
    inputWrapperRef.current?.addEventListener('keydown', handleKeyDown);
    return () => {
      inputWrapperRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputRef.current, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValueWithoutSpaces = sanitizeInputMetricValue(
      removeSpacesFromThousands(event.target.value, isImperialUnits),
      isImperialUnits,
      2
    );
    form.setFieldsValue({
      size: addSpacesToThousands(sanitizedValueWithoutSpaces, isImperialUnits),
    });

    form.validateFields();
  };

  return (
    <>
      <Html
        divProps={{
          style: {
            position: 'absolute',
            top: `${top}px`,
            left: `${left}px`,
            scale: 1 / scale,
            transform: `translate(0px, 0px) rotate(0deg) scaleX(${scale}) scaleY(${scale})`,
            width: `${width * scale}px`,
          },
        }}
      >
        <Form form={form} onFinish={onSubmit}>
          <div ref={inputWrapperRef}>
            <Form.Item
              name="size"
              noStyle
              initialValue={value}
              rules={
                limits && [
                  limitsValidator({
                    min: limits.min,
                    max: limits.max,
                    isImperialUnits,
                  }),
                ]
              }
            >
              <IntusInput
                ref={inputRef}
                autoComplete="off"
                size="small"
                onChange={handleChange}
              ></IntusInput>
            </Form.Item>
          </div>
        </Form>
      </Html>
      {hasError && limits && (
        <Html
          divProps={{
            style: {
              position: 'absolute',
              top: `${60}px`,
              left: `0`,
              scale: 1 / scale,
              display: 'flex',
              justifyContent: 'center',
              transform: `translate(0px, 0px) rotate(0deg) scaleX(${scale}) scaleY(${scale})`,
              width: `100%`,
            },
          }}
        >
          <span className="leading-5 py-0.5 px-2 text-white bg-red rounded">
            Limits{' '}
            {addSpacesToThousands(
              isImperialUnits
                ? convertMillimetersToFtInch(limits?.min)
                : limits?.min.toString(),
              isImperialUnits
            )}
            {' - '}
            {addSpacesToThousands(
              isImperialUnits
                ? convertMillimetersToFtInch(limits?.max)
                : limits?.max.toString(),
              isImperialUnits
            )}
          </span>
        </Html>
      )}
    </>
  );
};
