import React from 'react';
import { BaseIconProps } from '@/models';

export const MoreIcon: React.FC<BaseIconProps> = ({ fill = '#000' }) => {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="1.5" r="1.5" fill="#414042" />
      <circle cx="2" cy="8" r="1.5" fill="#414042" />
      <circle cx="2" cy="14.5" r="1.5" fill="#414042" />
    </svg>
  );
};
