import {
  clearSelectRelatedData,
  getIsWindowNodeSelected,
  getWindowCreatorMode,
  setSelectArea,
  setWindowSelectedNode,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { WindowElementType } from '@/components/WindowCreator/models/konva-model';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { WindowCreatorModes } from '@/models/shared.model';
import { useContextMenu } from '@/shared/hooks/useWindowMenu';
import { KonvaEventObject } from 'konva/lib/Node';
import { FlatVector2 } from '@/models';

export const useWindowCreatorSelect = (
  id: number,
  elementPoints: FlatVector2[]
) => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(getWindowCreatorMode);

  const isSelected = useAppSelector(getIsWindowNodeSelected(id));
  const isSelectionMode = mode === WindowCreatorModes.Selection;

  const { handleContextMenu } = useContextMenu(isSelected);

  const selectElementInWindowCreator = (
    type: WindowElementType,
    selectionPoints: FlatVector2[][],
    event?: KonvaEventObject<MouseEvent>
  ) => {
    if (!isSelectionMode) return;
    if (event?.evt.button !== 0) return;

    if (!isSelected) {
      event && handleContextMenu(event);

      dispatch(
        setWindowSelectedNode({
          id,
          type,
          points: elementPoints,
        })
      );
      dispatch(setSelectArea(selectionPoints));
    } else {
      dispatch(clearSelectRelatedData());
    }
  };

  return {
    selectElementInWindowCreator,
  };
};
