import React, { useMemo } from 'react';
import { NodeType, SelectedNodeSource, UserBuildingSurface } from '@/models';
import { C_FloorMaterial, C_SurfaceSelectedMaterial } from '@/shared/materials';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import Border from '@/routes/dashboard/projects/project/UserBuilding/components/Border';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { ThreeEvent } from '@react-three/fiber';
import { GenericChildSurface } from '@/models/building-nodes.model';

interface FloorProps extends GenericChildSurface {
  data: UserBuildingSurface;
  opacity?: number;
  isRoof?: boolean;
  isLowestFloor?: boolean;
  blockGUID: string;
}

const Surface: React.FC<FloorProps> = ({
  data,
  opacity = 1,
  isParentSelected,
  isParentHovered,
  isParentLocked,
  isRoof,
  blockGUID,
}) => {
  const { setHoveredNode, resetHoveredNode } = useHoveredNode({
    nodeGUID: blockGUID,
    isLocked: isParentLocked,
    source: SelectedNodeSource.Viewer,
  });
  const { selectNode } = useSelectedNodes();

  const handleSelectBlock = (event: ThreeEvent<PointerEvent>) => {
    selectNode({
      event,
      type: NodeType.Block,
      guid: blockGUID,
      isLocked: !!isParentLocked,
      isSelected: !!isParentSelected,
    });
  };

  const { points } = data;
  const coordinates = useMemo(
    () => convertFlatVector3ToVectors(points),
    [data]
  );

  const surfaceGeometry = useMemo(() => {
    return createGeometryFromVectorList(coordinates, 'horizontal');
  }, [points]);

  const surfaceMaterial = useMemo(() => {
    if (isParentSelected && opacity !== 1) {
      return C_SurfaceSelectedMaterial.clone();
    }
    const material = C_FloorMaterial.clone();
    material.opacity = opacity;

    return material;
  }, [opacity, isParentSelected]);

  const hoverHandlers = {
    onPointerEnter: setHoveredNode,
    onPointerLeave: resetHoveredNode,
    onPointerDown: handleSelectBlock,
  };

  return (
    <mesh
      geometry={surfaceGeometry}
      material={surfaceMaterial}
      {...(isRoof
        ? hoverHandlers
        : {
            onPointerEnter: (e) => e.stopPropagation(),
            onPointerDown: (e) => e.stopPropagation(),
          })}
      userData={{
        ...data.userData,
        originalBuildingBlock: {
          guid: blockGUID,
        },
      }}
    >
      <Border
        geometry={surfaceGeometry}
        isSelected={isParentSelected}
        isHovered={isParentHovered}
      />
    </mesh>
  );
};

export default Surface;
