import React, { useEffect, useState } from 'react';

interface AvatarProps {
  imgSrc?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  initial?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  imgSrc,
  width = 24,
  height = 24,
  fontSize = 12,
  initial = '?',
}) => {
  const [isImageAvailable, setIsImageAvailable] = useState(false);

  useEffect(() => {
    if (imgSrc) {
      const validateImgSrc = async () => {
        await fetch(new URL(imgSrc!), { method: 'HEAD' });
        setIsImageAvailable(true);
      };

      validateImgSrc().catch(() => setIsImageAvailable(false));
    }
  }, []);
  if (isImageAvailable) {
    return (
      <img
        src={imgSrc}
        alt={'profile picture'}
        loading={'lazy'}
        className={'rounded-full object-cover'}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
    );
  }
  return (
    <span
      className={
        'bg-light-green-15 text-light-green-100 rounded-full text-center inline-block'
      }
      style={{
        width,
        height,
        fontSize,
        lineHeight: `${height}px`,
      }}
    >
      {initial}
    </span>
  );
};

export default Avatar;
