import React, { useEffect } from 'react';
import ProjectScene from '@/routes/dashboard/projects/project/ProjectScene';
import {
  useCreateUserBuildingMutation,
  useFetchProjectQuery,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { IntusLoader } from '@/shared/elements';
import { useAppDispatch } from '@/store/hooks';
import { setHeaderMode } from '@/store/slices/sharedSlice';
import { HeaderModes } from '@/models/shared.model';
import { CREATE_USER_BUILDING_CACHE_KEY } from '@/routes/dashboard/projects/project/UserBuilding/CreateUserBuilding';
import { clearSelectedNodes } from '@/store/slices/canvasBuildingSlice';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';

const Project = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { data: projectData, isFetching } = useFetchProjectQuery(id!, {
    refetchOnMountOrArgChange: true,
  });
  const areWindowsFetching = useFetchWindowsQuery(id!).isFetching;
  useFetchWindowConfigQuery(undefined, { refetchOnMountOrArgChange: true });

  const [, { isLoading: isCreationInProgress }] = useCreateUserBuildingMutation(
    {
      fixedCacheKey: CREATE_USER_BUILDING_CACHE_KEY,
    }
  );

  const { handleDisableIsolateMode } = useIsolationHandlers();

  useEffect(() => {
    if (!projectData) return;
    dispatch(setHeaderMode(HeaderModes.Project));
  }, [projectData]);

  useEffect(() => {
    return () => {
      dispatch(setHeaderMode(HeaderModes.Default));
      dispatch(clearSelectedNodes());
      handleDisableIsolateMode();
    };
  }, []);

  return (
    <>
      {projectData && <ProjectScene project={projectData} />}
      <IntusLoader
        loading={isCreationInProgress || isFetching || areWindowsFetching}
      />
    </>
  );
};

export default Project;
