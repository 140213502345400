import React from 'react';
import { BaseIconProps } from '@/models';

interface CursorIconProps extends BaseIconProps {
  size?: number;
}

export const CursorIcon: React.FC<CursorIconProps> = ({
  fill = '#fff',
  size = 20,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.00195 3.74836C5.00195 2.71817 6.17819 2.13023 7.0022 2.74855L16.999 10.25C17.9597 10.9709 17.4499 12.4998 16.2488 12.4998H11.3172C10.9286 12.4998 10.562 12.6806 10.3254 12.989L7.24376 17.0064C6.5168 17.9542 5.00195 17.4401 5.00195 16.2456V3.74836ZM16.2488 11.2498L6.25195 3.74836V16.2456L9.33362 12.2282C9.80675 11.6114 10.5399 11.2498 11.3172 11.2498L16.2488 11.2498Z"
        fill={fill}
      />
    </svg>
  );
};
