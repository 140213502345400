import React from 'react';
import * as THREE from 'three';
import GenericFloor from '@/routes/dashboard/projects/project/UserBuilding/components/generic/GenericFloor';

interface GenericStoreyProps {
  storeyLevel: number;
  storeyShape: THREE.Mesh;
  storeyHeight: number;
}

const GenericStorey: React.FC<GenericStoreyProps> = ({
  storeyShape,
  storeyLevel,
  storeyHeight,
}) => {
  return (
    <group>
      <GenericFloor
        floorLevel={storeyLevel}
        baseBuilding={storeyShape}
        floorHeight={storeyHeight}
      />
    </group>
  );
};

export default GenericStorey;
