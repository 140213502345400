import React from 'react';
import { NodeType, UserBuildingBlock } from '@/models';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { getCenterFromFlatVectorsArray } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as THREE from 'three';
import ExtrudeWall from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeWall';
import { useExtrudeHandlers } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/useExtrudeHandlers';

interface ExtrudeBlockProps {
  block: UserBuildingBlock;
  extrudeNode: ExtrudeHandlerData;
  buildingGuid: string;
}

const ExtrudeBlock: React.FC<ExtrudeBlockProps> = ({
  block,
  extrudeNode,
  buildingGuid,
}) => {
  const {
    maxNegativeDistance,
    maxPositiveDistance,
    extrudeHandlerPosition,
    mousePosition,
    handleMaxPositiveDistance,
    handleMaxNegativeDistance,
    setExtrudeHandlerPosition,
  } = useExtrudeHandlers({ extrudeNode });

  return (
    <>
      {extrudeNode.facadeWallsGuids?.map((facadeWall) => {
        const storey = block.storeys.find((storey) =>
          storey.walls.find((wall) => wall.guid === facadeWall)
        );
        if (!storey) return null;

        const wall = storey.walls.find((wall) => wall.guid === facadeWall);

        if (!wall) return null;
        const wallCenter = getCenterFromFlatVectorsArray(wall.points);
        const storeyCenter = getCenterFromFlatVectorsArray(storey.floor.points);

        const extrudeStoreyData: ExtrudeHandlerData = {
          node: { guid: facadeWall, type: NodeType.Wall },
          wallCoordinates: wall.points,
          defaultCenter: [wallCenter.x, wallCenter.y, wallCenter.z],
          extendAnchor: [storeyCenter.x, storeyCenter.y, storeyCenter.z],
        };

        return (
          <ExtrudeWall
            storey={storey}
            extrudeNode={extrudeStoreyData}
            buildingGuid={buildingGuid}
            blockGuid={block.guid}
            cursorPosition={mousePosition}
            key={storey.guid}
            maxNegativeDistance={maxNegativeDistance}
            setMaxNegativeDistance={handleMaxNegativeDistance}
            maxPositiveDistance={maxPositiveDistance}
            setMaxPositiveDistance={handleMaxPositiveDistance}
            isBlockMode
            {...(storey.storeyNumber === 1
              ? {
                  setHandlerPosition: setExtrudeHandlerPosition,
                }
              : {})}
            {...(block.storeys[block.storeys.length - 1].storeyNumber ===
            storey.storeyNumber
              ? { saveBlock: true }
              : {})}
          />
        );
      })}
      <ExtrudeDotHandler
        extrudeHandlerData={extrudeNode}
        active
        shapeCoordinates={block.storeys[0].floor.points}
        position={
          extrudeHandlerPosition
            ? new THREE.Vector3(
                extrudeHandlerPosition.x,
                extrudeNode.defaultCenter[1],
                extrudeHandlerPosition.z
              )
            : undefined
        }
      />
    </>
  );
};

export default ExtrudeBlock;
