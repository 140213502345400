import React from 'react';
import EditToolbarButton from './EditToolbarButton';
import { CutIcon, DeleteIcon, SplitIcon } from './icons';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import {
  constructionElementTypes,
  getStatusByNodeOrParents,
} from '@/shared/helpers/construction-user-data';
import {
  CanvasActionsModes,
  EditModes,
  NodeType,
  SelectedNode,
} from '@/models';
import { setEditMode, setMode } from '@/store/slices/canvasModesSlice';
import { useAppDispatch } from '@/store/hooks';
import {
  setEditedNode,
  setHoveredNode,
} from '@/store/slices/canvasBuildingSlice';
import WindowPlacementIcon from '@/shared/elements/EditToolbar/icons/WindowPlacementIcon';
import { resetSelectedWindowFromLibrary } from '@/store/slices/windowsReducer/windowPlacementSlice';

const EditToolbar = ({
  selectedNode,
}: {
  projectId: number;
  selectedNode: SelectedNode;
}) => {
  const Divider = (
    <div className={'bg-light-gray-10 h-5 mx-0.5 my-auto w-0.5'} />
  );

  const dispatch = useAppDispatch();
  const { clearAllSelectedNodes } = useSelectedNodes();
  const userBuildingUtils = useUpdateUserBuildingData();
  const { isIsolateModeEnabled, isNodeIsolated } = useIsolationHandlers();

  const { getNodeData } = useFindNodeData();

  const isIsolated = isNodeIsolated(selectedNode.guid);

  const isNodeHiddenByIsolation = isIsolateModeEnabled ? !isIsolated : false;

  const isAllowedElementSelected = constructionElementTypes.includes(
    selectedNode.type
  );

  const nodeData = getNodeData({
    guid: selectedNode.guid,
    nodeType: selectedNode.type,
  });

  if (!nodeData) return null;

  const isNodeHidden = getStatusByNodeOrParents(nodeData, 'isHidden');
  const isNodeLocked = getStatusByNodeOrParents(nodeData, 'isLocked');

  const isCutModeAvailable =
    (selectedNode.type === NodeType.Building &&
      nodeData.childNodes.filter((node) => node.type === NodeType.Block)
        .length === 1) ||
    selectedNode.type === NodeType.Block;

  const handleDelete = () => {
    userBuildingUtils.deleteSelectedConstructions([nodeData]);
  };

  const handleClickCut = () => {
    dispatch(setEditedNode(selectedNode));
    dispatch(setHoveredNode(selectedNode.guid));
    dispatch(setEditMode(EditModes.Cut));
  };

  const handleClickSplit = () => {
    if (selectedNode.type === NodeType.Building) {
      clearAllSelectedNodes();
      return;
    }

    dispatch(setEditedNode(selectedNode));
    dispatch(setEditMode(EditModes.Split));
  };

  const handleClickWindowPlacement = () => {
    dispatch(setMode(CanvasActionsModes.windowPlacement));
    dispatch(resetSelectedWindowFromLibrary());
  };

  const getActions = () => {
    const displayedActions = [];

    if (isCutModeAvailable)
      displayedActions.push({
        element: (
          <EditToolbarButton icon={<CutIcon />} onClick={handleClickCut} />
        ),
      });

    if (selectedNode.type !== NodeType.Building)
      displayedActions.push({
        element: (
          <EditToolbarButton icon={<SplitIcon />} onClick={handleClickSplit} />
        ),
      });

    if ([NodeType.Block, NodeType.Panel].includes(selectedNode.type)) {
      displayedActions.push({ element: Divider });
      displayedActions.push({
        element: (
          <EditToolbarButton icon={<DeleteIcon />} onClick={handleDelete} />
        ),
      });
    }

    if (selectedNode.type === NodeType.Wall) {
      displayedActions.push({
        element: (
          <EditToolbarButton
            icon={<WindowPlacementIcon />}
            onClick={handleClickWindowPlacement}
          />
        ),
      });
    }
    return displayedActions;
  };

  const showToolbar =
    isAllowedElementSelected &&
    !isNodeHidden &&
    !isNodeLocked &&
    !isNodeHiddenByIsolation;

  return showToolbar ? (
    <div className="absolute top-[36px] left-[53%]">
      <div
        className={`translate-x-[-50%] rounded-md shadow-toolbar flex bg-white p-0.5 gap-0.5 items-center'}`}
      >
        {getActions().map((action, i) => (
          <span key={i}>{action.element}</span>
        ))}
      </div>
      <div className="absolute top-[27px] w-0 h-0 border-x-[8px] border-solid border-x-transparent border-t-[8px] border-b-0 border-t-white translate-x-[-50%] mx-auto" />
    </div>
  ) : (
    <></>
  );
};

export default EditToolbar;
