import React from 'react';
import './AntdButton.scss';
import { Button, ButtonProps } from 'antd';

interface IntusButtonProps extends ButtonProps {
  isActive?: boolean;
  small?: boolean;
  borderless?: boolean;
  transparent?: boolean;
}

const IntusButton: React.FC<IntusButtonProps> = ({
  isActive,
  disabled,
  small,
  borderless = true,
  transparent = false,
  type = 'primary',
  children,
  ...rest
}) => {
  return (
    <Button
      className={`
      flex items-center 
      ${disabled ? '' : isActive ? '!bg-light-green-100 hover:!bg-dark-green-100 text-white hover:!text-white' : 'text-black hover:text-black'} 
      ${small ? 'py-[6px] px-2' : ''}
      ${borderless ? '!border-0' : ''}
      ${transparent ? '!bg-transparent' : ''}
      `}
      type={type}
      disabled={disabled}
      rootClassName={'intus-button'}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default IntusButton;
