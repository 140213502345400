import {
  NodeType,
  DistanceInput,
  UnitSystemTypes,
  UserBuildingBlock,
  UserBuildingCoordinatesObject,
  UserBuildingStorey,
  FlatVector3,
  MetricLimits,
} from '@/models';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import {
  BlockSearchResults,
  StoreySearchResults,
  useFindNodeData,
  WallSearchResults,
} from './useFindNodeData';
import {
  calculateCommonArea,
  calculateCommonMetric,
  formatAreaValue,
  getCommonValue,
} from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import {
  getAreaInMeters,
  getFacadesArea,
  getFloorHeight,
  getObjectDimensions,
  getTotalBlockHeight,
  getWallArea,
  getWallWidth,
} from '../helpers/metrics';
import {
  convertFtInchToMillimeters,
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
  convertMillimetersToMeters,
} from '../helpers/distance';
import {
  convertFlatVector3ToVectors,
  FLOOR_HEIGHT,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import * as THREE from 'three';
import { updateSections } from '@/shared/helpers/rectangle-mode';
import { convertInputValueToMillimeters } from '@/shared/helpers/directional-input';
import {
  addSpacesToThousands,
  getAlphabetIndex,
  limitValue,
  removeSpacesFromThousands,
} from '../helpers/format-data';
import { uuidv7 } from 'uuidv7';
import { cloneDeep, omit } from 'lodash';
import {
  convertBufferGeometryTo3DVectorList,
  getExistingCoordinates,
  getExtendedVector,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';

interface framePropertiesProps {
  metricOnly?: boolean;
}
const useFrameProperties = (props?: framePropertiesProps) => {
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate);
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const { getNodeData } = useFindNodeData();

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const getBuildingDimensionsMetric = (
    blocks: BlockSearchResults[],
    dimension: DistanceInput.Width | DistanceInput.Length
  ) => {
    const buildingDimensions = blocks.map((block) =>
      getObjectDimensions(
        block.groundFloorPoints,
        multiplyRate,
        isImperialUnits
      )
    );

    return dimension === DistanceInput.Width
      ? calculateCommonMetric({
          nodes: buildingDimensions,
          calculateMetricFunction: (block) => block.width,
        })
      : calculateCommonMetric({
          nodes: buildingDimensions,
          calculateMetricFunction: (block) => block.length,
        });
  };

  const getBlockStoreysCountMetric = (blocks: BlockSearchResults[]) =>
    getCommonValue(blocks.map((block) => block.storeysCount.toString()));

  const getFloorHeightMetric = () =>
    getFloorHeight(isImperialUnits && !props?.metricOnly);

  const getBlockHeightMetric = (blocksStoreysCount: string) =>
    getTotalBlockHeight(blocksStoreysCount, isImperialUnits);

  const getFacadesAreaMetricForBlocks = (blocks: BlockSearchResults[]) => {
    const wallsGUID = blocks.flatMap((block) =>
      block.childNodes
        .filter((node) => node.type === NodeType.Wall)
        .map((node) => node.guid)
    );

    const wallsData = wallsGUID.map((guid) => {
      return getNodeData({
        guid,
        nodeType: NodeType.Wall,
      }) as WallSearchResults;
    });

    const facadesArea = getFacadesArea({
      walls: wallsData,
      multiplyRate,
    });

    return formatAreaValue(facadesArea, isImperialUnits);
  };

  const getGrossInternalAreaForBlocks = (blocks: BlockSearchResults[]) => {
    const storeysGUID = blocks.flatMap((block) =>
      block.childNodes
        .filter((node) => node.type === NodeType.Storey)
        .map((node) => node.guid)
    );

    const storeysData = storeysGUID.map((guid) => {
      return getNodeData({
        guid,
        nodeType: NodeType.Storey,
      }) as StoreySearchResults;
    });

    return calculateCommonArea({
      nodes: storeysData,
      calculateAreaFunction: (storey) =>
        Number(getAreaInMeters(storey.ceilingPoints, multiplyRate)),
      isImperialUnits,
    });
  };

  const getFacadesAreaMetricForStoreys = (storeys: StoreySearchResults[]) => {
    return calculateCommonArea({
      nodes: storeys,
      calculateAreaFunction: (storey) =>
        getFacadesArea({
          walls: storey.walls,
          multiplyRate,
        }),
      isImperialUnits,
    });
  };

  const getGrossInternalAreaMetricForStoreys = (
    storeys: StoreySearchResults[]
  ) => {
    return calculateCommonArea({
      nodes: storeys,
      calculateAreaFunction: (storey) =>
        Number(getAreaInMeters(storey.ceilingPoints, multiplyRate)),
      isImperialUnits,
    });
  };

  const getWallWidthMetric = (walls: WallSearchResults[]) => {
    const wallWidths = walls.map((wall) =>
      getWallWidth(wall.points, multiplyRate).toString()
    );

    const commonWallWidthInMeters = getCommonValue(wallWidths);

    if (commonWallWidthInMeters === '-') return commonWallWidthInMeters;
    const wallWidthInMillimeters = convertMetersToMillimeters(
      commonWallWidthInMeters
    );
    return isImperialUnits && !props?.metricOnly
      ? addSpacesToThousands(
          convertMillimetersToFtInch(wallWidthInMillimeters),
          isImperialUnits
        )
      : addSpacesToThousands(
          Number(wallWidthInMillimeters).toFixed(0),
          isImperialUnits
        );
  };

  const getFacadesAreaMetricForWalls = (walls: WallSearchResults[]) => {
    const calculateWallArea = (wall: WallSearchResults) => {
      const wallWidth = getWallWidth(wall.points, multiplyRate);
      return Number(getWallArea(Number(wallWidth)));
    };

    return calculateCommonArea({
      nodes: walls,
      calculateAreaFunction: calculateWallArea,
      isImperialUnits,
    });
  };
  const updatePointsHeight = (points: FlatVector3[]): FlatVector3[] =>
    points.map((point) => [point[0], point[1] + FLOOR_HEIGHT, point[2]]);

  const createNewStoreyFromPreviousStorey = (
    lastStorey: UserBuildingStorey,
    storeyNumber: number
  ): UserBuildingStorey =>
    ({
      guid: uuidv7(),
      storeyNumber,
      name: `Floor ${storeyNumber}`,
      floor: {
        points: updatePointsHeight(lastStorey.floor.points),
        guid: uuidv7(),
        userData: {},
      },
      ceiling: {
        points: updatePointsHeight(lastStorey.ceiling.points),
        guid: uuidv7(),
        userData: {},
      },
      walls: lastStorey.walls.map((wall, i) => ({
        points: updatePointsHeight(wall.points),
        guid: uuidv7(),
        userData: {},
        name: `Wall ${getAlphabetIndex(i)}`,
      })),
      userData: {},
    }) as UserBuildingStorey;

  const changeStoreysNumberInBlock = (
    value: string,
    userBuildingBlock: UserBuildingBlock
  ) => {
    const validatedValue = limitValue(
      Number(value),
      MetricLimits.FloorsMin,
      MetricLimits.FloorsMax
    );

    const targetStoreyCount = Number(validatedValue);

    const currentStoreyCount = userBuildingBlock.storeys.length;
    const updatedBlock = cloneDeep(userBuildingBlock);

    if (targetStoreyCount < currentStoreyCount) {
      updatedBlock.storeys = userBuildingBlock.storeys.slice(
        0,
        targetStoreyCount
      );
    } else if (targetStoreyCount > currentStoreyCount) {
      for (let i = currentStoreyCount; i < targetStoreyCount; i++) {
        const lastStorey = updatedBlock.storeys[i - 1];
        const newStorey = createNewStoreyFromPreviousStorey(lastStorey, i + 1);
        updatedBlock.storeys.push(newStorey);
      }
    }

    return updatedBlock;
  };

  const updateBuildingWidth = (
    val: string,
    building: UserBuildingCoordinatesObject
  ) => {
    const valueWithSpaces = removeSpacesFromThousands(val, isImperialUnits);
    const width =
      convertMillimetersToMeters(
        convertInputValueToMillimeters(valueWithSpaces, !isImperialUnits)
      ) * multiplyRate;
    const centerLineVectors = convertFlatVector3ToVectors(
      building.centerLineCoordinates!.points
    );
    const sections: THREE.Mesh[] = [];
    let floorShape: THREE.Mesh = null!;
    let processingNewSection: boolean = false;
    const generateFatCenterLine = () => {};
    const updateContour = () => {};

    for (let i = 0; i < centerLineVectors.length - 1; i++) {
      updateSections({
        closeSection: false,
        nextPoint: centerLineVectors[i + 1],
        sections,
        width,
        generateFatCenterLine,
        updateContour,
        setProcessingNewSection: (val) => (processingNewSection = val),
        setFloorShape: (val) => (floorShape = val),
        processingSection: false,
        floor: floorShape,
        activeCenterLine: [centerLineVectors[i], centerLineVectors[i + 1]],
        previousCenterLinePoint:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
      });
      updateSections({
        closeSection: false,
        nextPoint: centerLineVectors[i + 1],
        sections,
        width,
        processingSection: true,
        floor: floorShape,
        generateFatCenterLine,
        updateContour,
        setProcessingNewSection: (val) => (processingNewSection = val),
        setFloorShape: (val) => (floorShape = val),
        activeCenterLine: [centerLineVectors[i], centerLineVectors[i + 1]],
        previousCenterLinePoint:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
      });

      if (i < centerLineVectors.length - 2) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        processingNewSection = false;
      }
    }

    floorShape.geometry.setFromPoints([
      ...getExistingCoordinates(floorShape),
      getExistingCoordinates(floorShape)[0],
    ]);

    const firstFloorCoordinates: FlatVector3[] =
      convertBufferGeometryTo3DVectorList(
        floorShape.geometry,
        floorShape.geometry.getAttribute('position').count
      ).map((vector) => getXYZ(vector));

    const updatedBlock = cloneDeep(building.blocks[0]);

    for (
      let floorIndex = 0;
      floorIndex < building.blocks[0].storeys.length;
      floorIndex++
    ) {
      const floorPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + FLOOR_HEIGHT * floorIndex,
        z,
      ]);
      const ceilingPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + FLOOR_HEIGHT * (floorIndex + 1),
        z,
      ]);

      const allWalls = [];
      for (let i = 0; i < floorPoints.length - 1; i++) {
        const wall = [
          floorPoints[i],
          floorPoints[i + 1],
          ceilingPoints[i + 1],
          ceilingPoints[i],
        ];
        allWalls.push(wall);
      }

      const newStorey = {
        ...omit(updatedBlock.storeys[floorIndex], 'id'),
        ceiling: {
          ...omit(updatedBlock.storeys[floorIndex].ceiling, 'id'),
          points: ceilingPoints,
        },
        floor: {
          ...omit(updatedBlock.storeys[floorIndex].floor, 'id'),
          points: floorPoints,
        },
        walls: allWalls.map((wallPoints, index) => ({
          ...omit(updatedBlock.storeys[floorIndex].walls[index], 'id'),
          points: wallPoints,
        })),
      } as UserBuildingStorey;

      updatedBlock.storeys[floorIndex] = newStorey;
    }

    return updatedBlock;
  };

  const updateFirstFloorCoordinatesForRectangleBuilding = ({
    value,
    userBuildingBlock,
    dimension,
  }: {
    value: string;
    userBuildingBlock: UserBuildingBlock;
    dimension: DistanceInput.Width | DistanceInput.Length;
  }) => {
    const valueWithSpaces = removeSpacesFromThousands(value, isImperialUnits);
    const firstStorey = userBuildingBlock.storeys[0];
    const meterValue =
      convertMillimetersToMeters(
        isImperialUnits
          ? convertFtInchToMillimeters(valueWithSpaces)
          : valueWithSpaces
      ) * multiplyRate;

    const [v1, v2, v3, v4, v5] = convertFlatVector3ToVectors(
      firstStorey.floor.points
    );

    let updatedVectors;
    if (dimension === DistanceInput.Width) {
      updatedVectors = [
        v1,
        getExtendedVector(v1, v2, meterValue),
        getExtendedVector(v4, v3, meterValue),
        v4,
        v5,
      ];
    } else {
      updatedVectors = [
        v1,
        v2,
        getExtendedVector(v2, v3, meterValue),
        getExtendedVector(v1, v4, meterValue),
        v5,
      ];
    }

    return updatedVectors.map((vector) => getXYZ(vector));
  };

  const changeDimensionInRectangleBuilding = ({
    value,
    userBuildingBlock,
    dimension,
  }: {
    value: string;
    userBuildingBlock: UserBuildingBlock;
    dimension: DistanceInput.Width | DistanceInput.Length;
  }) => {
    const firstFloorCoordinates =
      updateFirstFloorCoordinatesForRectangleBuilding({
        value,
        userBuildingBlock,
        dimension,
      });

    const updatedBlock = cloneDeep(userBuildingBlock);

    for (
      let floorIndex = 0;
      floorIndex < userBuildingBlock.storeys.length;
      floorIndex++
    ) {
      const floorPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + FLOOR_HEIGHT * floorIndex,
        z,
      ]);
      const ceilingPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + FLOOR_HEIGHT * (floorIndex + 1),
        z,
      ]);

      const allWalls = [];
      for (let i = 0; i < floorPoints.length - 1; i++) {
        const wall = [
          floorPoints[i],
          floorPoints[i + 1],
          ceilingPoints[i + 1],
          ceilingPoints[i],
        ];
        allWalls.push(wall);
      }

      const newStorey = {
        ...omit(updatedBlock.storeys[floorIndex], 'id'),
        ceiling: {
          ...omit(updatedBlock.storeys[floorIndex].ceiling, 'id'),
          points: ceilingPoints,
        },
        floor: {
          ...omit(updatedBlock.storeys[floorIndex].floor, 'id'),
          points: floorPoints,
        },
        walls: allWalls.map((wallPoints, index) => ({
          ...omit(updatedBlock.storeys[floorIndex].walls[index], 'id'),
          points: wallPoints,
        })),
      } as UserBuildingStorey;

      updatedBlock.storeys[floorIndex] = newStorey;
    }

    return updatedBlock;
  };

  return {
    getBuildingDimensionsMetric,
    getFloorHeightMetric,
    getBlockStoreysCountMetric,
    getBlockHeightMetric,
    getFacadesAreaMetricForBlocks,
    getGrossInternalAreaForBlocks,
    getFacadesAreaMetricForStoreys,
    getGrossInternalAreaMetricForStoreys,
    getWallWidthMetric,
    getFacadesAreaMetricForWalls,
    updateBuildingWidth,
    changeStoreysNumberInBlock,
    changeDimensionInRectangleBuilding,
  };
};

export default useFrameProperties;
