import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const FreeDrawIcon: React.FC<BaseIconProps> = ({ fill = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2319 15.9234C11.0206 16.698 10.2101 17.1577 9.42153 16.9502C8.63778 16.7439 8.17073 15.9561 8.37253 15.1859L3.28596 11.8292C2.94653 12.0464 2.51809 12.1265 2.09595 12.0154C1.3074 11.8079 0.839443 11.0117 1.05073 10.2371C1.21731 9.62639 1.75631 9.21144 2.36336 9.16485L3.29957 5.73261C2.79752 5.39411 2.54191 4.76999 2.70849 4.1593C2.91978 3.38469 3.7303 2.925 4.51885 3.13255C5.01608 3.26343 5.38584 3.62837 5.53821 4.07435L11.9403 3.98737C12.1874 3.26711 12.9655 2.85038 13.7233 3.04984C14.5118 3.25739 14.9798 4.0536 14.7685 4.82821C14.7094 5.04475 14.6035 5.23669 14.4644 5.39631L17.2512 10.138C17.4615 10.0994 17.6836 10.1053 17.9041 10.1633C18.6926 10.3709 19.1606 11.1671 18.9493 11.9417C18.738 12.7163 17.9274 13.176 17.1389 12.9685C16.8982 12.9051 16.6873 12.7869 16.5165 12.6311L11.2674 15.3394C11.2954 15.5288 11.2855 15.7268 11.2319 15.9234ZM16.3978 10.622C16.2587 10.7816 16.1528 10.9735 16.0937 11.1901C16.0401 11.3867 16.0302 11.5847 16.0582 11.7742L10.8091 14.4824C10.6383 14.3267 10.4274 14.2084 10.1867 14.145C9.73295 14.0256 9.27195 14.1271 8.92202 14.3824L3.88537 11.0585C3.8929 11.0356 3.89988 11.0123 3.90631 10.9887C4.07289 10.378 3.81728 9.75388 3.31523 9.41537L4.25144 5.98313C4.81375 5.93998 5.31767 5.58077 5.52129 5.0427L11.9539 4.9553C12.1147 5.38204 12.4767 5.72823 12.9581 5.85496C13.1785 5.91297 13.4007 5.91886 13.611 5.8803L16.3978 10.622Z"
        fill={fill}
      />
    </svg>
  );
};
