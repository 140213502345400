import React, { useMemo } from 'react';
import { EditModes, FlatVector3, NodeType, UserBuildingBlock } from '@/models';
import Storey from '@/routes/dashboard/projects/project/UserBuilding/components/Storey';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getExtrudeNode,
  getIsNodeIsolated,
  getIsSingleNodeSelected,
  getSelectedNodeByGUID,
  setExtrudeNode,
  getIsNodeEdited,
  getEditedNode,
} from '@/store/slices/canvasBuildingSlice';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { GenericChildSurface } from '@/models/building-nodes.model';
import ExtrudeStorey from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeStorey';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { Vector3 } from 'three';
import { flatten } from 'lodash';
import {
  getCenterFromFlatVectorsArray,
  getCenterFromVectorsArray,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { getEditMode } from '@/store/slices/canvasModesSlice';
import CutTool from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutTool';

interface BlockProps extends GenericChildSurface {
  block: UserBuildingBlock;
}

const Block: React.FC<BlockProps> = ({
  block,
  isParentSelected,
  isParentLocked,
  isParentHovered,
  buildingGUID,
}) => {
  const dispatch = useAppDispatch();

  const isNodeSelected = !!useAppSelector(getSelectedNodeByGUID(block.guid));
  const extrudeNode = useAppSelector(getExtrudeNode);
  const isBlockIsolated = useAppSelector(getIsNodeIsolated(block.guid));
  const editMode = useAppSelector(getEditMode);
  const editedNode = useAppSelector(getEditedNode);

  const { isIsolateModeEnabled } = useIsolationHandlers();
  const { isNodeHovered } = useHoveredNode({ nodeGUID: block.guid });

  const isNodeEdited = useAppSelector(getIsNodeEdited(block.guid));
  const isSingleNodeSelected = useAppSelector(getIsSingleNodeSelected);

  const isExtrudeAvailable =
    isNodeSelected &&
    isSingleNodeSelected &&
    !block.userData?.isHidden &&
    !block.userData?.isLocked &&
    !isParentLocked &&
    (!isIsolateModeEnabled || (isIsolateModeEnabled && isBlockIsolated));

  const blockFacedCentersData = useMemo((): ExtrudeHandlerData[] => {
    const allWallsAtOneSide = [];

    for (let i = 0; i < block.storeys[0].walls.length; i++) {
      const oneSideWalls = [];
      let wall = block.storeys[0].walls[i];
      oneSideWalls.push(wall);
      for (let j = 1; j < block.storeys.length; j++) {
        const foundedWall = block.storeys[j]?.walls.find((storeyWall) => {
          const amountOfSamePoints = storeyWall.points.reduce((acc, curr) => {
            return wall.points.some(
              (point) =>
                point[0].toFixed(3) === curr[0].toFixed(3) &&
                point[1].toFixed(3) === curr[1].toFixed(3) &&
                point[2].toFixed(3) === curr[2].toFixed(3)
            )
              ? acc + 1
              : acc;
          }, 0);
          return amountOfSamePoints === 2;
        });
        if (foundedWall) {
          oneSideWalls.push(foundedWall);
          wall = foundedWall;
        }
      }
      oneSideWalls.length === block.storeys.length &&
        allWallsAtOneSide.push(oneSideWalls);
    }

    const extrudeHandlerPointsData: {
      point: Vector3;
      wallCoordinates: FlatVector3[];
      facadeWallsGuids: string[];
    }[] = [];

    allWallsAtOneSide.forEach((walls) => {
      const allPoints = flatten(walls.map((w) => w.points));
      const center = getCenterFromFlatVectorsArray(allPoints);

      extrudeHandlerPointsData.push({
        point: center,
        wallCoordinates: walls[0].points,
        facadeWallsGuids: walls.map((wall) => wall.guid),
      });
    });

    const areaCenter = getCenterFromVectorsArray(
      extrudeHandlerPointsData.map((points) => points.point)
    );

    return extrudeHandlerPointsData.map((pointData) => ({
      defaultCenter: [pointData.point.x, pointData.point.y, pointData.point.z],
      extendAnchor: [areaCenter.x, areaCenter.y, areaCenter.z],
      node: {
        type: NodeType.Block,
        guid: block.guid,
      },
      wallCoordinates: pointData.wallCoordinates,
      facadeWallsGuids: pointData.facadeWallsGuids,
    }));
  }, [block]);

  const handleSelectWallForExtrude = (node: ExtrudeHandlerData) => {
    dispatch(setExtrudeNode(node));
  };

  return (
    <>
      <group
        key={block.guid}
        userData={{
          ...block.userData,
          nodeType: NodeType.Block,
        }}
      >
        {block.storeys
          .filter((storey) => !storey.userData?.isHidden)
          .map((storey, storeyIndex) => {
            const isWallInExtrudeMode =
              extrudeNode &&
              storey.walls.find((wall) => wall.guid === extrudeNode.node.guid);

            if (isWallInExtrudeMode) {
              return (
                <ExtrudeStorey
                  storey={storey}
                  extrudeNode={extrudeNode}
                  key={storey.guid}
                  blockGuid={block.guid}
                  buildingGuid={buildingGUID}
                />
              );
            }
            return (
              <Storey
                block={block}
                blockGUID={block.guid}
                isolateMode={isIsolateModeEnabled}
                storey={storey}
                isParentEdited={isNodeEdited}
                isParentSelected={isParentSelected || isNodeSelected}
                isParentHovered={isNodeHovered || isParentHovered}
                isParentLocked={block.userData?.isLocked || isParentLocked}
                key={storey.guid}
                isLastFloor={storeyIndex === block.storeys.length - 1}
                buildingGUID={buildingGUID}
              />
            );
          })}

        {isExtrudeAvailable &&
          blockFacedCentersData.map((handlerPoint, i) => {
            return (
              <ExtrudeDotHandler
                extrudeHandlerData={handlerPoint}
                key={i}
                shapeCoordinates={block.storeys[0].floor.points}
                clickAction={() => handleSelectWallForExtrude(handlerPoint)}
              />
            );
          })}
      </group>

      {editMode === EditModes.Cut && editedNode?.guid === block.guid && (
        <CutTool block={block} buildingGUID={buildingGUID} />
      )}
    </>
  );
};

export default Block;
