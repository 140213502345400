import { FormInstance } from 'antd';
import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
  WindowCreatorFormData,
} from '@/components/WindowCreator/models/konva-model';
import { FlatVector2 } from '@/models';
import { get2DCenter, get2DDistance } from '@/shared/helpers/konva';
import {
  InnerWindowData,
  MullionData,
  OperationType,
} from '@/models/window-configurator.model';
import {
  getExtendedPointByPercentage,
  isHorizontal,
  isLineOnLine,
  isLinesIntersect,
  isPointIntersectLine,
} from '@/components/WindowCreator/helpers/direction.helpers';
import { isEqual } from 'lodash';
import { generateClosedContourPointsFromPointArray } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { union } from 'polygon-clipping';
import {
  generateMullion,
  generateSplitWindows,
} from '@/components/WindowCreator/helpers/generators';

export const updateWindowCreatorFormData = ({
  form,
  updateData,
}: {
  form: FormInstance<WindowCreatorFormData>;
  updateData: MeasurementUpdateData;
}) => {
  const frameFormData: WindowCreatorFormData = form.getFieldsValue(true);
  let updatedFramePoints: FlatVector2[] = [...frameFormData.points];
  let updatedMullions: MullionData[] = [...frameFormData.mullions];
  let updatedWindows: InnerWindowData[] = [...frameFormData.innerWindows];
  let sizeChange: number = 0;

  // Vertical updates are going backwards, as we have point [0,0] in the top left corner (Konva approach for coordinates)
  switch (updateData.type) {
    case MeasurementElementType.Offset:
      sizeChange = -updateData.distance + frameFormData.distanceToFloor;
      updatedFramePoints = updatedFramePoints.map((point) => [
        point[0],
        point[1] + sizeChange,
      ]);
      updatedMullions = updatedMullions.map((mullion) => ({
        ...mullion,
        points: mullion.points.map((point) => [
          point[0],
          point[1] + sizeChange,
        ]) as FlatVector2Axis,
      }));
      updatedWindows = updatedWindows.map((window) => ({
        ...window,
        points: window.points.map((point) => [point[0], point[1] + sizeChange]),
      }));

      form.setFieldsValue({
        points: updatedFramePoints,
        distanceToFloor: updateData.distance,
        innerWindows: updatedWindows,
        mullions: updatedMullions,
      });
      break;

    // Here we currently support only rectangle-like frame. For diagonal/non-rectangle shapes we will need to change the logic
    case MeasurementElementType.Frame:
      // Vertical frame size changed
      if (updateData.pointsToUpdate[0][0] === updateData.pointsToUpdate[1][0]) {
        // In case of vertical frame change
        // We're increasing its height and only top points of frame (and all rest elements, which connected to top frame border)
        const zeroPoint = updateData.pointsToUpdate.reduce((prev, cur) =>
          prev[1] > cur[1] ? prev : cur
        );
        const percentageIncrease =
          updateData.distance / get2DDistance(...updateData.pointsToUpdate);

        updatedFramePoints = updatedFramePoints.map((point) =>
          getExtendedPointByPercentage(
            point,
            zeroPoint,
            percentageIncrease,
            'y'
          )
        );
        updatedMullions = updatedMullions.map((mullion) => ({
          ...mullion,
          points: mullion.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              zeroPoint,
              percentageIncrease,
              'y'
            )
          ) as FlatVector2Axis,
        }));
        updatedWindows = updatedWindows.map((window) => ({
          ...window,
          points: window.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              zeroPoint,
              percentageIncrease,
              'y'
            )
          ),
        }));

        form.setFieldsValue({
          points: updatedFramePoints,
          innerWindows: updatedWindows,
          mullions: updatedMullions,
        });
      } else if (
        updateData.pointsToUpdate[0][1] === updateData.pointsToUpdate[1][1]
      ) {
        const percentageIncrease =
          updateData.distance / get2DDistance(...updateData.pointsToUpdate);
        const sizeCenterPoint = get2DCenter(...updateData.pointsToUpdate);

        updatedFramePoints = updatedFramePoints.map((point) =>
          getExtendedPointByPercentage(
            point,
            sizeCenterPoint,
            percentageIncrease,
            'x'
          )
        );
        updatedMullions = updatedMullions.map((mullion) => ({
          ...mullion,
          points: mullion.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              sizeCenterPoint,
              percentageIncrease,
              'x'
            )
          ) as FlatVector2Axis,
        }));
        updatedWindows = updatedWindows.map((window) => ({
          ...window,
          points: window.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              sizeCenterPoint,
              percentageIncrease,
              'x'
            )
          ),
        }));

        form.setFieldsValue({
          points: updatedFramePoints,
          innerWindows: updatedWindows,
          mullions: updatedMullions,
        });
      }
      break;

    case MeasurementElementType.Mullion:
      // Here we're checking measurement points. For vertical mullion, there are 2 X-axis based points (X----X)
      // For Horizontal Mullion those points will be allocated from top ( mullion/frame) to bottom (mullion/frame)
      if (isHorizontal(updateData.pointsToUpdate)) {
        const isLastPointOnFrame =
          updateData.pointsToUpdate[1][0] === updatedFramePoints[1][0];

        if (isLastPointOnFrame) {
          const xPosition =
            updateData.pointsToUpdate[1][0] - updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[0] === updateData.pointsToUpdate[0][0]
                ? [xPosition, point[1]]
                : point
            ) as FlatVector2Axis,
          }));
          updatedWindows = updatedWindows.map((window) => ({
            ...window,
            points: window.points.map((point) =>
              point[0] === updateData.pointsToUpdate[0][0]
                ? [xPosition, point[1]]
                : point
            ),
          }));
        } else {
          const xPosition =
            updateData.pointsToUpdate[0][0] + updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[0] === updateData.pointsToUpdate[1][0]
                ? [xPosition, point[1]]
                : point
            ) as FlatVector2Axis,
          }));
          updatedWindows = updatedWindows.map((window) => ({
            ...window,
            points: window.points.map((point) =>
              point[0] === updateData.pointsToUpdate[1][0]
                ? [xPosition, point[1]]
                : point
            ),
          }));
        }
      } else {
        const isLastPointOnFrame =
          updateData.pointsToUpdate[1][1] === updatedFramePoints[2][1];

        if (isLastPointOnFrame) {
          const yPosition =
            updateData.pointsToUpdate[1][1] - updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[1] === updateData.pointsToUpdate[0][1]
                ? [point[0], yPosition]
                : point
            ) as FlatVector2Axis,
          }));
          updatedWindows = updatedWindows.map((window) => ({
            ...window,
            points: window.points.map((point) =>
              point[1] === updateData.pointsToUpdate[0][1]
                ? [point[0], yPosition]
                : point
            ),
          }));
        } else {
          const yPosition =
            updateData.pointsToUpdate[0][1] + updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[1] === updateData.pointsToUpdate[1][1]
                ? [point[0], yPosition]
                : point
            ) as FlatVector2Axis,
          }));
          updatedWindows = updatedWindows.map((window) => ({
            ...window,
            points: window.points.map((point) =>
              point[1] === updateData.pointsToUpdate[1][1]
                ? [point[0], yPosition]
                : point
            ),
          }));
        }
      }

      form.setFieldsValue({
        mullions: updatedMullions,
        innerWindows: updatedWindows,
      });
      break;
  }
};

export const handleChangeWindowType = (
  windowPoints: FlatVector2[],
  type: OperationType,
  form: FormInstance<WindowCreatorFormData>
) => {
  const existingWindows: InnerWindowData[] = form.getFieldValue('innerWindows');

  const newWindows = existingWindows.map((window) =>
    isEqual(window.points, windowPoints)
      ? { ...window, operationType: type }
      : window
  );
  form.setFieldValue('innerWindows', newWindows);
};

export const handleDeleteMullion = (
  mullionData: MullionData,
  form: FormInstance<WindowCreatorFormData>
) => {
  let mullions: MullionData[] = form.getFieldValue('mullions');
  let innerWindows: InnerWindowData[] = form.getFieldValue('innerWindows');
  const framePoints: FlatVector2[] = form.getFieldValue('points');
  const frameContour: FlatVector2Axis[] =
    generateClosedContourPointsFromPointArray(framePoints);

  const removeMullion = (points: FlatVector2Axis) =>
    (mullions = mullions.filter((mullion) => !isEqual(mullion.points, points)));

  removeMullion(mullionData.points);

  let hasUnconnectedMullion = true;
  const clearUnconnectedMullions = () => {
    mullions.forEach((mullion) => {
      const frameIntersectionAmount = frameContour.filter((line) =>
        isLinesIntersect(line, mullion.points)
      ).length;

      // 1. We need to filter mullions to exclude current one, to not compare mullion with itself
      // 2. After we're checking if one of mullion points intersect any other mullion
      // 3. If there is amount of intersections with frame 2 or more => Mullion is connected from both sides and shouldn't be removed
      // This method runs recursively as deleting one mullion may make another mullion unconnected
      const anotherMullionConnections = mullions
        .filter((_m) => {
          return !isEqual(_m.points, mullion.points);
        })
        .filter(
          (_m) =>
            isPointIntersectLine(mullion.points[0], _m.points, true) ||
            isPointIntersectLine(mullion.points[1], _m.points, true)
        ).length;

      hasUnconnectedMullion =
        frameIntersectionAmount + anotherMullionConnections < 2;

      if (hasUnconnectedMullion) {
        removeMullion(mullion.points);
        clearUnconnectedMullions();
      } else {
        return;
      }
    });
  };

  const mergeWindows = () => {
    const windowList = innerWindows.reduce(
      (
        acc: {
          windowsToMerge: InnerWindowData[];
          windowsToKeep: InnerWindowData[];
        },
        curWindow
      ) => {
        const windowEdges = generateClosedContourPointsFromPointArray(
          curWindow.points
        );
        const isWindowNotConnectedToAnySideElement = windowEdges.some(
          (windowEdge) => {
            return (
              !frameContour.some((frameEdge) =>
                isLineOnLine(windowEdge, frameEdge)
              ) &&
              !mullions.some((mullion) =>
                isLineOnLine(windowEdge, mullion.points)
              )
            );
          }
        );
        if (isWindowNotConnectedToAnySideElement) {
          acc.windowsToMerge.push(curWindow);
        } else {
          acc.windowsToKeep.push(curWindow);
        }

        return acc;
      },
      { windowsToMerge: [], windowsToKeep: [] }
    );

    const combinedWindow = union(
      windowList.windowsToMerge.map((w) => [[...w.points, w.points[0]]])
    )[0][0];

    // this line is needed, as union adding 1st point as a last element in array, while our windows have 4 points only
    combinedWindow.pop();

    innerWindows = [
      ...windowList.windowsToKeep,
      {
        points: combinedWindow,
        glazing: 'double',
        operationType: OperationType.Fixed,
      },
    ];
  };

  clearUnconnectedMullions();
  mergeWindows();

  form.setFieldValue('mullions', mullions);
  form.setFieldValue('innerWindows', innerWindows);
};

export const addMullion = (
  detail: { axis: FlatVector2Axis; windowPoints: FlatVector2[] },
  form: FormInstance<WindowCreatorFormData>
) => {
  let mullions = form.getFieldValue('mullions');
  const innerWindows: InnerWindowData[] = form.getFieldValue('innerWindows');

  const windowToReplaceIdx = innerWindows.findIndex((window) =>
    isEqual(window.points, detail.windowPoints)
  );

  const generatedWindows = generateSplitWindows(
    detail.axis,
    innerWindows[windowToReplaceIdx]
  );

  innerWindows.splice(windowToReplaceIdx, 1, ...generatedWindows);
  mullions = [...mullions, generateMullion(detail.axis)];

  form.setFieldsValue({
    innerWindows,
    mullions,
  });
};
