import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getStatusIsolateMode,
  setIsolateMode,
} from '@/store/slices/canvasModesSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import {
  clearIsolatedNodes,
  getIsNodeIsolated,
  switchIsolatedFlags,
} from '@/store/slices/canvasBuildingSlice';
import { NodeType, SelectedNode } from '@/models';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { flattenDeep } from 'lodash';
import { constructionElementTypes } from '@/shared/helpers';

export const useIsolationHandlers = () => {
  const isIsolateModeEnabled = useAppSelector(getStatusIsolateMode);
  const dispatch = useAppDispatch();

  const { clearAllSelectedNodes } = useSelectedNodes();

  const { getNodeData } = useFindNodeData();

  const allowedTypesForIsolation = [...constructionElementTypes];

  const handleIsolateNode = ({
    guid,
    type,
  }: {
    guid: string;
    type: NodeType;
  }) => {
    const nodeData = getNodeData({ guid, nodeType: type });
    if (!nodeData) return;
    dispatch(
      switchIsolatedFlags([
        nodeData.guid,
        ...nodeData.childNodes.map((node) => node.guid),
      ])
    );
    if (!isIsolateModeEnabled) dispatch(setIsolateMode(true));
  };

  const handleIsolateAction = (data: SelectedNode[] = []) => {
    if (data.length > 0) {
      const nodesToIsolate: SelectedNode[] = data.filter((node) =>
        allowedTypesForIsolation.includes(node.type)
      );

      const idsToIsolate =
        flattenDeep(
          nodesToIsolate
            .map((node) =>
              getNodeData({ guid: node.guid, nodeType: node.type })
            )
            .filter((node) => !!node?.guid)
            .map((node) => [
              node!.guid,
              ...node!.childNodes.map((node) => node.guid),
            ])
        ) || [];

      dispatch(switchIsolatedFlags(idsToIsolate));
      dispatch(setIsolateMode(true));
    }
  };

  const isNodeIsolated = (guid: string): boolean =>
    useAppSelector(getIsNodeIsolated(guid));

  const handleDisableIsolateMode = () => {
    dispatch(clearIsolatedNodes());
    clearAllSelectedNodes();
    dispatch(setIsolateMode(false));
  };

  return {
    isIsolateModeEnabled,
    isNodeIsolated,
    handleDisableIsolateMode,
    handleIsolateAction,
    handleIsolateNode,
    allowedTypesForIsolation,
  };
};
