import React from 'react';

const MetricsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8536 7.35355C11.6583 7.54882 11.3417 7.54882 11.1464 7.35355L10.5 6.70711L10.5 13.2929L11.1464 12.6464C11.3417 12.4512 11.6583 12.4512 11.8536 12.6464C12.0488 12.8417 12.0488 13.1583 11.8536 13.3536L10.3536 14.8536C10.1583 15.0488 9.84171 15.0488 9.64645 14.8536L8.14645 13.3536C7.95118 13.1583 7.95118 12.8417 8.14645 12.6464C8.34171 12.4512 8.65829 12.4512 8.85355 12.6464L9.5 13.2929L9.5 6.70711L8.85355 7.35355C8.65829 7.54882 8.34171 7.54882 8.14645 7.35355C7.95118 7.15829 7.95118 6.84171 8.14645 6.64645L9.64645 5.14645C9.84171 4.95118 10.1583 4.95118 10.3536 5.14645L11.8536 6.64645C12.0488 6.84171 12.0488 7.15829 11.8536 7.35355ZM14 17C15.6569 17 17 15.6569 17 14L17 6C17 4.34315 15.6569 3 14 3H6C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34314 17 6 17L14 17ZM16 13H13C13 13.3579 12.8727 13.7158 12.6181 14H16C16 15.1046 15.1046 16 14 16L6 16C4.89543 16 4 15.1046 4 14H7.38191C7.1273 13.7158 7 13.3579 7 13H4L4 7H7C7 6.64208 7.1273 6.28417 7.38191 6L4 6C4 4.89543 4.89543 4 6 4L14 4C15.1046 4 16 4.89543 16 6L12.6181 6C12.8727 6.28417 13 6.64208 13 7L16 7L16 13Z"
        fill="#414042"
      />
    </svg>
  );
};

export default MetricsIcon;
