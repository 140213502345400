import { FlatVector2 } from '@/models';
import { get2DDistance } from '@/shared/helpers/konva';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { addSpacesToThousands } from '@/shared/helpers/format-data';

const ALLOWED_RATIO = 1 / 6;
const MIN_WIDTH_MM = 450;
const MAX_WIDTH_MM = 1600;
const MINIMAL_AREA_MM = 62000;
const MINIMAL_AREA_M = 0.062;
const MIN_HEIGHT_MM = 600;
const MAX_HEIGHT_MM = 2000;

const validateGlassRatio = (
  verticalDistance: number,
  horizontalDistance: number
): boolean => {
  const ratio =
    verticalDistance > horizontalDistance
      ? horizontalDistance / verticalDistance
      : verticalDistance / horizontalDistance;

  return ratio >= ALLOWED_RATIO;
};

const validateGlassArea = (
  verticalDistance: number,
  horizontalDistance: number
): boolean => {
  return verticalDistance * horizontalDistance >= MINIMAL_AREA_MM;
};

const validateMinimalWindowWidth = (horizontalDistance: number): boolean => {
  return horizontalDistance >= MIN_WIDTH_MM;
};

const validateMaximalWindowWidth = (horizontalDistance: number): boolean => {
  return horizontalDistance <= MAX_WIDTH_MM;
};

const validateMinimalWindowHeight = (verticalDistance: number): boolean => {
  return verticalDistance >= MIN_HEIGHT_MM;
};

const validateMaximalWindowHeight = (verticalDistance: number): boolean => {
  return verticalDistance <= MAX_HEIGHT_MM;
};

export const validateWindow = (
  glassPoints: FlatVector2[],
  framePoints: FlatVector2[],
  isImperialSystem: boolean
): string[] => {
  const errorMessages = {
    ratioError: 'Glass limitation exceeds the 1:6 limit',
    glassArea: `Glass area is below ${isImperialSystem ? convertMillimetersToFtInch(MINIMAL_AREA_M) : `${MINIMAL_AREA_M} square meters`}`,
    minWidthError: `Width of the window frame below ${isImperialSystem ? convertMillimetersToFtInch(MIN_WIDTH_MM) : `${MIN_WIDTH_MM} mm`}`,
    maxWidthError: `Width of the window frame over ${isImperialSystem ? convertMillimetersToFtInch(MAX_WIDTH_MM) : `${addSpacesToThousands(MAX_WIDTH_MM.toString(), false)} mm`}`,
    minHeightError: `Height of the window frame is below ${isImperialSystem ? convertMillimetersToFtInch(MIN_HEIGHT_MM) : `${addSpacesToThousands(MIN_HEIGHT_MM.toString(), false)} mm`}`,
    maxHeightError: `Height of the window frame is over ${isImperialSystem ? convertMillimetersToFtInch(MAX_HEIGHT_MM) : `${addSpacesToThousands(MAX_HEIGHT_MM.toString(), false)} mm`}`,
  };

  const verticalGlassLine = [glassPoints[0], glassPoints[1]];
  const horizontalGlassLine = [glassPoints[3], glassPoints[0]];
  const verticalGlassDistance = get2DDistance(
    verticalGlassLine[0],
    verticalGlassLine[1]
  );
  const horizontalGlassDistance = get2DDistance(
    horizontalGlassLine[0],
    horizontalGlassLine[1]
  );

  const ratioError = !validateGlassRatio(
    verticalGlassDistance,
    horizontalGlassDistance
  );
  const glassArea = !validateGlassArea(
    verticalGlassDistance,
    horizontalGlassDistance
  );

  const horizontalWindowLine = [framePoints[0], framePoints[1]];
  const verticalWindowLine = [framePoints[1], framePoints[2]];
  const horizontalWindowDistance = get2DDistance(
    horizontalWindowLine[0],
    horizontalWindowLine[1]
  );
  const verticalWindowDistance = get2DDistance(
    verticalWindowLine[0],
    verticalWindowLine[1]
  );

  const minWidthError = !validateMinimalWindowWidth(horizontalWindowDistance);
  const maxWidthError = !validateMaximalWindowWidth(horizontalWindowDistance);
  const minHeightError = !validateMinimalWindowHeight(verticalWindowDistance);
  const maxHeightError = !validateMaximalWindowHeight(verticalWindowDistance);

  return [
    ratioError ? errorMessages.ratioError : [],
    glassArea ? errorMessages.glassArea : [],
    minWidthError ? errorMessages.minWidthError : [],
    maxWidthError ? errorMessages.maxWidthError : [],
    minHeightError ? errorMessages.minHeightError : [],
    maxHeightError ? errorMessages.maxHeightError : [],
  ].flat();
};
