import React, { useEffect, useMemo, useState } from 'react';
import {
  AddCamera,
  CompassButton,
  MapView,
  MoveMode,
  MoveToTheSite,
  OrthoPerspectiveView,
  RotateMode,
  TextureView,
  TwoDThreeDView,
  SavedCameras,
  IsolateSelection,
} from './actions';
import SaveScreen from './actions/SaveScreen';
import { subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT } from '@/core/event-names';
import { makeScreenshotDelay } from '@/shared/helpers/camera';
import { sleep } from '@/shared/helpers/sleep';

const Toolbar = () => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, toggleAnimation);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, toggleAnimation);
    };
  }, []);

  const toggleAnimation = async () => {
    setIsAnimating(true);
    await sleep(makeScreenshotDelay);
    setIsAnimating(false);
  };

  const Divider = (
    <div className={'bg-light-gray-10 h-5 mx-0.5 my-auto w-0.5'} />
  );

  const actions = useMemo(
    () => [
      {
        element: <IsolateSelection />,
      },
      {
        element: <TwoDThreeDView />,
      },
      {
        element: <OrthoPerspectiveView />,
      },
      {
        element: <TextureView />,
      },
      {
        element: <MapView />,
      },
      {
        element: Divider,
      },
      {
        element: <AddCamera />,
      },

      { element: <SavedCameras /> },
      { element: <SaveScreen /> },
      {
        element: Divider,
      },
      { element: <RotateMode /> },
      { element: <MoveMode /> },
      { element: <MoveToTheSite /> },
      {
        element: Divider,
      },
      { element: <CompassButton /> },
    ],
    []
  );

  return (
    <div
      className={`absolute bottom-[24px] left-[50%] translate-x-[-50%] shadow-toolbar rounded-lg flex bg-white p-0.5 gap-0.5 items-center transition-opacity duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}
    >
      {actions.map((action, i) => (
        <span key={i}>{action.element}</span>
      ))}
    </div>
  );
};

export default Toolbar;
