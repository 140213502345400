import { useParams } from 'react-router';
import { useAppDispatch } from '@/store/hooks';
import { projectsApi } from '@/store/apis/projectsApi';

export const useUpdateProjectCameras = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const updateCameraName = (cameraId: number, newName: string) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        projectData.cameras = projectData.cameras.map((camera) => {
          if (camera.id === cameraId) {
            return {
              ...camera,
              name: newName,
            };
          } else return camera;
        });
      })
    );
  };

  const deleteCamera = (cameraId: number) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        projectData.cameras = projectData.cameras.filter(
          (camera) => camera.id !== cameraId
        );
      })
    );
  };

  return {
    updateCameraName,
    deleteCamera,
  };
};
