import { FlatVector2 } from '@/models';
import { FlatVector2Axis } from '@/components/WindowCreator/models/konva-model';
import { round } from 'mathjs';
import {
  booleanPointOnLine,
  lineIntersect,
  lineOverlap,
  lineString,
} from '@turf/turf';

export const isHorizontal = (points: FlatVector2Axis) =>
  points[0][1] === points[1][1];
export const isPointIntersectLine = (
  point: FlatVector2,
  line: FlatVector2Axis,
  ignoreEndVertices: boolean = false
) =>
  booleanPointOnLine(point, lineString(line), {
    ignoreEndVertices,
  });

export const isLinesIntersect = (
  line1: FlatVector2Axis,
  line2: FlatVector2Axis
) => {
  return (
    lineIntersect(lineString(line1), lineString(line2)).features.length > 0
  );
};

export const findShapeClosestDistanceToAxis = (
  points: FlatVector2[],
  axisDirection: 'x' | 'y',
  pointerPosition: number
) => {
  const axisIdx = axisDirection === 'x' ? 0 : 1;
  return Math.min(
    ...points
      .map((point) => point[axisIdx])
      .map((point) => Math.abs(pointerPosition - point))
  );
};

export const isPointOnContourPoints = (
  point: FlatVector2,
  contourPoints: FlatVector2[]
) =>
  contourPoints.some(
    (contourPoint) =>
      point[0] === contourPoint[0] || point[1] === contourPoint[1]
  );

export const isLineOnLine = (
  line1: FlatVector2Axis,
  line2: FlatVector2Axis
): boolean =>
  lineOverlap(lineString(line1), lineString(line2)).features.length > 0;

export const getExtendedPointByPercentage = (
  point: FlatVector2,
  zeroPoint: FlatVector2,
  percentageIncrease: number,
  direction: 'x' | 'y'
): FlatVector2 => {
  const _dir = direction === 'x' ? 0 : 1;
  const distance = point[_dir] - zeroPoint[_dir];
  if (point[_dir] < zeroPoint[_dir]) {
    return [
      direction === 'x'
        ? round(zeroPoint[0] - Math.abs(distance) * percentageIncrease, 2)
        : point[0],
      direction === 'x'
        ? point[1]
        : round(zeroPoint[1] - Math.abs(distance) * percentageIncrease, 2),
    ];
  } else if (point[0] > zeroPoint[0]) {
    return [
      direction === 'x'
        ? round(zeroPoint[0] + Math.abs(distance) * percentageIncrease, 2)
        : point[0],
      direction === 'x'
        ? point[1]
        : round(zeroPoint[1] + Math.abs(distance) * percentageIncrease, 2),
    ];
  } else return point;
};

export const mirrorPointAcrossX = (
  [x, y]: FlatVector2,
  windowXMidpoint: number
): FlatVector2 => {
  const newX = windowXMidpoint - (x - windowXMidpoint);
  return [newX, y];
};
