import React, { useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { flatten, isEqual } from 'lodash';

import {
  getCenterFromVectorsArray,
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  isPointInsideArea,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  CanvasActionsModes,
  DistanceInput,
  EditModes,
  FlatVector3,
  UserBuildingBlock,
  UserBuildingSurface,
} from '@/models';
import { C_WallMaterial } from '@/shared/materials';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import CutSurface from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutSurface';
import CutFace from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutFace';
import { subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__ESCAPE,
  DIRECTIONAL_INPUT__UPDATE,
} from '@/core/event-names';
import {
  getProcessingEntity,
  resetExternalElementsState,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { setEditMode, setMode } from '@/store/slices/canvasModesSlice';
import { setEditedNode } from '@/store/slices/canvasBuildingSlice';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { getCursorCoordinatesOnOrthographicSystem } from '@/shared/helpers';

interface CutToolProps {
  block: UserBuildingBlock;
  buildingGUID: string;
}

const CutTool: React.FC<CutToolProps> = ({ block, buildingGUID }) => {
  const dispatch = useAppDispatch();
  const multiplyRate = useAppSelector(getMultiplyRate);
  const processingEntity = useAppSelector(getProcessingEntity);
  const three = useThree();

  const [mousePosition, setMousePosition] = useState<THREE.Vector3 | null>(
    null
  );
  const [currentWall, setCurrentWall] = useState<UserBuildingSurface | null>(
    null
  );
  const [cutFaceWallPoints, setCutFaceWallPoints] = useState<FlatVector3[]>();
  const [isAllowedToCut, setIsAllowedToCut] = useState(false);

  const findWallInStoreyByPoints = (
    points: FlatVector3[],
    storeyIndex: number
  ) =>
    block.storeys[storeyIndex].walls.find(
      (wall) =>
        wall.points.reduce((acc, curr) => {
          const isPointExist = points.find((point) =>
            isEqual([curr[0], curr[2]], [point[0], point[2]])
          );
          return isPointExist ? acc + 1 : acc;
        }, 0) === 4
    );

  const translateWallPointsFromBuildingCenter = (
    wallCoordinates: THREE.Vector3[],
    floorPoints: FlatVector3[]
  ) => {
    const normal = getPerpendicularVectorToVectors(wallCoordinates, true);
    const newPoints = wallCoordinates.map((point) =>
      getTranslatedVector(point, 0.00001, normal)
    );

    const wallCenter = getCenterFromVectorsArray(newPoints);
    const isPointInside = isPointInsideArea(
      wallCenter,
      convertFlatVector3ToVectors(floorPoints)
    );

    if (isPointInside) {
      return wallCoordinates.map((point) =>
        getTranslatedVector(point, -0.00001, normal)
      );
    } else {
      return newPoints;
    }
  };

  const generateWallEdge = (
    floorPoints: FlatVector3[],
    ceilingPoints: FlatVector3[],
    storeyIndex: number
  ) => {
    const material = C_WallMaterial.clone();

    const wallEdgeArray: THREE.Mesh[] = [];

    for (let i = 0; i < floorPoints.length - 1; i++) {
      const edge = [
        floorPoints[i],
        floorPoints[i + 1],
        ceilingPoints[i + 1],
        ceilingPoints[i],
      ];

      const position = translateWallPointsFromBuildingCenter(
        convertFlatVector3ToVectors(edge),
        floorPoints
      );

      const wallMesh = new THREE.Mesh(
        createGeometryFromVectorList(position, 'vertical'),
        material
      );
      const wallData = findWallInStoreyByPoints(edge, storeyIndex);

      wallMesh.userData = {
        wallData,
      };

      wallEdgeArray.push(wallMesh);
    }
    return wallEdgeArray;
  };

  const wallArray = useMemo(() => {
    const result: THREE.Mesh[][] = [];

    for (let i = 0; i < block.storeys.length; i++) {
      const floorPoints = block.storeys[i].floor.points;
      const ceilingPoints = block.storeys[i].ceiling.points;
      const edges = generateWallEdge(floorPoints, ceilingPoints, i);
      result.push(edges);
    }
    return flatten(result);
  }, [block]);

  const facadeSurfaceGeometry = useMemo(() => {
    return cutFaceWallPoints
      ? createGeometryFromVectorList(
          translateWallPointsFromBuildingCenter(
            convertFlatVector3ToVectors(cutFaceWallPoints),
            block.storeys[0].floor.points
          ),
          'vertical'
        )
      : null;
  }, [cutFaceWallPoints, block]);

  const handleMove = (position: THREE.Vector3, wall?: UserBuildingSurface) => {
    !isAllowedToCut && setIsAllowedToCut(true);
    if (processingEntity?.active) return;

    wall && setCurrentWall(wall);
    setMousePosition(position);
  };

  const updateDirectionalInputValues = (
    leftDistance: number,
    rightDistance: number
  ) => {
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          processing: true,
          value: convertMetersToMillimeters(
            leftDistance / multiplyRate
          ).toString(),
        },
        {
          type: DistanceInput.RightEdgeDistance,
          processing: true,
          value: convertMetersToMillimeters(
            rightDistance / multiplyRate
          ).toString(),
        },
      ])
    );
  };

  const borderEdgeArray = useMemo(() => {
    const borderHalfSize = 0.015;
    const borderArray: {
      border: THREE.Mesh;
      wall?: UserBuildingSurface;
    }[] = [];

    block.storeys.forEach((storey, storeyIndex) => {
      const points = storey.ceiling.points;
      for (let i = 0; i < points.length - 1; i++) {
        const start = convertFlatVector3ToVector(points[i]);
        const end = convertFlatVector3ToVector(points[i + 1]);

        const nextStoreyPoints = block.storeys[storeyIndex + 1]?.ceiling
          ? convertFlatVector3ToVectors(
              block.storeys[storeyIndex + 1].ceiling.points
            )
          : [];

        const isWallUnderStartExist =
          nextStoreyPoints.length && isPointInsideArea(start, nextStoreyPoints);

        const isWallUnderEndExist =
          nextStoreyPoints.length && isPointInsideArea(end, nextStoreyPoints);

        if (isWallUnderEndExist && isWallUnderStartExist) continue;

        const outerStart = getExtendedVector(start, end, -borderHalfSize);
        const innerStart = getExtendedVector(start, end, borderHalfSize);

        const outerEnd = getExtendedVector(end, start, -borderHalfSize);
        const innerEnd = getExtendedVector(end, start, borderHalfSize);

        const perpendicular = getPerpendicularVectorToVectors(
          [start, end],
          true
        );
        const leftStart = getTranslatedVector(
          innerStart,
          borderHalfSize,
          perpendicular
        );
        const rightStart = getTranslatedVector(
          outerStart,
          -borderHalfSize,
          perpendicular
        );

        const leftEnd = getTranslatedVector(
          innerEnd,
          borderHalfSize,
          perpendicular
        );
        const rightEnd = getTranslatedVector(
          outerEnd,
          -borderHalfSize,
          perpendicular
        );

        borderArray.push({
          border: new THREE.Mesh(
            createGeometryFromVectorList(
              [leftStart, leftEnd, rightEnd, rightStart, leftStart],
              'horizontal'
            ),
            C_WallMaterial
          ),
          wall: findWallInStoreyByPoints(
            [points[i], points[i + 1]],
            storeyIndex
          ),
        });
      }
    });

    return borderArray;
  }, []);

  const borderArray = useMemo(() => {
    const handleMousePosition = (
      wall: UserBuildingSurface,
      mouse: THREE.Vector3
    ) => {
      if (processingEntity?.active) return;
      const start = convertFlatVector3ToVector(wall.points[0]);
      const end = convertFlatVector3ToVector(wall.points[1]);

      const closestPoint = new THREE.Vector3();
      new THREE.Line3(start, end).closestPointToPoint(
        mouse,
        true,
        closestPoint
      );
      handleMove(closestPoint, wall);
    };

    return borderEdgeArray.map((edge) => (
      <primitive
        object={edge.border}
        onPointerMove={(e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          edge.wall && handleMousePosition(edge.wall, e.point);
        }}
        onPointerEnter={() => setIsAllowedToCut(true)}
        key={edge.border.id}
        position={new THREE.Vector3(0, 0.001, 0)}
        visible={false}
      />
    ));
  }, [block]);

  const handleInputLeftEdgeDistance = (distance: string) => {
    const firstPoint = convertFlatVector3ToVector(cutFaceWallPoints![1]);
    const secondPoint = convertFlatVector3ToVector(cutFaceWallPoints![0]);
    const maxDistance = firstPoint.distanceTo(secondPoint);

    const maxDistanceInMillimeters = Number(
      convertMetersToMillimeters(maxDistance / multiplyRate)
    );

    const secondInputValue = (
      maxDistanceInMillimeters - Number(distance)
    ).toString();

    dispatch(
      setDirectionalInputValues([
        { ...processingEntity, value: distance },
        {
          type: DistanceInput.RightEdgeDistance,
          value: secondInputValue,
        },
      ])
    );

    let leftDistance = convertMillimetersToMeters(distance) * multiplyRate;

    if (leftDistance > maxDistance) {
      leftDistance = maxDistance - 0.0001;
    }
    if (leftDistance < 0) {
      leftDistance = 0;
    }

    const extendedVector = getExtendedVector(
      firstPoint,
      secondPoint,
      leftDistance
    );

    setMousePosition(extendedVector);
  };
  const handleInputRightEdgeDistance = (distance: string) => {
    const firstPoint = convertFlatVector3ToVector(cutFaceWallPoints![0]);
    const secondPoint = convertFlatVector3ToVector(cutFaceWallPoints![1]);

    const maxDistance = firstPoint.distanceTo(secondPoint);

    const maxDistanceInMillimeters = Number(
      convertMetersToMillimeters(maxDistance / multiplyRate)
    );

    const secondInputValue = (
      maxDistanceInMillimeters - Number(distance)
    ).toString();

    dispatch(
      setDirectionalInputValues([
        { ...processingEntity, value: distance },
        {
          type: DistanceInput.LeftEdgeDistance,
          value: secondInputValue,
        },
      ])
    );

    let rightDistance = convertMillimetersToMeters(distance) * multiplyRate;

    if (rightDistance > maxDistance) {
      rightDistance = maxDistance - 0.0001;
    }
    if (rightDistance < 0) {
      rightDistance = 0;
    }

    const extendedVector = getExtendedVector(
      firstPoint,
      secondPoint,
      rightDistance
    );

    setMousePosition(extendedVector);
  };
  const onInputUpdate = (evt: CustomEvent) => {
    if (!cutFaceWallPoints || !mousePosition) return;

    switch (processingEntity?.type) {
      case DistanceInput.LeftEdgeDistance: {
        handleInputLeftEdgeDistance(evt.detail);
        break;
      }
      case DistanceInput.RightEdgeDistance: {
        handleInputRightEdgeDistance(evt.detail);
        break;
      }
      default:
        break;
    }
  };

  const onEscape = () => {
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          processing: true,
        },
        {
          type: DistanceInput.RightEdgeDistance,
          processing: true,
        },
      ])
    );
  };

  useEffect(() => {
    if (processingEntity?.active) return;

    if (!mousePosition || !cutFaceWallPoints) return;

    if (!isAllowedToCut) {
      updateDirectionalInputValues(0, 0);
      return;
    }

    const rightDistance = convertFlatVector3ToVector([
      cutFaceWallPoints[0][0],
      0,
      cutFaceWallPoints[0][2],
    ]).distanceTo(mousePosition.clone().setY(0));

    const leftDistance = convertFlatVector3ToVector([
      cutFaceWallPoints[1][0],
      0,
      cutFaceWallPoints[1][2],
    ]).distanceTo(mousePosition.clone().setY(0));

    updateDirectionalInputValues(leftDistance, rightDistance);
  }, [mousePosition, cutFaceWallPoints, currentWall, isAllowedToCut]);

  const resetCutMode = () => {
    dispatch(setEditedNode(undefined));
    dispatch(setEditMode(EditModes.Unset));
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(resetExternalElementsState());
    dispatch(
      setDirectionalInputValues([
        { type: DistanceInput.Distance, processing: true },
      ])
    );
  };

  useEffect(() => {
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    subscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    return () => {
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
      unsubscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    };
  }, [processingEntity, mousePosition]);

  useEffect(() => {
    dispatch(setShowDirectionalInput({ isShow: true }));
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          display: true,
          processing: true,
          value: '0',
        },
        {
          type: DistanceInput.RightEdgeDistance,
          display: true,
          processing: true,
          value: '0',
        },
      ])
    );
    return () => {
      resetCutMode();
    };
  }, []);

  const checkIsMouseOutsideBuilding = (event: PointerEvent) => {
    const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
      event as PointerEvent,
      three.gl
    );
    three.raycaster.setFromCamera(cursorCoordinates, three.camera);
    const objects = [...wallArray, ...borderEdgeArray.map((a) => a.border)];
    const intersects = three.raycaster.intersectObjects(objects, true);

    const intersect = intersects.some((intersect) =>
      objects.find((w) => w.uuid === intersect.object.uuid)
    );

    !intersect && setIsAllowedToCut(false);
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.addEventListener('pointermove', checkIsMouseOutsideBuilding);
    return () => {
      canvas.removeEventListener('pointermove', checkIsMouseOutsideBuilding);
    };
  }, []);

  return (
    <group>
      {borderArray}
      {wallArray.map((edge) => (
        <primitive
          object={edge}
          onPointerMove={(event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation();
            const position = event.pointOnLine ?? event.point;
            handleMove(position, event.object.userData.wallData);
          }}
          key={edge.uuid}
          onPointerEnter={() => setIsAllowedToCut(true)}
          visible={false}
        />
      ))}
      {isAllowedToCut && (
        <>
          <CutSurface
            block={block}
            mousePosition={mousePosition}
            currentWall={currentWall}
            buildingGUID={buildingGUID}
            isAllowedToCut={isAllowedToCut}
          />
          <CutFace
            block={block}
            mousePosition={mousePosition}
            currentWall={currentWall}
            setCutFaceWallPoints={setCutFaceWallPoints}
            cutFaceWallPoints={cutFaceWallPoints}
          />
          {facadeSurfaceGeometry && (
            <mesh
              geometry={facadeSurfaceGeometry}
              material={
                new THREE.MeshBasicMaterial({
                  side: THREE.DoubleSide,
                })
              }
              visible={false}
              onPointerMove={(event) => {
                event.stopPropagation();
                handleMove(event.pointOnLine ?? event.point);
              }}
            />
          )}
        </>
      )}
    </group>
  );
};

export default CutTool;
