import React, { useEffect, useRef, useState } from 'react';
import { IntusIconButton, IntusInput } from '@/shared/elements';
import { PencilIcon } from '@/shared/icons';
import { Form, InputRef } from 'antd';
import { FormInstance } from 'antd/lib';
import { maxLengthValidator } from '@/shared/form/validators';

const WindowName = ({ form }: { form: FormInstance }) => {
  const [isEditing, setIsEditing] = useState(false);
  // Stores the initial 'name' field value to reset input if the user cancels editing without saving.
  const originalNameRef = useRef(form.getFieldValue('name'));
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (!originalNameRef.current) {
      originalNameRef.current = form.getFieldValue('name');
    }
  }, [form.getFieldValue('name')]);

  useEffect(() => {
    isEditing &&
      inputRef.current?.input?.addEventListener('keydown', handleKeyDown);
    isEditing && document.addEventListener('mousedown', handleClickOutside);
    return () => {
      inputRef.current?.input?.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, form, originalNameRef]);

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      event.preventDefault();
      saveName();
    }
    if (event.key === 'Escape') {
      handleResetInput();
    }
  };

  const handleResetInput = () => {
    if (isEditing) {
      setIsEditing(false);
      form.setFieldValue('name', originalNameRef.current);
      form.validateFields();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      inputRef.current?.input &&
      !inputRef.current.input?.contains(event.target as Node) &&
      target.textContent !== 'Save'
    ) {
      handleResetInput();
    }
  };

  const saveName = () => {
    if (form.getFieldError('name').length > 0) return;
    if (form.getFieldValue('name').trim() === '') {
      handleResetInput();
      return;
    }
    originalNameRef.current = form.getFieldValue('name');
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus({
        cursor: 'all',
      });
    }
  }, [isEditing]);

  useEffect(() => {
    if (form.getFieldError('name').length) {
      setIsEditing(true);
    }
  }, [form.getFieldError('name')]);

  return (
    <div className="ml-5">
      <Form.Item
        name="name"
        rules={[maxLengthValidator('Max 50 characters', 50)]}
        className="m-0 max-h-6"
      >
        {isEditing ? (
          <IntusInput
            size="small"
            ref={inputRef}
            autoComplete="off"
            className="w-[220px]"
          />
        ) : (
          <div className="h-6 flex">
            <div className="font-medium px-1 py-0.5 leading-5 text-xs whitespace-nowrap">
              {form.getFieldValue('name')}
            </div>

            <IntusIconButton
              size="small"
              simplified
              transparent
              onClick={() => setIsEditing(true)}
              icon={<PencilIcon />}
            />
          </div>
        )}
      </Form.Item>
    </div>
  );
};

export default WindowName;
