import { get2DDistance } from '@/shared/helpers/konva';
import {
  generateFrameMeasurements,
  generateOffsetMeasurement,
} from './generators';
import { FlatVector2 } from '@/models';
import { WindowFrame } from '@/models/window-configurator.model';

export const calculateHeightFromFloor = (windowData: WindowFrame) => {
  const offsetPoints = generateOffsetMeasurement(windowData);
  return get2DDistance(...offsetPoints);
};

export const calculateWindowDimensions = (windowData: WindowFrame) => {
  const measurementPoints: [FlatVector2, FlatVector2][] = [
    ...generateFrameMeasurements(windowData),
  ];
  return {
    width: get2DDistance(measurementPoints[0][0], measurementPoints[0][1]),
    height: get2DDistance(measurementPoints[1][0], measurementPoints[1][1]),
  };
};
