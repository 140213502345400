import React from 'react';

const NoSavedCamerasIcon = () => {
  return (
    <svg
      width="24"
      height="41"
      viewBox="0 0 24 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.28034 10.7197C2.98745 10.4268 2.51257 10.4268 2.21968 10.7197C1.92678 11.0126 1.92677 11.4874 2.21966 11.7803L3.78662 13.3473C2.72676 13.8827 2 14.9815 2 16.25V24.75C2 26.5449 3.45507 28 5.25 28H13.75C15.0185 28 16.1172 27.2733 16.6526 26.2136L20.7194 30.2805C21.0123 30.5734 21.4872 30.5734 21.7801 30.2805C22.073 29.9876 22.073 29.5127 21.7801 29.2198L3.28034 10.7197ZM15.4765 25.0374C15.3394 25.8672 14.6186 26.5 13.75 26.5H5.25C4.2835 26.5 3.5 25.7165 3.5 24.75V16.25C3.5 15.3813 4.13291 14.6605 4.96275 14.5235L15.4765 25.0374ZM15.5 20.8182V16.25C15.5 15.2835 14.7165 14.5 13.75 14.5H9.18191L7.68194 13H13.75C15.5449 13 17 14.4551 17 16.25V16.423L20.8639 14.105C21.3638 13.8049 22 14.1649 22 14.748V26.25C22 26.5514 21.83 26.7932 21.5967 26.9151L20.5 25.8183V16.0731L17 18.1745V22.3182L15.5 20.8182Z"
        fill="#E6E6E7"
      />
    </svg>
  );
};

export default NoSavedCamerasIcon;
