import React from 'react';

const MoveIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12.0198C16 13.0755 15.7933 14.1209 15.3917 15.0972L14.807 16.5182C14.5783 17.0741 14.1606 17.5312 13.6275 17.8088C13.3614 17.9474 13.0657 18.0198 12.7657 18.0198H10.22C9.44723 18.0198 8.73328 17.6071 8.34758 16.9375C7.84848 16.071 7.33258 15.2341 6.63495 14.5074L5.30661 13.1237C4.86531 12.664 4.34187 12.2757 3.8242 11.8918C3.62414 11.7434 3.42486 11.5956 3.23157 11.4446C3.08542 11.3304 3 11.1553 3 10.9698C3 10.2163 3.53642 9.39514 4.21659 9.17982C5.12686 8.87353 6.01274 8.9671 6.8688 9.39514C6.9136 9.41755 6.95729 9.44115 7 9.46586V4.5C7 3.67157 7.67157 3 8.5 3C8.69512 3 8.88155 3.03726 9.05254 3.10504C9.22589 2.46823 9.80827 2 10.5 2C11.1917 2 11.7741 2.46823 11.9475 3.10504C12.1185 3.03726 12.3049 3 12.5 3C13.3284 3 14 3.67157 14 4.5V4.58535C14.1564 4.53008 14.3247 4.5 14.5 4.5C15.3284 4.5 16 5.17157 16 6V12.0198ZM12 4.5V8.5C12 8.77614 11.7761 9 11.5 9C11.2239 9 11 8.77614 11 8.5V3.5C11 3.22386 10.7761 3 10.5 3C10.2239 3 10 3.22386 10 3.5V8.5C10 8.77614 9.77614 9 9.5 9C9.22386 9 9 8.77614 9 8.5V4.5C9 4.22386 8.77614 4 8.5 4C8.22386 4 8 4.22386 8 4.5L7.9996 10.4997C8.00498 10.6342 7.9563 10.7706 7.85355 10.8733C7.65829 11.0686 7.34171 11.0686 7.14645 10.8733L7.13817 10.865C6.91843 10.6453 6.70391 10.4307 6.42158 10.2896C5.72516 9.94137 4.19944 9.71523 4.01528 10.7879C4.19117 10.9253 4.37122 11.0602 4.55185 11.1956C5.06647 11.5813 5.58591 11.9706 6.028 12.4311L7.35634 13.8148C8.11026 14.6002 8.6745 15.5015 9.21411 16.4384C9.42132 16.7981 9.80486 17.0198 10.22 17.0198H12.7657C12.9049 17.0198 13.0421 16.9862 13.1655 16.9219C13.4894 16.7532 13.7433 16.4755 13.8822 16.1377L14.4669 14.7167C14.8189 13.8612 15 12.945 15 12.0198V6C15 5.72386 14.7761 5.5 14.5 5.5C14.2239 5.5 14 5.72386 14 6V9.5C14 9.77614 13.7761 10 13.5 10C13.2239 10 13 9.77614 13 9.5V4.5C13 4.22386 12.7761 4 12.5 4C12.2239 4 12 4.22386 12 4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoveIcon;
