import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const DotIcon: React.FC<BaseIconProps> = ({ fill = '#D9D8DA' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <circle cx="6" cy="6" r="3" fill={fill} />
    </svg>
  );
};
