import React from 'react';

const IsolateSelectionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2015 6.63507L13.2015 7.95616C13.3829 8.03604 13.5 8.21554 13.5 8.41376V11.5323C13.5 11.7281 13.3857 11.9059 13.2075 11.9872L10.2075 13.3558C10.0757 13.4159 9.92429 13.4159 9.79248 13.3558L6.79248 11.9872C6.61432 11.9059 6.5 11.7281 6.5 11.5323V8.41376C6.5 8.21554 6.61709 8.03604 6.79849 7.95616L9.79849 6.63507C9.92688 6.57853 10.0731 6.57853 10.2015 6.63507Z"
        stroke="currentColor"
      />
      <path
        d="M13.4287 8.28572L10.0001 9.95713M10.0001 9.95713L6.57153 8.28572M10.0001 9.95713V13.4286"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M2 4.34722C2 3.05089 3.05089 2 4.34722 2H5.79167C6.09082 2 6.33333 2.24251 6.33333 2.54167C6.33333 2.84082 6.09082 3.08333 5.79167 3.08333H4.34722C3.6492 3.08333 3.08333 3.6492 3.08333 4.34722V5.79167C3.08333 6.09082 2.84082 6.33333 2.54167 6.33333C2.24251 6.33333 2 6.09082 2 5.79167V4.34722ZM13.6667 2.54167C13.6667 2.24251 13.9092 2 14.2083 2H15.6528C16.9491 2 18 3.05089 18 4.34722V5.79167C18 6.09082 17.7575 6.33333 17.4583 6.33333C17.1592 6.33333 16.9167 6.09082 16.9167 5.79167V4.34722C16.9167 3.6492 16.3508 3.08333 15.6528 3.08333H14.2083C13.9092 3.08333 13.6667 2.84082 13.6667 2.54167ZM2.54167 13.6667C2.84082 13.6667 3.08333 13.9092 3.08333 14.2083V15.6528C3.08333 16.3508 3.6492 16.9167 4.34722 16.9167H5.79167C6.09082 16.9167 6.33333 17.1592 6.33333 17.4583C6.33333 17.7575 6.09082 18 5.79167 18H4.34722C3.05089 18 2 16.9491 2 15.6528V14.2083C2 13.9092 2.24251 13.6667 2.54167 13.6667ZM17.4583 13.6667C17.7575 13.6667 18 13.9092 18 14.2083V15.6528C18 16.9491 16.9491 18 15.6528 18H14.2083C13.9092 18 13.6667 17.7575 13.6667 17.4583C13.6667 17.1592 13.9092 16.9167 14.2083 16.9167H15.6528C16.3508 16.9167 16.9167 16.3508 16.9167 15.6528V14.2083C16.9167 13.9092 17.1592 13.6667 17.4583 13.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IsolateSelectionIcon;
