import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import {
  CanvasActionsModes,
  CanvasMapTypes,
  DrawModes,
  EditModes,
} from '@/models';

type CanvasMapState = {
  mode: CanvasActionsModes;
  mapType: CanvasMapTypes;
  drawMode: DrawModes;
  editMode: EditModes;
  isStoreyCreationStep: boolean;
  isIsolateModeOn: boolean;
  isInDrawMode: boolean;
};

const initialState: CanvasMapState = {
  drawMode: DrawModes.Unset,
  editMode: EditModes.Unset,
  mode: CanvasActionsModes.selection,
  mapType: CanvasMapTypes.light,
  isIsolateModeOn: false,
  isInDrawMode: false,
  isStoreyCreationStep: false,
};

export const canvasModesSlice = createSlice({
  name: 'canvasModes',
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<CanvasActionsModes>) => {
      state.drawMode = DrawModes.Unset;
      state.editMode = EditModes.Unset;
      state.mode = action.payload;
      state.isStoreyCreationStep = false;
    },
    setDrawMode: (state, action: PayloadAction<DrawModes>) => {
      state.mode = CanvasActionsModes.building;
      state.drawMode = action.payload;
      state.isStoreyCreationStep = false;
    },
    setEditMode: (state, action: PayloadAction<EditModes>) => {
      state.mode = CanvasActionsModes.edit;
      state.editMode = action.payload;
    },
    setIsolateMode: (state, action: PayloadAction<boolean>) => {
      state.mode = CanvasActionsModes.selection;
      state.isIsolateModeOn = action.payload;
    },
    setMapType: (state, action: PayloadAction<CanvasMapTypes>) => {
      state.mapType = action.payload;
    },
    resetCanvasMode: (state) => {
      state.drawMode = DrawModes.Unset;
      state.editMode = EditModes.Unset;
      state.mode = initialState.mode;
      state.isStoreyCreationStep = false;
    },
    setStoreyCreationStep: (state) => {
      state.isStoreyCreationStep = true;
    },
    setIsInDrawMode: (state, { payload }: PayloadAction<boolean>) => {
      state.isInDrawMode = payload;
    },
  },
});

export const {
  setMode,
  setDrawMode,
  setEditMode,
  resetCanvasMode,
  setMapType,
  setIsolateMode,
  setIsInDrawMode,
  setStoreyCreationStep,
} = canvasModesSlice.actions;

export const getCanvasMode = (state: RootState) =>
  state.canvasReducer.canvasModes.mode;

export const getDrawMode = (state: RootState) =>
  state.canvasReducer.canvasModes.drawMode;

export const getEditMode = (state: RootState) =>
  state.canvasReducer.canvasModes.editMode;

export const getCanvasMapType = (state: RootState) =>
  state.canvasReducer.canvasModes.mapType;

export const getStatusIsolateMode = (state: RootState) =>
  state.canvasReducer.canvasModes.isIsolateModeOn;

export const getIsInDrawMode = (state: RootState) =>
  state.canvasReducer.canvasModes.isInDrawMode;

export const getIsStoreyCreationStep = (state: RootState) =>
  state.canvasReducer.canvasModes.isStoreyCreationStep;

export const getIsCutModeEnabled = (state: RootState) =>
  state.canvasReducer.canvasModes.editMode === EditModes.Cut;

export const getIsWindowPlacementModeEnabled = (state: RootState) =>
  state.canvasReducer.canvasModes.mode === CanvasActionsModes.windowPlacement;

export const getIsSelectionModeEnabled = (state: RootState) =>
  state.canvasReducer.canvasModes.mode === CanvasActionsModes.selection;
