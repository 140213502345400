import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate('/dashboard/projects');
    else {
      loginWithRedirect({
        appState: {
          returnTo: '/dashboard/projects',
        },
      });
    }
  });
  return <></>;
};

export default Auth;
