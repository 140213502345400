import React from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';

import TreeLoader from './TreeLoader';

interface IntusLoaderProps extends SpinProps {
  loading?: boolean;
  embedded?: boolean;
}

const IntusLoader: React.FC<IntusLoaderProps> = ({
  embedded = true,
  loading = true,
  ...rest
}) => {
  return (
    <Spin
      spinning={loading}
      indicator={<TreeLoader isMinimal={embedded} />}
      {...rest}
    />
  );
};

export default IntusLoader;
