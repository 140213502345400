import React, { useEffect, useRef } from 'react';
import { IntusInput } from '@/shared/elements';
import { InputRef } from 'antd';
import { InputProps } from 'antd/lib';
import EditIcon from '@/components/Toolbar/icons/EditIcon';
import DeleteIcon from '@/components/Toolbar/icons/DeleteIcon';
import useDebouncedClick from '@/shared/hooks/UseDebouncedClick';

interface EditableFieldProps extends InputProps {
  isEditing: boolean;
  onEscape: () => void;
  onClickOutside: () => void;
  setEditMode?: (value: boolean) => void;
  textClassNames?: string;
  textSize: 'xs' | 'sm';
  focusCursor?: 'all' | 'end' | 'start';
  hasEditIcon?: boolean;
  clickAction?: () => void;
  deleteAction?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
}

const EditableField: React.FC<EditableFieldProps> = ({
  isEditing,
  onEscape,
  onClickOutside,
  setEditMode,
  textClassNames,
  size,
  textSize = 'sm',
  value,
  focusCursor,
  hasEditIcon,
  clickAction,
  deleteAction,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<InputRef>(null);

  const handleEscape = () => {
    onEscape();
  };

  const handleEdit = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setEditMode && setEditMode(true);
  };

  const handleClick = useDebouncedClick({
    clickAction,
    doubleClickAction: handleEdit,
  });

  const resetEditing = () => {
    handleEscape();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    switch (event.key) {
      case 'Escape': {
        resetEditing();
        break;
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current?.input &&
      !inputRef.current.input?.contains(event.target as Node)
    ) {
      onClickOutside();
    }
  };

  useEffect(() => {
    isEditing &&
      inputRef.current?.input?.addEventListener('keydown', handleKeyDown);
    isEditing && document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      inputRef.current?.input?.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, value, handleClickOutside]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (isEditing) {
        inputRef.current?.focus({
          cursor: focusCursor ?? 'all',
        });
      }
    });
  }, [isEditing]);

  return isEditing ? (
    <IntusInput
      value={value}
      autoFocus
      ref={inputRef}
      size={size}
      className={'z-40 font-light'}
      placeholder={'Edit'}
      maxLength={50}
      {...rest}
    />
  ) : (
    <div
      onClick={(e) => !disabled && handleClick(e)}
      className={textClassNames + ` text-${textSize}` + ' ml-1'}
    >
      <span
        className={`
          w-full
          ${hasEditIcon && deleteAction ? 'group-hover:w-[68%]  truncate ...' : ''}
        `}
      >
        {value?.toString()}
      </span>
      <div className="flex justify-between gap-1 w-0 group-hover:w-max">
        {hasEditIcon ? (
          <div
            className="flex items-center min-h-[26px] opacity-0 group-hover:opacity-100"
            onClick={(e) => handleEdit(e)}
          >
            <EditIcon />
          </div>
        ) : null}
        {deleteAction ? (
          <div
            className="flex items-center min-h-[26px] opacity-0 group-hover:opacity-100"
            onClick={(e) => deleteAction(e)}
          >
            <DeleteIcon />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditableField;
