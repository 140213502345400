import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IntusInput } from '@/shared/elements';
import { InputProps as AntdInputProps } from 'antd/lib';
import tabIcon from '@/images/tab-icon.svg';
import { Form, InputRef } from 'antd';
import { publish } from '@/core/events';
import {
  DIRECTIONAL_INPUT__ESCAPE,
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
} from '@/core/event-names';
import { DistanceInput, NumberedInput, UnitSystemTypes } from '@/models';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import {
  DirectionalInputEntity,
  getDirectionalInputValues,
  getProcessingEntity,
  setDirectionalInputValues,
} from '@/store/slices/canvasExternalElementsSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { convertInputValueToMillimeters } from '@/shared/helpers/directional-input';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import {
  addSpacesToThousands,
  removeSpacesFromThousands,
  sanitizeInputMetricValue,
} from '@/shared/helpers/format-data';
import { limitsValidator } from '@/shared/form/validators';
import useMouseOverCanvas from '@/shared/hooks/useMouseOverCanvas';

interface DirectionalInputProps extends AntdInputProps {
  userUnitType?: UnitSystemTypes;
}

const DirectionalInput: React.FC<DirectionalInputProps> = ({
  userUnitType,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const allInputValuesToDisplay = useAppSelector(
    getDirectionalInputValues
  ).filter((v) => v.display);
  const activeValueIdx = allInputValuesToDisplay.findIndex((v) => v.active);
  const processingEntity = useAppSelector(getProcessingEntity)!;

  const convertInputValue =
    userUnitType === UnitSystemTypes.Metric ||
    processingEntity?.type === NumberedInput.Floors;

  const convertInputValueBasedOnSystem = (
    entity: DirectionalInputEntity
  ): string => {
    switch (entity?.type) {
      // For some reason marked as unused code. it is used :-)
      case DistanceInput.Distance:
      case DistanceInput.Length:
      case DistanceInput.Width:
      case DistanceInput.BuildingWidth:
      case DistanceInput.LeftEdgeDistance:
      case DistanceInput.RightEdgeDistance:
        return userUnitType === UnitSystemTypes.Metric
          ? Number(entity?.value).toFixed(0) ?? 0
          : convertMillimetersToFtInch(entity.value, true);
      default:
        return entity?.value.toString();
    }
  };

  const [position, setPosition] = useState<{ x: number; y: number }>(null!);

  const inputRef = useRef<InputRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useMouseOverCanvas(setPosition);

  useEffect(() => {
    const externalValue = convertInputValueBasedOnSystem(processingEntity);
    externalValue !== form.getFieldValue('directionalInput') &&
      !processingEntity?.active &&
      form.setFieldValue('directionalInput', externalValue);
  }, [processingEntity?.value]);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab' && wrapperRef.current) {
      // In future -> Use 'locked' prop to ignore values, that are locked to being active
      let nextActiveIdx;
      if (
        (activeValueIdx === -1 ||
          activeValueIdx === allInputValuesToDisplay.length - 1) &&
        !allInputValuesToDisplay[0].locked
      ) {
        nextActiveIdx = 0;
      } else if (!allInputValuesToDisplay[activeValueIdx + 1].locked) {
        nextActiveIdx = activeValueIdx + 1;
      } else {
        nextActiveIdx = activeValueIdx;
      }
      form.setFieldsValue({
        directionalInput: addSpacesToThousands(
          convertInputValueBasedOnSystem(
            allInputValuesToDisplay[nextActiveIdx]
          ),
          isImperialUnits
        ),
      });
      dispatch(
        setDirectionalInputValues([
          {
            ...allInputValuesToDisplay[nextActiveIdx],
            active: true,
            processing: true,
          },
        ])
      );
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onKeyActionsWithInput = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (processingEntity.active) {
        event.preventDefault();
        event.stopPropagation();
        form
          .validateFields(['directionalInput'])
          .then(() => {
            dispatch(
              setDirectionalInputValues([
                { ...processingEntity, active: false },
              ])
            );
            publish(
              DIRECTIONAL_INPUT__SET,
              convertInputValueToMillimeters(
                removeSpacesFromThousands(
                  form.getFieldValue('directionalInput')?.toString(),
                  isImperialUnits
                ),
                convertInputValue
              )
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else if (event.key === 'Escape') {
      if (processingEntity.active) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(
          setDirectionalInputValues([{ ...processingEntity, active: false }])
        );
        publish(DIRECTIONAL_INPUT__ESCAPE);
      }
    }
  };

  useEffect(() => {
    if (processingEntity?.active) {
      inputRef.current!.focus({ cursor: 'all' });
    }
  }, [processingEntity?.active, processingEntity?.type]);

  useEffect(() => {
    inputRef.current?.input?.addEventListener('keydown', onKeyActionsWithInput);
    return () => {
      inputRef.current?.input?.removeEventListener(
        'keydown',
        onKeyActionsWithInput
      );
    };
  }, [inputRef.current, processingEntity]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [processingEntity]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const sanitizedValueWithoutSpaces = sanitizeInputMetricValue(
      removeSpacesFromThousands(e.target.value, isImperialUnits),
      isImperialUnits,
      1,
      processingEntity?.type === NumberedInput.Floors
    );

    form.setFieldValue(
      'directionalInput',
      addSpacesToThousands(sanitizedValueWithoutSpaces, isImperialUnits)
    );

    form
      .validateFields(['directionalInput'])
      .then(() => {
        dispatch(
          setDirectionalInputValues([
            {
              ...processingEntity,
              value: convertInputValueToMillimeters(
                sanitizedValueWithoutSpaces,
                convertInputValue
              ),
            },
          ])
        );

        publish(
          DIRECTIONAL_INPUT__UPDATE,
          convertInputValueToMillimeters(
            sanitizedValueWithoutSpaces,
            convertInputValue
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (!position) return <></>;

  return (
    <div
      ref={wrapperRef}
      className={
        'absolute text-xs p-2.5 pb-1 rounded-lg border border-solid border-light-gray-40 shadow-toolbar ' +
        (processingEntity?.active ? 'bg-white' : 'bg-light-gray-10')
      }
      style={{
        top: position.y - 35,
        left: position.x + 17,
      }}
    >
      <div className="flex items-center pb-3">
        <img src={tabIcon} alt="tab icon" className="pr-1" />
        <span>to input</span>
      </div>
      <div>
        {allInputValuesToDisplay.map((v) => (
          <div key={v.type} className="flex items-start mb-1 min-h-6">
            {v.prependIcon ? (
              <img
                src={v.prependIcon}
                alt={v.type}
                className={`pr-1 ${v.active ? 'pt-1' : ''}`}
              />
            ) : (
              <span className={`pr-1 ${v.active ? 'pt-1' : ''}`}>
                {v.type}:{' '}
              </span>
            )}
            {v.active ? (
              <Form form={form}>
                <Form.Item
                  name="directionalInput"
                  className="mb-0"
                  rules={
                    processingEntity.min !== null &&
                    processingEntity.max !== null
                      ? [
                          limitsValidator({
                            min: processingEntity.min,
                            max: processingEntity.max,
                            isImperialUnits,
                            staticValue:
                              processingEntity.type === NumberedInput.Floors,
                            customValidationMessage:
                              processingEntity.validationMessage,
                          }),
                        ]
                      : []
                  }
                >
                  <IntusInput
                    ref={inputRef}
                    autoFocus
                    autoComplete="off"
                    onChange={handleInputChange}
                    className="w-[110px] flex-none"
                    {...rest}
                  />
                </Form.Item>
              </Form>
            ) : (
              <span className="font-medium">
                {addSpacesToThousands(
                  convertInputValueBasedOnSystem(v),
                  isImperialUnits
                )}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DirectionalInput;
