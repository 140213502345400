import React from 'react';
import {
  HeaderPanelItemProps,
  NodeType,
  UserBuildingBlock,
  UserBuildingCoordinatesObject,
} from '@/models';
import BrowserPanelItem from '../BrowserPanelItem';
import { IntusCollapse } from '@/shared/elements';
import WallPanelItem from './WallPanelItem';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useAppSelector } from '@/store/hooks';
import { getIsNodeSelected } from '@/store/slices/canvasBuildingSlice';

interface FloorsPanelItemProps extends HeaderPanelItemProps {
  building: UserBuildingCoordinatesObject;
  block: UserBuildingBlock;
  parentBlockGUID: string;
}

const FloorsPanelItem: React.FC<FloorsPanelItemProps> = ({
  depthLevel,
  keys,
  onCollapseChange,
  building,
  block,
  isParentSelected,
  isParentLocked,
  isParentHidden,
  parentBlockGUID,
}) => {
  const userBuildingUtils = useUpdateUserBuildingData();

  const isParentBlockSelected = useAppSelector(
    getIsNodeSelected(parentBlockGUID)
  );

  const handleLockUnlockFloor = (
    storeyGUID: string,
    previousValue: boolean
  ) => {
    userBuildingUtils.updateUserBuildingFloorUserData({
      buildingGUID: building.guid,
      blockGUID: block.guid,
      storeyGUID,
      key: 'isLocked',
      value: !previousValue,
    });
  };

  const handleRenameFloor = (storeyGUID: string, newName: string) => {
    userBuildingUtils.renameUserBuildingFloor({
      buildingGUID: building.guid,
      blockGUID: block.guid,
      storeyGUID,
      name: newName,
    });
  };

  const getFloors = () => {
    return [...block.storeys]
      .sort((a, b) => b.storeyNumber - a.storeyNumber)
      .map((storey) => {
        return {
          key: storey.guid,
          label: (
            <BrowserPanelItem
              text={storey.name || `Floor ${storey.storeyNumber}`}
              nodeType={NodeType.Storey}
              elementGUID={storey.guid}
              isLocked={!!storey.userData?.isLocked}
              isHidden={!!storey.userData?.isHidden}
              isParentLocked={isParentLocked}
              isParentHidden={isParentHidden}
              isParentSelected={isParentSelected || isParentBlockSelected}
              lockUnlockAction={() =>
                handleLockUnlockFloor(storey.guid, !!storey.userData?.isLocked)
              }
              renameAction={(name) => handleRenameFloor(storey.guid, name)}
              depthLevel={depthLevel}
            />
          ),
          children: (
            <>
              {storey.walls.map((wall, wallIndex) => (
                <WallPanelItem
                  depthLevel={depthLevel + 1}
                  wall={wall}
                  key={wall.guid}
                  isParentSelected={isParentSelected || isParentBlockSelected}
                  parentStoreyGUID={storey.guid}
                  isParentLocked={isParentLocked || !!storey.userData?.isLocked}
                  isParentHidden={isParentHidden || !!storey.userData?.isHidden}
                  storeyGUID={storey.guid}
                  blockGUID={block.guid}
                  buildingGUID={building.guid}
                  wallIndex={wallIndex}
                />
              ))}
            </>
          ),
        };
      });
  };

  return (
    <IntusCollapse
      items={getFloors()}
      collapsible={'icon'}
      onChange={onCollapseChange}
      activeKey={keys}
      destroyInactivePanel={true}
    />
  );
};

export default FloorsPanelItem;
