import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import TexturedIcon from '../icons/TexturedIcon';
const TextureView = () => {
  return (
    <ToolbarButton
      icon={<TexturedIcon />}
      tooltipText={'Switch to Uncolored facades'}
      disabled
    />
  );
};

export default TextureView;
