import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import './AntdTextArea.scss';

const IntusTextArea: React.FC<TextAreaProps> = ({ ...rest }) => {
  return <TextArea rootClassName={'intus-textarea'} {...rest} />;
};

export default IntusTextArea;
