import React from 'react';
import resolutionErrorIcon from '@/images/resolution-error.svg';

const ResolutionError = () => {
  return (
    <div className="absolute inset-x-0 inset-y-0 p-4 bg-white xl:hidden flex justify-center items-center flex-col z-30">
      <img
        className="h-[160px]"
        alt="low resolution icon"
        src={resolutionErrorIcon}
      />
      <div className="max-w-xl font-medium text-sm/[21px] mb-1">
        The resolution of your screen is too small
      </div>
      <div className="max-w-xl font-light text-sm/[21px] text-center">
        Web Designer is limited to screen resolutions smaller than 1280px to
        provide you with the best user experience. To use Web Designer, please
        increase the size of your web browser window or log in from another
        device
      </div>
    </div>
  );
};

export default ResolutionError;
