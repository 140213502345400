import React from 'react';
import CubeAreaIcon from '@/shared/icons/CubeAreaIcon';
import PropertyList from '../PropertyList/PropertyList';
import { getAreaUnit } from '@/shared/helpers/format-data';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { UnitSystemTypes } from '@/models';

interface TotalSurfaceProps {
  facadesArea: string;
  grossInternalArea?: string;
}

const TotalSurface: React.FC<TotalSurfaceProps> = ({
  facadesArea,
  grossInternalArea,
}) => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const properties = [
    {
      name: 'Facades area',
      value: facadesArea,
      units: getAreaUnit(isImperialUnits),
    },
  ];

  if (grossInternalArea) {
    properties.push({
      name: 'Gross internal area',
      value: grossInternalArea,
      units: getAreaUnit(isImperialUnits),
    });
  }

  return (
    <div className="text-xs">
      <div className="px-3 pt-3 flex items-center justify-start gap-1 h-5">
        <CubeAreaIcon />
        <span className="font-medium ">TOTAL SURFACE</span>
      </div>
      <PropertyList properties={properties} />
    </div>
  );
};

export default TotalSurface;
