import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
const Root = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/auth');
  });

  return <></>;
};
export default Root;
