import { CustomUserData } from './structures.model';

export enum CanvasActionsModes {
  selection = 'Selection',
  building = 'Building',
  createWindow = 'CreateWindow',
  windowPlacement = 'WindowPlacement',
  edit = 'Edit',
  move = 'Move',
  rotate = 'Rotate',
}

export enum CanvasMapTypes {
  light = 0,
  satellite = 1,
}

export enum CanvasCameraPolarAngles {
  topView = 0,
  defaultView = 1,
  customView = 2,
}

export enum CanvasCameraCenterMode {
  site = 0,
  custom = 1,
}

export enum CanvasCameraType {
  Perspective = 'Perspective',
  Orthographic = 'Orthographic',
}

export type FlatVector3 = [number, number, number];

export type FlatVector2 = [number, number];

export interface CanvasCamera {
  name: string;
  position: FlatVector3;
  target: FlatVector3;
  zoom: number;
  type: CanvasCameraType.Perspective | CanvasCameraType.Orthographic;
  fov: number;
}

export enum SelectedNodeSource {
  Viewer = 'Viewer',
  Panel = 'Panel',
}

export enum NodeType {
  Wall = 'Wall',
  Walls = 'Walls',
  Floor = 'Floor',
  Ceiling = 'Ceiling',
  Storey = 'Storey',
  Building = 'Building',
  Block = 'Block',
  Panel = 'Panel',
  Environment = 'Environment',
  ConstructionSite = 'ConstructionSite',
  SurroundingBuilding = 'SurroundingBuilding',
  SurroundingBuildings = 'SurroundingBuildings',
}

export interface IsolatePayload {
  isIsolated: boolean;
  guid: string;
}

export interface SelectedNode {
  type: NodeType;
  guid: string;
  source?: SelectedNodeSource;
  userData?: CustomUserData;
}

export enum DrawModes {
  Unset = 'none',
  FreeDraw = 'FreeDraw',
  Rectangle = 'Rectangle',
  CenterLine = 'CenterLine',
}

export enum EditModes {
  Unset = 'none',
  Split = 'Split',
  Cut = 'Cut',
}
