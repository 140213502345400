import { SCALE_SPEED } from '@/components/WindowCreator/constants';
import { KonvaEventObject } from 'konva/lib/Node';

export const useKonvaHandlers = ({
  maxZoom,
  minZoom,
}: {
  maxZoom: number;
  minZoom: number;
}) => {
  const wheelHandler = (e: KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();
    const stage = e.target.getStage()!;

    // MIN_ZOOM
    if (stage.scaleX() <= minZoom && e.evt.deltaY > 0) {
      return;
    }

    // MAX_ZOOM
    if (stage.scaleX() >= maxZoom && e.evt.deltaY < 0) {
      return;
    }
    const oldScale = stage.scaleX();
    const newScale =
      e.evt.deltaY < 0 ? oldScale * SCALE_SPEED : oldScale / SCALE_SPEED;
    const mousePointTo = {
      x: stage.getPointerPosition()!.x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition()!.y / oldScale - stage.y() / oldScale,
    };
    return {
      scale: newScale,
      x: (stage.getPointerPosition()!.x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition()!.y / newScale - mousePointTo.y) * newScale,
    };
  };

  return {
    wheelHandler,
  };
};
