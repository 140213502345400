import React from 'react';

import { FixedWindow } from '@/components/WindowCreator/elements/InnerWindow/FixedWindow';
import OperableWindow from '@/components/WindowCreator/elements/InnerWindow/OperableWindow';
import {
  InnerWindowData,
  OperationType,
} from '@/models/window-configurator.model';
import { FlatVector2, UnitSystemTypes } from '@/models';
import { View } from '../../models/konva-model';
import { swapDiagonalCorners } from '../../helpers';
import { mirrorPointAcrossX } from '../../helpers/direction.helpers';
import { get2DCenter } from '@/shared/helpers/konva';

interface InnerWindowProps {
  windowData: InnerWindowData;
  outerFramePoints: FlatVector2[];
  frameColor: string;
  scale: number;
  units: UnitSystemTypes;
  independent?: boolean;
  view: View;
}

const InnerWindow = ({
  scale,
  independent,
  windowData,
  outerFramePoints,
  frameColor,
  units,
  view,
}: InnerWindowProps) => {
  const windowXMidpoint = get2DCenter(
    outerFramePoints[0],
    outerFramePoints[1]
  )[0];
  const windowPoints =
    view === View.Outside
      ? windowData.points
      : swapDiagonalCorners(
          windowData.points.map((point) =>
            mirrorPointAcrossX(point, windowXMidpoint)
          )
        );
  if (windowData?.operationType === OperationType.Fixed) {
    return (
      <FixedWindow
        outerFramePoints={outerFramePoints}
        points={windowPoints}
        independent={independent ?? true}
        scale={scale}
        view={view}
      />
    );
  }

  return (
    <OperableWindow
      points={windowPoints}
      scale={scale}
      outerFramePoints={outerFramePoints}
      operationType={windowData.operationType}
      independent={true}
      units={units}
      frameColor={frameColor}
      view={view}
    />
  );
};

export default InnerWindow;
