import React from 'react';
import { BaseIconProps } from '@/models';

export const AreaIcon: React.FC<BaseIconProps> = ({ fill = '#000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect
        x="2.5"
        y="2.5"
        width="11"
        height="11"
        stroke={fill}
        strokeDasharray="1 1"
      />
      <rect x="12" y="1" width="3" height="3" rx="1.5" fill={fill} />
      <rect x="12" y="12" width="3" height="3" rx="1.5" fill={fill} />
      <rect x="1" y="1" width="3" height="3" rx="1.5" fill={fill} />
      <rect x="1" y="12" width="3" height="3" rx="1.5" fill={fill} />
    </svg>
  );
};
