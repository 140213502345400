import React from 'react';
import { EditModes, NodeType, UserBuildingCoordinatesObject } from '@/models';
import { useAppSelector } from '@/store/hooks';
import {
  getEditedNode,
  getExtrudeNode,
  getSelectedNodeByGUID,
} from '@/store/slices/canvasBuildingSlice';
import Block from '@/routes/dashboard/projects/project/UserBuilding/components/Block';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import ExtrudeBlock from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeBlock';
import CutTool from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutTool';
import { getEditMode } from '@/store/slices/canvasModesSlice';

interface BuildingProps {
  building: UserBuildingCoordinatesObject;
}

const Building: React.FC<BuildingProps> = ({ building }) => {
  const isBuildingSelected = !!useAppSelector(
    getSelectedNodeByGUID(building.guid)
  );

  const isBuildingVisible = !building.userData?.isHidden;
  const extrudeNode = useAppSelector(getExtrudeNode);
  const editMode = useAppSelector(getEditMode);
  const editedNode = useAppSelector(getEditedNode);

  const { isNodeHovered } = useHoveredNode({ nodeGUID: building.guid });

  return (
    <>
      {isBuildingVisible && (
        <group
          key={`building-${building.guid}`}
          userData={{
            ...building.userData,
            nodeType: NodeType.Building,
          }}
        >
          {building.blocks
            .filter((block) => !block.userData?.isHidden)
            .map((block) => {
              const isBlockInExtrudeMode =
                extrudeNode && extrudeNode.node.guid === block.guid;

              if (isBlockInExtrudeMode) {
                return (
                  <ExtrudeBlock
                    block={block}
                    key={block.guid}
                    extrudeNode={extrudeNode}
                    buildingGuid={building.guid}
                  />
                );
              }

              return (
                <Block
                  buildingGUID={building.guid}
                  block={block}
                  key={block.guid}
                  isParentSelected={isBuildingSelected}
                  isParentHovered={isNodeHovered}
                  isParentLocked={building.userData?.isLocked}
                />
              );
            })}
        </group>
      )}
      {editMode === EditModes.Cut &&
        editedNode?.guid === building.guid &&
        building.blocks.length === 1 && (
          <CutTool block={building.blocks[0]} buildingGUID={building.guid} />
        )}
    </>
  );
};

export default Building;
