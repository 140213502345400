import get from 'lodash/get';
import { notification } from 'antd/lib';
import { ArgsProps } from 'antd/lib/notification';
import { CustomError } from '@/models';

export const extractErrorMessage = (err: CustomError | unknown): string => {
  return get(err, 'data[0].errorMessage', 'Something Went wrong');
};

export const NotificationService = {
  error: (error: CustomError | unknown) =>
    notification.error({
      message: 'Error',
      description: extractErrorMessage(error),
    }),
  success: (argsProps: ArgsProps) =>
    notification.success({
      message: 'Success',
      description: argsProps.message,
    }),
  info: (argsProps: ArgsProps) =>
    notification.info({ message: argsProps.message }),
  warning: (argsProps: ArgsProps) =>
    notification.warning({ message: argsProps.message }),
};
