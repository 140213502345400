import React from 'react';
import BrowserPanelItem from '../BrowserPanelItem';
import { NodeType, PanelItemProps, UserBuildingSurface } from '@/models';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useAppSelector } from '@/store/hooks';
import { getIsNodeSelected } from '@/store/slices/canvasBuildingSlice';

interface WallPanelItemProps extends PanelItemProps {
  wall: UserBuildingSurface;
  isParentSelected?: boolean;
  blockGUID: string;
  isParentLocked?: boolean;
  isParentHidden?: boolean;
  buildingGUID: string;
  storeyGUID: string;
  wallIndex: number;
  parentStoreyGUID: string;
}

const WallPanelItem: React.FC<WallPanelItemProps> = ({
  depthLevel,
  wall,
  isParentSelected,
  isParentLocked,
  isParentHidden,
  blockGUID,
  storeyGUID,
  buildingGUID,
  wallIndex,
  parentStoreyGUID,
}) => {
  const userBuildingUtils = useUpdateUserBuildingData();

  const isParentStoreySelected = useAppSelector(
    getIsNodeSelected(parentStoreyGUID)
  );

  const handleLockUnlockWall = () => {
    userBuildingUtils.updateWallUserData({
      wallGUID: wall.guid,
      blockGUID: blockGUID,
      buildingGUID: buildingGUID,
      storeyGUID: storeyGUID,
      key: 'isLocked',
      value: !wall.userData?.isLocked,
    });
  };

  const handleRenameWall = (newName: string) => {
    userBuildingUtils.updateWallName({
      wallGUID: wall.guid,
      blockGUID: blockGUID,
      buildingGUID: buildingGUID,
      storeyGUID: storeyGUID,
      name: newName,
    });
  };

  return (
    <BrowserPanelItem
      text={wall.name ?? `Wall ${wallIndex + 1}`}
      nodeType={NodeType.Wall}
      elementGUID={wall.guid}
      isParentSelected={isParentSelected || isParentStoreySelected}
      isLocked={!!wall.userData?.isLocked}
      isHidden={!!wall.userData?.isHidden}
      isParentLocked={isParentLocked}
      isParentHidden={isParentHidden}
      lockUnlockAction={handleLockUnlockWall}
      renameAction={(name) => handleRenameWall(name)}
      depthLevel={depthLevel}
      isLastChildItem={true}
    />
  );
};

export default WallPanelItem;
