import React from 'react';

const OrthoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2015 2.63507L17.2015 5.71761C17.3829 5.79749 17.5 5.97699 17.5 6.17521V13.7075C17.5 13.9034 17.3857 14.0812 17.2075 14.1624L10.2075 17.3558C10.0757 17.4159 9.92429 17.4159 9.79248 17.3558L2.79248 14.1624C2.61432 14.0812 2.5 13.9034 2.5 13.7075V6.17521C2.5 5.97699 2.61709 5.7975 2.79849 5.71761L9.79849 2.63507C9.92688 2.57853 10.0731 2.57853 10.2015 2.63507Z"
        stroke="currentColor"
      />
      <path
        d="M16.8571 6.57227L9.99996 9.91508M9.99996 9.91508L3.14282 6.57227M9.99996 9.91508V16.858"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default OrthoIcon;
