import React from 'react';

interface CompassIconProps {
  northOriented: boolean;
}

export const CompassIcon: React.FC<CompassIconProps> = ({ northOriented }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1699 10.0586H13.1744C13.1691 10.1406 13.1439 10.2207 13.1003 10.2912L10.4793 18.6482C10.3319 19.1186 9.66626 19.1186 9.51886 18.6482L6.89786 10.2918C6.85431 10.2211 6.82898 10.1409 6.8238 10.0586L7.87986 10.0586L9.99926 16.8166L12.1699 10.0586Z"
        fill="#414042"
      />
      <path
        d="M9.12086 10.0586C9.15126 10.5198 9.53046 10.8854 9.99926 10.8854C10.4681 10.8854 10.8473 10.5198 10.8777 10.0586C10.8473 9.59736 10.468 9.23141 9.99925 9.23141C9.53045 9.23141 9.15126 9.59736 9.12086 10.0586Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0053 1.00096C9.89719 0.999662 9.79158 1.03319 9.70405 1.09659C9.61652 1.15998 9.55173 1.24988 9.51926 1.35296L6.89786 9.71056C6.84399 9.79764 6.81802 9.89911 6.82346 10.0014C6.82249 10.0205 6.82261 10.0396 6.8238 10.0586L7.87986 10.0586H9.12086C9.15126 9.59736 9.53045 9.23141 9.99925 9.23141C10.468 9.23141 10.8473 9.59736 10.8777 10.0586L12.1699 10.0586H13.1744C13.1756 10.0391 13.1757 10.0195 13.1747 9.99996C13.1799 9.89798 13.1539 9.79682 13.1003 9.70996L10.4795 1.35296C10.4477 1.25175 10.3847 1.16319 10.2995 1.09997C10.2143 1.03674 10.1113 1.00208 10.0053 1.00096Z"
        fill={northOriented ? '#D23736' : '#414042'}
      />
    </svg>
  );
};
