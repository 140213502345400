import React from 'react';

const AddCameraIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 3C4.11929 3 3 4.11929 3 5.5V8.59971C3.31679 8.43777 3.65136 8.30564 4 8.20703V5.5C4 4.67157 4.67157 4 5.5 4H12.5C13.3284 4 14 4.67157 14 5.5V12.5C14 13.3284 13.3284 14 12.5 14H10.9776C10.9466 14.3434 10.8841 14.6777 10.793 15H12.5C13.8807 15 15 13.8807 15 12.5V11.5L17.4 13.3C18.0592 13.7944 19 13.324 19 12.5V5.49998C19 4.67594 18.0592 4.20556 17.4 4.69998L15 6.49998V5.5C15 4.11929 13.8807 3 12.5 3H5.5ZM15 7.74998L18 5.49998V12.5L15 10.25V7.74998ZM10 13.5C10 15.9853 7.98528 18 5.5 18C3.01472 18 1 15.9853 1 13.5C1 11.0147 3.01472 9 5.5 9C7.98528 9 10 11.0147 10 13.5ZM6 11.5C6 11.2239 5.77614 11 5.5 11C5.22386 11 5 11.2239 5 11.5V13H3.5C3.22386 13 3 13.2239 3 13.5C3 13.7761 3.22386 14 3.5 14H5L5 15.5C5 15.7761 5.22386 16 5.5 16C5.77614 16 6 15.7761 6 15.5V14H7.5C7.77614 14 8 13.7761 8 13.5C8 13.2239 7.77614 13 7.5 13H6V11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AddCameraIcon;
