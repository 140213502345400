import React from 'react';
import Split, { SplitProps } from 'react-split';
import './IntusSplit.scss';

interface IntusSplitProps extends SplitProps {
  children: React.ReactNode;
}

const IntusSplit: React.FC<IntusSplitProps> = ({ children, ...props }) => {
  return <Split {...props}>{children}</Split>;
};

export default IntusSplit;
