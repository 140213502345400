import React from 'react';
import { DirectionalInput } from '@/shared/elements';
import { useAppSelector } from '@/store/hooks';
import { getShowDirectionalInput } from '@/store/slices/canvasExternalElementsSlice';
import PropertyPanel from './PropertyPanel';
import { useParams } from 'react-router';
import { getProjectUnits } from '@/store/slices/projectSlice';
import EditToolbar from '@/shared/elements/EditToolbar';
import {
  getExtrudeNode,
  getSelectedNodes,
} from '@/store/slices/canvasBuildingSlice';
import LeftPanel from './LeftPanel/LeftPanel';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes } from '@/models';
import WindowPlacement from '@/components/WindowPlacement/WindowPlacement';
import Toolbar from '@/components/Toolbar';

const CanvasExternalElements = () => {
  const { id } = useParams();

  const showDirectionalInput = useAppSelector(getShowDirectionalInput);
  const unitSystem = useAppSelector(getProjectUnits(id!));

  const selectedNodes = Object.values(useAppSelector(getSelectedNodes));
  const extrudedNode = useAppSelector(getExtrudeNode);
  const mode = useAppSelector(getCanvasMode);

  const isWindowPlacementMode = mode === CanvasActionsModes.windowPlacement;

  const showEditToolbar =
    !extrudedNode && selectedNodes.length === 1 && !isWindowPlacementMode;

  return (
    <>
      {!isWindowPlacementMode && <Toolbar />}
      {showEditToolbar && (
        <EditToolbar projectId={Number(id!)} selectedNode={selectedNodes[0]} />
      )}
      <LeftPanel />
      {showDirectionalInput && (
        <DirectionalInput size="small" userUnitType={unitSystem} />
      )}
      <PropertyPanel selectedNodes={selectedNodes} />
      {isWindowPlacementMode && selectedNodes.length === 1 && (
        <WindowPlacement selectedWall={selectedNodes[0]} />
      )}
    </>
  );
};

export default CanvasExternalElements;
