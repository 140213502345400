import React from 'react';
import { useParams } from 'react-router';
import CreateUserBuilding from '@/routes/dashboard/projects/project/UserBuilding/CreateUserBuilding';
import EditUserBuilding from '@/routes/dashboard/projects/project/UserBuilding/EditUserBuilding';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';

const UserBuilding = () => {
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data?.buildings;

  if (!userBuildings?.length) {
    return <CreateUserBuilding />;
  }

  return <EditUserBuilding userBuildings={userBuildings} />;
};

export default UserBuilding;
