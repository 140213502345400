import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const DismissIcon: React.FC<BaseIconProps> = ({ fill = '#000' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M3.08859 2.71569L3.14645 2.64645C3.32001 2.47288 3.58944 2.4536 3.78431 2.58859L3.85355 2.64645L8.5 7.293L13.1464 2.64645C13.3417 2.45118 13.6583 2.45118 13.8536 2.64645C14.0488 2.84171 14.0488 3.15829 13.8536 3.35355L9.207 8L13.8536 12.6464C14.0271 12.82 14.0464 13.0894 13.9114 13.2843L13.8536 13.3536C13.68 13.5271 13.4106 13.5464 13.2157 13.4114L13.1464 13.3536L8.5 8.707L3.85355 13.3536C3.65829 13.5488 3.34171 13.5488 3.14645 13.3536C2.95118 13.1583 2.95118 12.8417 3.14645 12.6464L7.793 8L3.14645 3.35355C2.97288 3.17999 2.9536 2.91056 3.08859 2.71569L3.14645 2.64645L3.08859 2.71569Z"
        fill={fill}
      />
    </svg>
  );
};
