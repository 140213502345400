import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  isPointInsideArea,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as THREE from 'three';
import { FlatVector3, SelectedNode } from '@/models';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { getScaleRatioForCameraZoom } from '@/shared/helpers/canvas-verifiers';
import { useThree } from '@react-three/fiber';
import { RENDER_ORDERS } from '@/shared/constants';

export interface ExtrudeHandlerData {
  defaultCenter: FlatVector3;
  extendAnchor: FlatVector3;
  wallCoordinates: FlatVector3[];
  node: SelectedNode;
  facadeWallsGuids?: string[];
}

interface ExtrudeHandlersProps {
  extrudeHandlerData: ExtrudeHandlerData;
  active?: boolean;
  clickAction?: () => void;
  shapeCoordinates?: FlatVector3[];
  position?: THREE.Vector3;
}
const HANDLER_CENTER_RADIUS = 0.005;
const HANDLER_BORDER_RADIUS = 0.003;
const EXTRUDE_DOT_MARGIN = 0.015;

const ExtrudeDotHandler: React.FC<ExtrudeHandlersProps> = ({
  extrudeHandlerData,
  active,
  clickAction,
  shapeCoordinates,
  position,
}) => {
  const { defaultCenter, extendAnchor, wallCoordinates } = extrudeHandlerData;

  const { camera, size, controls } = useThree();

  const [scale, setScale] = useState<number>(1);
  const [hovered, setHovered] = useState(false);

  const borderRef = useRef<THREE.Mesh>(null);

  const handleCameraMove = () => {
    borderRef?.current?.quaternion.copy(camera.quaternion);

    const scaleValue = getScaleRatioForCameraZoom(
      camera,
      size,
      convertFlatVector3ToVector(extrudeHandlerData.defaultCenter),
      0.058
    );

    const restrictedScale = () => {
      const cameraScale =
        camera.type === 'PerspectiveCamera' ? scaleValue / 2 : scaleValue;
      if (cameraScale < 1) return 1;
      if (cameraScale > 9) return 9;
      return cameraScale;
    };
    setScale(restrictedScale());
  };

  const getPosition = (positiveMargin?: boolean) => {
    const min = Math.min(...wallCoordinates.map((c) => c[1]));
    const bottomCoordinates = wallCoordinates.filter(
      (points) => points[1] === min
    );

    const normal = getPerpendicularVectorToVectors(
      convertFlatVector3ToVectors(bottomCoordinates),
      true
    );
    return getTranslatedVector(
      convertFlatVector3ToVector(defaultCenter),
      positiveMargin
        ? EXTRUDE_DOT_MARGIN + scale / 100
        : -EXTRUDE_DOT_MARGIN - scale / 100,
      normal
    );
  };

  const handlerPosition = useMemo(() => {
    const positionWithNegativeMargin = getPosition();

    if (shapeCoordinates) {
      const isPointInside = isPointInsideArea(
        positionWithNegativeMargin,
        shapeCoordinates.map((p) => convertFlatVector3ToVector(p))
      );

      if (isPointInside) {
        return getPosition(true);
      }
    }
    return positionWithNegativeMargin;
  }, [shapeCoordinates, defaultCenter, extendAnchor, scale]);

  const handlerCenterGeometry = useMemo(
    () => new THREE.SphereGeometry(HANDLER_CENTER_RADIUS, 32),
    []
  );

  const handlerCenterMaterial = new THREE.MeshBasicMaterial({
    color:
      active || hovered
        ? new THREE.Color('#05299E')
        : new THREE.Color('#758DDB'),
    toneMapped: false,
  });

  const borderGeometry = useMemo(
    () =>
      new THREE.CircleGeometry(
        HANDLER_CENTER_RADIUS + HANDLER_BORDER_RADIUS,
        32
      ),
    []
  );
  const borderMaterial = new THREE.MeshBasicMaterial({
    color: new THREE.Color('#fff'),
    toneMapped: false,
  });

  useEffect(() => {
    handleCameraMove();
    controls?.addEventListener('change', handleCameraMove);

    return () => {
      controls?.removeEventListener('change', handleCameraMove);
    };
  }, []);

  return (
    <mesh
      geometry={handlerCenterGeometry}
      material={handlerCenterMaterial}
      position={position ?? handlerPosition}
      renderOrder={RENDER_ORDERS.DOT_BACKGROUND}
      onPointerDown={(event) => {
        event.stopPropagation();
        clickAction && clickAction();
      }}
      onPointerMove={(event) => event.stopPropagation()}
      scale={scale}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      <mesh
        geometry={borderGeometry}
        material={borderMaterial}
        renderOrder={RENDER_ORDERS.DOT}
        ref={borderRef}
      />
    </mesh>
  );
};

export default ExtrudeDotHandler;
