import { CanvasActionsModes } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getCanvasMode,
  resetCanvasMode,
  setMode,
} from '@/store/slices/canvasModesSlice';
import { hideSelectionBoxArea } from '@/shared/helpers';
import { useEffect } from 'react';

export const useMouseMode = (mode: CanvasActionsModes) => {
  const currentMode = useAppSelector(getCanvasMode);

  const isActive = currentMode === mode;
  const dispatch = useAppDispatch();

  const onClick = () => {
    isActive ? dispatch(resetCanvasMode()) : dispatch(setMode(mode));
  };

  const handleMouseMove = () => {
    hideSelectionBoxArea();
  };
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      dispatch(resetCanvasMode());
    }
  };

  useEffect(() => {
    isActive && document.addEventListener('pointermove', handleMouseMove);
    isActive && document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('pointermove', handleMouseMove);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive]);

  return { onClick, isActive };
};
