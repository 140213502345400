import React, { BaseSyntheticEvent, ReactNode } from 'react';

interface BrowserPanelIconButtonProps {
  onClick?: (event: BaseSyntheticEvent) => void;
  icon: ReactNode;
  isApplied?: boolean;
}

const BrowserPanelIconButton: React.FC<BrowserPanelIconButtonProps> = ({
  onClick,
  icon,
  isApplied,
}) => {
  return (
    <span
      className={`cursor-pointer opacity-0 ${isApplied ? 'opacity-100' : ''} group-hover:opacity-100 transition duration-300 w-4 h-6 flex justify-center items-center`}
      onClick={onClick}
    >
      {icon}
    </span>
  );
};

export default BrowserPanelIconButton;
