import React from 'react';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { polygon, intersect, Position } from '@turf/turf';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import {
  GPSRelativePosition,
  getBBoxCenter,
} from '../../../project-canvas.helpers';
import { FlatVector2 } from '@/models';
import { BBox } from '@turf/turf';
import { GeolibGeoJSONPoint } from 'geolib/es/types';
import { getAreaUnit } from '@/shared/helpers/format-data';

interface ConstructionSiteFrameProps {
  area: string;
  siteUse: {
    siteArea: string;
    usageRate: number;
  };
  isImperialUnits: boolean;
}

const ConstructionSiteFrame = ({
  area,
  siteUse: { siteArea, usageRate },
  isImperialUnits,
}: ConstructionSiteFrameProps) => {
  const { id } = useParams();

  const siteCoordinates = useFetchProjectQuery(id!).data?.environment
    .constructionSite.points;

  const surroundingBuildings =
    useFetchProjectQuery(id!).data?.environment?.surroundingBuildings
      .surroundings || [];

  const userBuildings = useFetchProjectQuery(id!).data?.buildings || [];
  const boundingBox = useFetchProjectQuery(id!).data?.boundingBox;

  const surroundingBuildingsCanvasCoordinates = surroundingBuildings.map(
    (building) => {
      return building.coordinates?.map((point) =>
        GPSRelativePosition(
          point as GeolibGeoJSONPoint,
          getBBoxCenter(boundingBox as BBox)
        )
      );
    }
  );

  const userBuildingCanvasCoordinates = userBuildings.map((building) => {
    const coordinates: FlatVector2[] =
      building.blocks[0].storeys[0].floor.points.map((subarray) => [
        -subarray[2],
        -subarray[0],
      ]);

    return coordinates;
  });

  const constructionSiteCanvasCoordinates = siteCoordinates?.map((point) => {
    return GPSRelativePosition(
      point as GeolibGeoJSONPoint,
      getBBoxCenter(boundingBox as BBox)
    );
  });

  const getIntersectionsBuildings = (
    buildings: FlatVector2[][],
    siteCoordinates: FlatVector2[] | undefined
  ) => {
    const sitePolygon = polygon([siteCoordinates as Position[]]);
    const intersectionsBuildings = [];

    for (const building of buildings) {
      let buildingPolygon;
      try {
        buildingPolygon = polygon([building]);
      } catch (e) {
        console.warn('First and last Position are not equivalent');
        building.push(building[0]);
        buildingPolygon = polygon([building as Position[]]);
      }

      const intersection = intersect(sitePolygon, buildingPolygon);

      if (intersection) {
        intersectionsBuildings.push(building);
      }
    }

    return intersectionsBuildings;
  };

  const surroundingCount = getIntersectionsBuildings(
    surroundingBuildingsCanvasCoordinates,
    constructionSiteCanvasCoordinates
  ).length;

  const buildingCount = getIntersectionsBuildings(
    userBuildingCanvasCoordinates,
    constructionSiteCanvasCoordinates
  ).length;

  return (
    <>
      <div className="flex px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden">
          Site
        </span>
      </div>
      <div
        className={
          'text-xs font-light border border-l-0 border-solid border-light-gray-20 !bg-white overflow-y-auto'
        }
      >
        <PropertyList
          properties={[
            {
              name: 'Site area',
              value: `${area}`,
              units: getAreaUnit(isImperialUnits),
            },
            {
              name: 'Area used',
              value: `${siteArea}`,
              units: getAreaUnit(isImperialUnits),
            },
            {
              name: 'Percentage of area used',
              value: `${usageRate}`,
              units: '%',
            },
          ]}
        />
      </div>
      <div
        className={
          'text-xs font-light border border-l-0 border-t-0 border-solid border-light-gray-20 flex-1 h-[calc(100%_-_2rem)] !bg-white overflow-y-auto'
        }
      >
        <div className="font-medium uppercase px-3 pt-3">Buildings count</div>
        <PropertyList
          properties={[
            {
              name: 'Constructed',
              value: `${buildingCount}`,
            },
            {
              name: 'Surrounding',
              value: `${surroundingCount}`,
            },
          ]}
        />
      </div>
    </>
  );
};

export default ConstructionSiteFrame;
