import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Html } from 'react-konva-utils';
import {
  WindowCreatorFormData,
  WindowElementType,
  WindowNestedMenu,
  View,
} from '../../models/konva-model';
import {
  getColorDescriptionByName,
  getHexByName,
  getOperationTypeIcon,
} from '../../helpers/config';
import { IntusIconButton } from '@/shared/elements';
import ArrowLeftMenuIcon from '@/shared/icons/ArrowLeftMenuIcon';
import { useClickAway } from 'react-use';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { Form, FormInstance } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getSelectedWindowNodePoints,
  getSelectedWindowNodeType,
  resetSelectedArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { isEqual } from 'lodash';
import { STAGE_HEIGHT, STAGE_WIDTH } from '../../constants';
import { publish } from '@/core/events';
import { SET_WINDOW_CONTEXT_MENU_POSITION } from '@/core/event-names';
import { OperationType } from '@/models/window-configurator.model';
import {
  handleChangeWindowType,
  swapDiagonalCorners,
} from '@/components/WindowCreator/helpers';
import { mirrorPointAcrossX } from '../../helpers/direction.helpers';
import { get2DCenter } from '@/shared/helpers/konva';

interface WindowMenuProps {
  menuPosition: { x: number; y: number };
  setMenuPosition: Dispatch<SetStateAction<{ x: number; y: number } | null>>;
  scale: number;
  windowData: WindowCreatorFormData;
  form: FormInstance<WindowCreatorFormData>;
}

const WindowMenu: React.FC<WindowMenuProps> = ({
  menuPosition,
  setMenuPosition,
  scale,
  windowData,
  form,
}) => {
  const dispatch = useAppDispatch();
  const configData = useFetchWindowConfigQuery().data!;
  const [positionIsAdjusted, setPositionIsAdjusted] = useState(false);

  useEffect(() => {
    adjustMenuPosition();
    setPositionIsAdjusted(true);
  }, []);

  const selectedNodeType = useAppSelector(getSelectedWindowNodeType);
  const selectedNodePoints = useAppSelector(getSelectedWindowNodePoints);

  const innerFrameData = windowData.innerWindows.find((innerWindow) => {
    const frameCenter = get2DCenter(
      windowData.points[0],
      windowData.points[1]
    )[0];
    const adjustedInnerWindowPoints =
      windowData.view === View.Outside
        ? innerWindow.points
        : swapDiagonalCorners(
            innerWindow.points.map((point) =>
              mirrorPointAcrossX(point, frameCenter)
            )
          );
    return isEqual(selectedNodePoints, adjustedInnerWindowPoints);
  });

  const isFrameWithoutMullionsSelected =
    windowData?.mullions.length === 0 &&
    selectedNodeType === WindowElementType.Frame;

  const ref = useRef<HTMLDivElement>(null!);
  const nestedMenuRef = useRef<HTMLDivElement>(null!);
  useClickAway(ref, () => setMenuPosition(null));
  useClickAway(nestedMenuRef, () => {
    setActiveNestedMenu(null);
  });
  const [activeNestedMenu, setActiveNestedMenu] =
    useState<WindowNestedMenu | null>(null);

  const handleKeyup = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setMenuPosition(null);
      publish(SET_WINDOW_CONTEXT_MENU_POSITION, null);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyup);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [menuPosition]);

  const handleChangeOperationType = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: OperationType
  ) => {
    handleChangeWindowType(innerFrameData?.points ?? [], type, form);
    handleResetMenu(event);
    dispatch(resetSelectedArea());
    setMenuPosition(null);
  };

  const handleResetMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveNestedMenu(null);
  };

  const handleChangeColor = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    color: string
  ) => {
    e.stopPropagation();
    form.setFieldValue(
      activeNestedMenu === WindowNestedMenu.InnerColor
        ? WindowNestedMenu.InnerColor
        : WindowNestedMenu.OuterColor,
      color
    );
    setActiveNestedMenu(null);
  };

  const adjustMenuPosition = () => {
    if (!ref.current) return;

    const modalMargin = 20;
    const totalMenuWidth = ref.current.offsetWidth + 220 + modalMargin;

    let newXPosition = menuPosition.x;
    let newYPosition = menuPosition.y;

    if (newXPosition + totalMenuWidth > STAGE_WIDTH) {
      newXPosition = STAGE_WIDTH - totalMenuWidth;
    }

    let maxAllowedHeight;

    if (selectedNodeType === WindowElementType.Frame) {
      maxAllowedHeight = isFrameWithoutMullionsSelected ? 392 : 308;
    } else {
      maxAllowedHeight = 344;
    }
    maxAllowedHeight += modalMargin;

    if (newYPosition + maxAllowedHeight > STAGE_HEIGHT) {
      newYPosition = STAGE_HEIGHT - maxAllowedHeight;
    }

    setMenuPosition({ x: newXPosition, y: newYPosition });
  };

  const getColorsNestedMenu = (
    type: WindowNestedMenu.OuterColor | WindowNestedMenu.InnerColor
  ) => (
    <div
      ref={nestedMenuRef}
      className="absolute top-0 pt-1 ml-2 w-[220px] left-full bg-white context-menu__wrapper font-normal box-border"
    >
      {configData.colors
        .filter((color) =>
          type === WindowNestedMenu.OuterColor
            ? color.name !== windowData.outerColor
            : color.name !== windowData.innerColor
        )
        .map((color) => {
          return (
            <div
              className={`h-8 px-2 py-1 flex gap-2 mb-1 box-border hover:bg-light-gray-10`}
              key={color.name}
              onClick={(e) => {
                handleChangeColor(e, color.name);
              }}
            >
              <div
                className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                style={{
                  backgroundColor: getHexByName(configData.colors, color.name),
                }}
              />
              <div className="flex items-center leading-5 font-normal text-xs">
                {getColorDescriptionByName(configData.colors, color.name)}
              </div>
            </div>
          );
        })}
    </div>
  );

  const operationTypeMenu = (
    <div className="py-1">
      <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
        Operation type
      </div>
      <div
        className={`flex cursor-pointer hover:bg-light-gray-10 relative ${activeNestedMenu === WindowNestedMenu.OperationType ? 'bg-light-gray-10' : 'bg-white'}`}
        onClick={() => setActiveNestedMenu(WindowNestedMenu.OperationType)}
      >
        <div className="px-2 py-1 flex gap-2 min-w-[202px] box-border">
          {innerFrameData &&
            getOperationTypeIcon(innerFrameData?.operationType)}
          <div className="flex items-center text-xs font-normal leading-5">
            {
              configData.operationTypes.find(
                (operationType) =>
                  operationType.name === innerFrameData?.operationType
              )?.description
            }
          </div>
        </div>
        <div className="flex items-center">
          <IntusIconButton
            simplified
            size="small"
            transparent
            icon={<ArrowLeftMenuIcon />}
          />
        </div>

        {activeNestedMenu === WindowNestedMenu.OperationType && (
          <Form.Item name={WindowNestedMenu.OperationType}>
            <div
              ref={nestedMenuRef}
              className="absolute top-0 pt-1 ml-2 w-[220px] h-[316px] left-full bg-white context-menu__wrapper font-normal box-border"
            >
              {configData.operationTypes
                .filter(
                  (operationType) =>
                    operationType.name !== innerFrameData?.operationType
                )
                .map((operationType) => {
                  return (
                    <div
                      className={`h-12 px-2 py-1 flex gap-2 mb-1 box-border hover:bg-light-gray-10`}
                      key={operationType.name}
                      onClick={(e) =>
                        handleChangeOperationType(e, operationType.name)
                      }
                    >
                      {getOperationTypeIcon(operationType.name)}
                      <div className="flex justify-center flex-col flex-1">
                        <div className="font-normal leading-5 text-xs">
                          {operationType.description}
                        </div>
                        <div className="font-light leading-5 text-xs">
                          {operationType.swing}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Form.Item>
        )}
      </div>
    </div>
  );

  const outerFrameMenu = (
    <div className={isFrameWithoutMullionsSelected ? 'pb-1' : 'py-1'}>
      <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
        Outside color
      </div>
      <div
        onClick={() => setActiveNestedMenu(WindowNestedMenu.OuterColor)}
        className={`flex cursor-pointer hover:bg-light-gray-10 relative ${activeNestedMenu === WindowNestedMenu.OuterColor ? 'bg-light-gray-10' : 'bg-white'} mb-1`}
      >
        <div className="h-8 box-border py-1 px-2 flex gap-2 min-w-[202px]">
          <div
            className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
            style={{
              backgroundColor: getHexByName(
                configData.colors,
                windowData.outerColor
              ),
            }}
          />
          <div className="flex items-center text-xs font-normal leading-5">
            {getColorDescriptionByName(
              configData.colors,
              windowData.outerColor
            )}
          </div>
        </div>
        <div className="flex items-center">
          <IntusIconButton
            simplified
            size="small"
            transparent
            icon={<ArrowLeftMenuIcon />}
          />
        </div>
        {activeNestedMenu === WindowNestedMenu.OuterColor && (
          <Form.Item name="outerColor">
            {getColorsNestedMenu(WindowNestedMenu.OuterColor)}
          </Form.Item>
        )}
      </div>
      <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
        Inside color
      </div>
      <div
        onClick={() => setActiveNestedMenu(WindowNestedMenu.InnerColor)}
        className={`flex cursor-pointer hover:bg-light-gray-10 relative ${activeNestedMenu === WindowNestedMenu.InnerColor ? 'bg-light-gray-10' : 'bg-white'} mb-1`}
      >
        <div className="h-8 box-border py-1 px-2 flex gap-2 min-w-[202px]">
          <div
            className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
            style={{
              backgroundColor: getHexByName(
                configData.colors,
                windowData.innerColor
              ),
            }}
          />
          <div className="flex items-center text-xs font-normal leading-5">
            {getColorDescriptionByName(
              configData.colors,
              windowData.innerColor
            )}
          </div>
        </div>
        <div className="flex items-center">
          <IntusIconButton
            simplified
            size="small"
            transparent
            icon={<ArrowLeftMenuIcon />}
          />
        </div>
        {activeNestedMenu === WindowNestedMenu.InnerColor && (
          <Form.Item name={WindowNestedMenu.InnerColor}>
            {getColorsNestedMenu(WindowNestedMenu.InnerColor)}
          </Form.Item>
        )}
      </div>
      <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
        Glazing type
      </div>
      <div className="text-xs font-normal leading-5 h-8 flex items-center px-2 py-1 box-border">
        Double
      </div>
    </div>
  );

  return (
    <Html
      divProps={{
        style: {
          position: 'absolute',
          left: `${menuPosition.x + 5}px`,
          top: `${menuPosition.y + 5}px`,
          scale: 1 / scale,
          transform: `translate(0px, 0px) rotate(0deg) scaleX(${scale}) scaleY(${scale})`,
        },
      }}
    >
      <div
        ref={ref}
        className={`w-[230px] bg-white context-menu__wrapper font-normal text-xs ${positionIsAdjusted ? 'opacity-100' : 'opacity-0'}`}
      >
        {innerFrameData && operationTypeMenu}
        {selectedNodeType === WindowElementType.Frame && outerFrameMenu}
      </div>
    </Html>
  );
};

export default WindowMenu;
