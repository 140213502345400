import React from 'react';

const CubeAreaIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.6407C10.9416 17.6581 10.8828 17.674 10.8238 17.6883C10.5533 17.7538 10.2769 17.7865 10.0005 17.7865L10 8.49904L14.957 6.31459L17.6654 5.25002C17.7947 5.40955 17.8908 5.59498 17.9461 5.79513C17.9728 5.89146 17.9899 5.9912 17.9968 6.0931C17.9991 6.12615 18.0002 6.15943 18.0002 6.19289L18.0002 13.8406C18.0002 14.454 17.6268 15.0055 17.0573 15.2333L16.6859 14.3048C16.8758 14.2289 17.0002 14.0451 17.0002 13.8406L17.0002 12.0196L15.5913 14.7427L16.6859 14.3048L17.0573 15.2333L11.3001 17.5362C11.2013 17.5757 11.1012 17.6106 11 17.6407ZM12.7143 15.8935L14.1715 15.3106L17 9.84366L17.0001 7.62111L12.7143 15.8935ZM16.2593 6.8772L15.3417 7.23786L14.5715 7.57729L11 14.5618V16.5792L11.2937 16.4618L16.2593 6.8772ZM11 12.3653L13.1216 8.21621L11 9.15116V12.3653Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2999 2.4808C10.4654 2.14702 9.53457 2.14702 8.70013 2.4808L2.94291 4.78369C2.37343 5.01148 2 5.56305 2 6.1764V13.8223C2 14.4357 2.37343 14.9873 2.94291 15.2151L8.70013 17.5179C9.53457 17.8517 10.4654 17.8517 11.2999 17.5179L17.0571 15.2151C17.6266 14.9873 18 14.4357 18 13.8223V6.1764C18 5.56305 17.6266 5.01148 17.0571 4.78369L11.2999 2.4808ZM9.07152 3.40928C9.66755 3.17087 10.3324 3.17087 10.9285 3.40928L16.6857 5.71216C16.8755 5.7881 17 5.97195 17 6.1764V13.8223C17 14.0268 16.8755 14.2106 16.6857 14.2866L10.9285 16.5895C10.3324 16.8279 9.66755 16.8279 9.07152 16.5895L3.3143 14.2866C3.12448 14.2106 3 14.0268 3 13.8223V6.1764C3 5.97195 3.12448 5.7881 3.31431 5.71216L9.07152 3.40928Z"
        fill="#414042"
      />
      <path
        d="M5.70295 6.04249C5.45061 5.93033 5.15513 6.04398 5.04298 6.29632C4.93082 6.54866 5.04447 6.84414 5.29681 6.9563L9.49975 8.82433L9.99975 8.99902L10.6759 8.19867L9.99976 7.95223L5.70295 6.04249Z"
        fill="#414042"
      />
    </svg>
  );
};

export default CubeAreaIcon;
