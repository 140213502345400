import { GeolibGeoJSONPoint } from 'geolib/es/types';
import * as THREE from 'three';
import { getCenter, getDistance, getRhumbLineBearing } from 'geolib';
import {
  BBox,
  booleanPointInPolygon,
  polygon,
  Position,
  point,
  angle,
} from '@turf/turf';
import { BufferAttribute, BufferGeometry, Shape } from 'three';
import { FlatVector2, FlatVector3 } from '@/models';
import { Earcut } from 'three/src/extras/Earcut';
import { LineGeometry } from 'three-stdlib';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { FLOOR_HEIGHT } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

export const CANVAS_PROJECT_ZOOM = 75;
export const CANVAS_PROJECT_MIN_ZOOM = 50;
export const CANVAS_PROJECT_MAX_ZOOM = 8000;
export const FLOOR_HEIGHT_IN_MILIMETERS = 3000;

interface Coordinates {
  x: number;
  z: number;
}

interface ExtendedCoordinates extends Coordinates {
  y: number;
}
interface MinMaxCoordinatesPairs {
  min: Coordinates;
  max: Coordinates;
}

export interface ExtendedMinMaxCoordinatesPairs extends MinMaxCoordinatesPairs {
  min: ExtendedCoordinates;
  max: ExtendedCoordinates;
}

export const convertBoundingBoxToCanvasCoordinates = (
  boundingBox: BBox
): MinMaxCoordinatesPairs => {
  const center: GeolibGeoJSONPoint = getBBoxCenter(boundingBox);
  const shapeLikeCoordinates: FlatVector2[] =
    getShapeLikeObjectFromBbox(boundingBox);
  const canvasMapCoordinates = genCanvasCoordinatesBasedOnShape(
    shapeLikeCoordinates,
    center
  );
  return getMinMaxValuesFromCoordinates(canvasMapCoordinates);
};

export const genCanvasCoordinatesBasedOnShape = (
  points: FlatVector2[],
  mapCenter: GeolibGeoJSONPoint
): FlatVector2[] =>
  points.map((point) => GPSRelativePosition(point, mapCenter));

export const GPSRelativePosition = (
  object: GeolibGeoJSONPoint,
  mapCenter: GeolibGeoJSONPoint
): FlatVector2 => {
  const dis = getDistance(object, mapCenter);
  const bearing = getRhumbLineBearing(object, mapCenter);
  const x = Number(mapCenter[0]) + dis * Math.cos((bearing * Math.PI) / 180);
  const y = Number(mapCenter[1]) + dis * Math.sin((bearing * Math.PI) / 180);
  return [-x / 100, y / 100];
};

export const getMinMaxValuesFromCoordinates = (
  coordinates: FlatVector2[]
): MinMaxCoordinatesPairs =>
  coordinates.reduce(
    (acc, cur) => {
      acc.min.x = acc.min.x < cur[0] ? acc.min.x : cur[0];
      acc.max.x = acc.max.x > cur[0] ? acc.max.x : cur[0];
      acc.min.z = acc.min.z < cur[1] ? acc.min.z : cur[1];
      acc.max.z = acc.max.z > cur[1] ? acc.max.z : cur[1];
      return acc;
    },
    {
      min: { x: +Infinity, z: +Infinity },
      max: { x: -Infinity, z: -Infinity },
    }
  );
export const getBBoxCenter = (bbox: BBox): GeolibGeoJSONPoint => {
  const geolibCenter = getCenter([
    { lat: bbox[1], lng: bbox[0] },
    { lat: bbox[3], lng: bbox[2] },
  ]) as {
    longitude: number;
    latitude: number;
  };

  return [geolibCenter.longitude, geolibCenter.latitude];
};

// Generates list of coordinate points from Boundary Box. So far alternatives haven't been found.
// feel your self free to update this method if you will have better ideas
export const getShapeLikeObjectFromBbox = (
  coordinates: BBox
): FlatVector2[] => {
  const minX = Math.min(coordinates[0], coordinates[2]);
  const maxX = Math.max(coordinates[0], coordinates[2]);
  const minY = Math.min(coordinates[1], coordinates[3]);
  const maxY = Math.max(coordinates[1], coordinates[3]);
  return [
    [minX, minY],
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
  ];
};

export const getBuildingMaterials = (texture: THREE.Texture) => [
  new THREE.MeshBasicMaterial({ color: '#C4C4C4', toneMapped: false }),
  new THREE.MeshBasicMaterial({ color: '#C4C4C4', toneMapped: false }),
  new THREE.MeshBasicMaterial({
    color: 'white',
    toneMapped: false,
    map: texture,
    opacity: 1,
  }),
  new THREE.MeshBasicMaterial({ color: '#C4C4C4', toneMapped: false }),
  new THREE.MeshBasicMaterial({ color: '#C4C4C4', toneMapped: false }),
  new THREE.MeshBasicMaterial({ color: '#C4C4C4', toneMapped: false }),
];

export function approximatelyEqual(a: number, b: number) {
  return Math.abs(a - b) < 0.05;
}

export const generateBuildingShape = (
  coordinate: GeolibGeoJSONPoint[],
  mapCenter: GeolibGeoJSONPoint
) => {
  const shape = new THREE.Shape();

  coordinate.forEach((point, idx) => {
    const canvasCoordinate = GPSRelativePosition(point, mapCenter);
    idx === 0
      ? shape.moveTo(canvasCoordinate[0], canvasCoordinate[1])
      : shape.lineTo(canvasCoordinate[0], canvasCoordinate[1]);
  });
  return shape;
};

export const generateBuildingGeometry = (
  shape: Shape,
  buildingLevels: number
) => {
  const geometry = new THREE.ExtrudeGeometry(shape, {
    curveSegments: 1,
    depth: FLOOR_HEIGHT * buildingLevels,
    bevelEnabled: false,
  });
  geometry.rotateX(Math.PI / 2);
  geometry.rotateY(Math.PI / 2);
  geometry.rotateZ(Math.PI);
  geometry.computeBoundingBox();
  return geometry;
};

export const convert3DVectorsTo2D = (
  vectors: THREE.Vector3[],
  coordinates: ['x' | 'y' | 'z', 'x' | 'y' | 'z']
): THREE.Vector2[] =>
  vectors.map((v) => new THREE.Vector2(v[coordinates[0]], v[coordinates[1]]));

// Works only with limited geometry.drawRange!
export const convertBufferGeometryTo3DVectorList = (
  geometry: BufferGeometry,
  count?: number
): THREE.Vector3[] => {
  const vectors: THREE.Vector3[] = [];
  const c = count || geometry.drawRange.count;
  for (let i = 0; i < c; i++) {
    vectors.push(
      new THREE.Vector3().fromBufferAttribute(
        geometry.getAttribute('position') as BufferAttribute,
        i
      )
    );
  }
  return vectors;
};

export const getCanvasObjectByUUID = (scene: THREE.Scene, uuid: string) =>
  scene.getObjectsByProperty('uuid', uuid)[0] || {};

export const getXYZ = (vector: THREE.Vector3): FlatVector3 => [
  vector.x,
  vector.y,
  vector.z,
];

export const getGeometryPointByIdx = (
  idx: number,
  geometry: BufferGeometry
): FlatVector3 => [
  geometry.getAttribute('position').array[idx * 3],
  geometry.getAttribute('position').array[idx * 3 + 1],
  geometry.getAttribute('position').array[idx * 3 + 2],
];

export const getGeometryVectorPointByIdx = (
  idx: number,
  geometry: BufferGeometry
) => new THREE.Vector3(...getGeometryPointByIdx(idx, geometry));

export const setGeometryPointPositionByIdx = (
  idx: number,
  geometry: BufferGeometry,
  position: FlatVector3
) => {
  geometry.getAttribute('position').setXYZ(idx, ...position);
  geometry.getAttribute('position').needsUpdate = true;
};

export const setObjectPosition = (
  object: THREE.Mesh,
  position: FlatVector3
) => {
  object.position.set(...position);
  object.geometry.computeBoundingBox();
  object.geometry.getAttribute('position').needsUpdate = true;
};

export const setMeshMaterialOpacity = (
  material: THREE.Material | THREE.Material[],
  value: number
) => {
  Array.isArray(material)
    ? material.forEach((m) => (m.opacity = value))
    : (material.opacity = value);
};

export const getExtendedVector = (
  vectorA: THREE.Vector3,
  vectorB: THREE.Vector3,
  distance: number
): THREE.Vector3 => {
  try {
    if (isNaN(distance)) {
      return vectorA;
    }
    const dir = new THREE.Vector3().subVectors(vectorB, vectorA).normalize();
    return vectorA.clone().addScaledVector(dir, distance);
  } catch (e) {
    return vectorA;
  }
};

export const getPerpendicularVectorToVectors = (
  vectors: THREE.Vector3[],
  isNegative: boolean
) => {
  const sub = isNegative ? [vectors[0], vectors[1]] : [vectors[1], vectors[0]];
  return new THREE.Vector3()
    .subVectors(sub[0], sub[1])
    .applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI * 0.5)
    .normalize();
};

export const getDistanceBetweenInfiniteLineAndVector = (
  line: THREE.Line3,
  point: THREE.Vector3
) => {
  const newVector = new THREE.Vector3();
  line.closestPointToPoint(point, false, newVector);
  return newVector.distanceTo(point);
};

export const getExistingCoordinates = (mesh: THREE.Mesh) => [
  ...convertBufferGeometryTo3DVectorList(
    mesh.geometry,
    mesh.geometry.getAttribute('position').count
  ),
];

export const getTranslatedVector = (
  vector: THREE.Vector3,
  offset: number,
  direction: THREE.Vector3
): THREE.Vector3 => {
  direction.normalize();
  return new THREE.Vector3(
    vector.x + offset * direction.x,
    vector.y + offset * direction.y,
    vector.z + offset * direction.z
  );
};

export const isVectorLeftSide = (
  a: THREE.Vector3,
  b: THREE.Vector3,
  c: THREE.Vector3
) => (b.x - a.x) * (c.z - a.z) > (b.z - a.z) * (c.x - a.x);

export const generateLineGeometry = (
  startingPoint: FlatVector3,
  endingPoint: FlatVector3
): THREE.BufferGeometry => {
  const geometry = new THREE.BufferGeometry();
  const positions = new Float32Array(6); // 3 vertices per point
  geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

  const geometryPosition = geometry.getAttribute('position');
  geometryPosition.setXYZ(0, ...startingPoint);
  geometryPosition.setXYZ(1, ...endingPoint);
  geometry.setDrawRange(0, 2);
  return geometry;
};

export const triangulateGeometryAndUpdate = (
  geometry: BufferGeometry,
  vectors: THREE.Vector3[],
  direction?: 'horizontal' | 'vertical'
) => {
  const triangulate = Earcut.triangulate(
    convert3DVectorsTo2D(
      vectors,
      direction === 'vertical' ? ['x', 'y'] : ['x', 'z']
    )
      .map((point) => [point.x, point.y])
      .flat()
  );
  geometry.setIndex(triangulate);
  geometry.computeVertexNormals();
  geometry.computeBoundingBox();
};

export const createLine2 = (
  points: number[] | Float32Array,
  material: LineMaterial
) => {
  const geometry = new LineGeometry().setPositions(points);
  const line = new Line2(geometry, material.clone());
  line.computeLineDistances();
  return line;
};

export const updateLine2Position = (
  line: Line2,
  newPoints: number[] | Float32Array
) => {
  line.geometry.setPositions(newPoints);
  line.computeLineDistances();
};

export const getCenterFrom2DFlatVectorsArray = (
  vectors: FlatVector2[]
): THREE.Vector2 => {
  const centroid = new THREE.Vector2();
  vectors.forEach((vector) =>
    centroid.add(new THREE.Vector2(vector[0], vector[1]))
  );

  centroid.divideScalar(vectors.length);
  return centroid;
};

export const getCenterFromFlatVectorsArray = (
  vectors: FlatVector3[]
): THREE.Vector3 => {
  const centroid = new THREE.Vector3();
  vectors.forEach((vector) =>
    centroid.add(new THREE.Vector3(vector[0], vector[1], vector[2]))
  );

  centroid.divideScalar(vectors.length);
  return centroid;
};

export const getCenterFromVectorsArray = (
  vectors: THREE.Vector3[]
): THREE.Vector3 => {
  const centroid = new THREE.Vector3();
  vectors.forEach((vector) => centroid.add(vector));

  centroid.divideScalar(vectors.length);
  return centroid;
};

export const isPointInsideArea = (
  centerPoint: THREE.Vector3,
  vectors: THREE.Vector3[]
) => {
  const pt = point([centerPoint.x, centerPoint.z]);
  const poly = polygon([
    vectors.map((vector) => [vector.x, vector.z]) as Position[],
  ]);
  return booleanPointInPolygon(pt, poly);
};

export const getMinMaxCoordinatesAtVector3 = (
  coordinates: THREE.Vector3[]
): ExtendedMinMaxCoordinatesPairs => {
  const box = new THREE.Box3().setFromPoints(coordinates);
  return {
    min: {
      x: box.min.x,
      y: box.min.y,
      z: box.min.z,
    },
    max: {
      x: box.max.x,
      y: box.max.y,
      z: box.max.z,
    },
  };
};

export const checkLineIntersection = (
  line1Start: THREE.Vector3,
  line1End: THREE.Vector3,
  line2Start: THREE.Vector3,
  line2End: THREE.Vector3
) => {
  const result: {
    x: null | number;
    y: null | number;
    onLine1: boolean;
    onLine2: boolean;
  } = {
    x: null,
    y: null,
    onLine1: false,
    onLine2: false,
  };
  const denominator =
    (line2End.z - line2Start.z) * (line1End.x - line1Start.x) -
    (line2End.x - line2Start.x) * (line1End.z - line1Start.z);
  if (denominator == 0) {
    return result;
  }
  let a = line1Start.z - line2Start.z;
  let b = line1Start.x - line2Start.x;
  const numerator1 =
    (line2End.x - line2Start.x) * a - (line2End.z - line2Start.z) * b;
  const numerator2 =
    (line1End.x - line1Start.x) * a - (line1End.z - line1Start.z) * b;
  a = numerator1 / denominator;
  b = numerator2 / denominator;
  result.x = line1Start.x + a * (line1End.x - line1Start.x);
  result.y = line1Start.z + a * (line1End.z - line1Start.z);
  if (a > 0 && a < 1) {
    result.onLine1 = true;
  }
  if (b > 0 && b < 1) {
    result.onLine2 = true;
  }
  return result;
};

export const isPointsInOneLine = (
  startPoint: FlatVector2,
  middlePoint: FlatVector2,
  endPoint: FlatVector2
) => {
  const angleResult = angle(startPoint, middlePoint, endPoint);
  return angleResult < 181 && angleResult > 179;
};
