import React from 'react';

const ZeroStateFrame = () => {
  return (
    <>
      <div className="flex px-3 bg-white font-medium text-xs min-h-8 border border-t-0 border-x-0 border-solid border-light-gray-20 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden">
          Properties
        </span>
      </div>
    </>
  );
};

export default ZeroStateFrame;
