import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { C_MeshBaseColorMaterial } from '@/shared/materials';
import { useThree } from '@react-three/fiber';
import { getScaleRatioForCameraZoom } from '@/shared/helpers/canvas-verifiers';
import { RENDER_ORDERS } from '@/shared/constants';

interface ScalableDotProps {
  dotPosition: THREE.Vector3;
}

const CENTER_RADIUS = 0.01;
const BORDER_RADIUS = 0.003;

const ScalableDot: React.FC<ScalableDotProps> = ({ dotPosition }) => {
  const { camera, controls, size } = useThree();

  const [scale, setScale] = useState(1);

  const dotGeometry = useMemo(
    () => new THREE.SphereGeometry(CENTER_RADIUS, 32),
    [dotPosition]
  );

  const dotMaterial = useMemo(() => {
    const material = C_MeshBaseColorMaterial.clone();
    material.depthTest = false;
    return material;
  }, []);

  const borderGeometry = useMemo(
    () => new THREE.SphereGeometry(BORDER_RADIUS + CENTER_RADIUS, 32),
    []
  );

  const borderMaterial = useMemo(() => {
    const material = C_MeshBaseColorMaterial.clone();
    material.color = new THREE.Color('#fff');
    material.depthTest = false;
    return material;
  }, []);

  const position = useMemo(
    () => new THREE.Vector3(dotPosition.x, dotPosition.y, dotPosition.z),
    [dotPosition]
  );

  const calculateScale = useCallback(() => {
    const scaleValue = getScaleRatioForCameraZoom(
      camera,
      size,
      dotPosition,
      0.06
    );

    const getRestrictedScaleValue = () => {
      if (scaleValue > 10) return 10;
      if (scaleValue < 1) return 1;
      return scaleValue;
    };

    setScale(getRestrictedScaleValue());
  }, [dotPosition, camera, size]);

  useEffect(() => {
    calculateScale();
    controls?.addEventListener('change', calculateScale);
    return () => {
      controls?.removeEventListener('change', calculateScale);
    };
  }, [controls, camera, dotPosition]);

  return (
    <mesh
      geometry={dotGeometry}
      material={dotMaterial}
      position={position}
      scale={scale}
      renderOrder={RENDER_ORDERS.DOT_BACKGROUND}
    >
      <mesh
        geometry={borderGeometry}
        material={borderMaterial}
        renderOrder={RENDER_ORDERS.DOT}
      />
    </mesh>
  );
};

export default ScalableDot;
