import React, { useEffect, useMemo, useState } from 'react';
import { Group, Layer, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

import WallView from '@/components/WindowPlacement/elements/WallView';
import {
  limitValue,
  removeSpacesFromThousands,
} from '@/shared/helpers/format-data';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import { SelectedNode } from '@/models';
import WindowView from '@/components/WindowView/WindowView';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  addPlacedWindow,
  getPlacedWindows,
  getSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/windowPlacementSlice';
import { useParams } from 'react-router';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { get2DDistance } from '@/shared/helpers/konva';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { WindowOffset } from '@/components/WindowCreator/models';

const WindowPlacementContainer = ({
  placementHeight,
  placementWidth,
  selectedWall,
}: {
  placementHeight: number;
  placementWidth: number;
  selectedWall: SelectedNode;
}) => {
  Konva.dragButtons = [2];

  const { id } = useParams();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const dispatch = useAppDispatch();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const placedWindows = useAppSelector(getPlacedWindows);
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );

  const isPlacementProcessing = !!selectedWindowFromLibrary;

  const { getNodeData } = useFindNodeData();
  const { getFloorHeightMetric, getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });
  const { wheelHandler } = useKonvaHandlers({ maxZoom: 1, minZoom: 0.01 });

  const [placedWindowPosition, setPlacedWindowPosition] =
    useState<WindowOffset>({
      offsetX: 0,
      offsetY: 0,
    });

  const wallData = getNodeData({
    guid: selectedWall.guid,
    nodeType: selectedWall.type,
  });

  const wallWidth = useMemo(
    () =>
      Number(
        removeSpacesFromThousands(
          getWallWidthMetric([wallData as WallSearchResults]),
          false
        )
      ),
    [wallData]
  );

  const wallHeight = useMemo(
    () => Number(removeSpacesFromThousands(getFloorHeightMetric(), false)),
    [wallData]
  );

  const scaleX = (placementWidth * 0.9) / wallWidth;
  const scaleY = (placementHeight - 100) / wallHeight;
  const scale = Math.min(scaleX, scaleY);

  const [stageParams, setStageParams] = useState({
    scale,
    x: placementWidth / 2 - (wallWidth * scale) / 2,
    y: placementHeight / 2 - (wallHeight * scale) / 2,
  });

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    const scaleData = wheelHandler(event);

    scaleData && setStageParams(scaleData);
  };

  useEffect(() => {
    selectedWindowFromLibrary &&
      setPlacedWindowPosition({
        offsetX: 0,
        offsetY: wallHeight - selectedWindowFromLibrary.distanceToFloor,
      });
  }, [selectedWindowFromLibrary]);

  const calculateWindowPosition = (event?: KonvaEventObject<MouseEvent>) => {
    if (!isPlacementProcessing) return { x: 0, y: 0 };

    const offsetFromBottom = selectedWindowFromLibrary.distanceToFloor;
    const stage = event?.target.getStage();

    const minXPosition = 0;
    const maxXPosition =
      wallWidth -
      get2DDistance(
        selectedWindowFromLibrary.points[0],
        selectedWindowFromLibrary.points[1]
      );

    const position: WindowOffset = {
      offsetX: limitValue(
        stage?.getRelativePointerPosition()?.x ?? 0,
        minXPosition,
        maxXPosition
      ),
      offsetY: wallHeight - offsetFromBottom,
    };

    setPlacedWindowPosition(position);
    return position;
  };

  const handleClick = () => {
    if (isPlacementProcessing) {
      dispatch(
        addPlacedWindow({
          id: selectedWindowFromLibrary.id,
          attachPosition: placedWindowPosition,
        })
      );
    }
  };

  const showAddedWindows = () =>
    placedWindows.map((window, idx) => {
      const windowToDisplay = windowsData.find((_w) => _w.id === window.id)!;
      return (
        <WindowView
          key={`window-placement__${window.id}__${idx}`}
          data={windowToDisplay}
          scale={stageParams.scale}
          windowPosition={window.attachPosition}
          units={unitSystem}
        />
      );
    });

  return (
    <Stage
      onContextMenu={(e) => e.evt.preventDefault()}
      width={placementWidth}
      height={placementHeight}
      scaleX={stageParams.scale}
      scaleY={stageParams.scale}
      x={stageParams.x}
      y={stageParams.y}
      draggable
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
      onClick={handleClick}
      onPointerMove={calculateWindowPosition}
    >
      <Layer>
        {wallData && (
          <WallView
            wallWidth={wallWidth}
            wallHeight={wallHeight}
            scale={stageParams.scale}
            initialScale={scale}
          />
        )}
        {selectedWindowFromLibrary && (
          <Group opacity={0.75}>
            <WindowView
              data={selectedWindowFromLibrary}
              scale={stageParams.scale}
              units={unitSystem}
              windowPosition={placedWindowPosition}
              viewOnly
            />
          </Group>
        )}
        {showAddedWindows()}
      </Layer>
    </Stage>
  );
};

export default WindowPlacementContainer;
