import React from 'react';
import './TreeLoader.scss';
import Tree from './Tree';
import TreeText from './TreeText';

interface TreeLoaderProps {
  isMinimal?: boolean;
}

const TreeLoader: React.FC<TreeLoaderProps> = ({ isMinimal = true }) => {
  return (
    <div
      className={`loading-container ${isMinimal ? 'minimal with-blur' : ''}  ${
        isMinimal && '!absolute'
      }`}
    >
      <div className={'magic-loader-container'}>
        <div className="magic-loader flex items-center justify-center">
          <Tree withText={!isMinimal} />
          {!isMinimal && <TreeText />}
        </div>
      </div>
    </div>
  );
};

export default TreeLoader;
