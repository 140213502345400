import React from 'react';
import { UserBuildingStorey } from '@/models';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import ExtrudeWall from './ExtrudeWall';
import { useExtrudeHandlers } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/useExtrudeHandlers';

interface ExtrudeStoreyProps {
  storey: UserBuildingStorey;
  extrudeNode: ExtrudeHandlerData;
  buildingGuid: string;
  blockGuid: string;
}

const ExtrudeStorey: React.FC<ExtrudeStoreyProps> = ({
  storey,
  extrudeNode,
  blockGuid,
  buildingGuid,
}) => {
  const {
    maxNegativeDistance,
    maxPositiveDistance,
    extrudeHandlerPosition,
    mousePosition,
    handleMaxPositiveDistance,
    handleMaxNegativeDistance,
    setExtrudeHandlerPosition,
  } = useExtrudeHandlers({ extrudeNode });

  return (
    <>
      <ExtrudeWall
        storey={storey}
        extrudeNode={extrudeNode}
        cursorPosition={mousePosition}
        blockGuid={blockGuid}
        buildingGuid={buildingGuid}
        setHandlerPosition={setExtrudeHandlerPosition}
        maxNegativeDistance={maxNegativeDistance}
        setMaxNegativeDistance={handleMaxNegativeDistance}
        maxPositiveDistance={maxPositiveDistance}
        setMaxPositiveDistance={handleMaxPositiveDistance}
      />
      <ExtrudeDotHandler
        extrudeHandlerData={extrudeNode}
        active
        shapeCoordinates={storey.floor.points}
        position={extrudeHandlerPosition}
      />
    </>
  );
};

export default ExtrudeStorey;
