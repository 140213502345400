import React from 'react';
import { UserBuildingCoordinatesObject } from '@/models';
import Building from '@/routes/dashboard/projects/project/UserBuilding/components/Building';

interface EditUserBuildingProps {
  userBuildings?: UserBuildingCoordinatesObject[];
}

const EditUserBuilding: React.FC<EditUserBuildingProps> = ({
  userBuildings,
}) => {
  if (!userBuildings || !userBuildings.length) return null;

  return (
    <>
      {userBuildings.map((building) => (
        <group key={building.guid}>
          <Building building={building} />
        </group>
      ))}
    </>
  );
};

export default EditUserBuilding;
