import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import TwoDIcon from '@/components/Toolbar/icons/TwoDIcon';
import ThreeDIcon from '@/components/Toolbar/icons/ThreeDIcon';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getCameraPolarAngle,
  setCameraPolarAngle,
} from '@/store/slices/canvasCamerasSlice';
import { CanvasCameraPolarAngles } from '@/models';

const TwoDThreeDView = () => {
  const dispatch = useAppDispatch();

  const isTopView =
    useAppSelector(getCameraPolarAngle) === CanvasCameraPolarAngles.topView;

  const handleView = () => {
    dispatch(
      setCameraPolarAngle(
        isTopView
          ? CanvasCameraPolarAngles.defaultView
          : CanvasCameraPolarAngles.topView
      )
    );
  };

  const getIcon = () => (isTopView ? <TwoDIcon /> : <ThreeDIcon />);
  const getTooltipText = () =>
    isTopView ? 'Switch to 3D mode' : 'Switch to 2D mode';

  return (
    <ToolbarButton
      icon={getIcon()}
      tooltipText={getTooltipText()}
      onClick={handleView}
    />
  );
};

export default TwoDThreeDView;
