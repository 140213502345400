export const BUILDING_WIDTH_IN_METERS = 20;
export const FLOORS_INITAL_COUNT = 2;

export enum RENDER_ORDERS {
  DOT = 10,
  DOT_BACKGROUND = 15,
  EDIT_NODE_LINE = 20,
}

export const WINDOW_CREATOR_STAGE_ID = '2d-konva__window-creator__stage';
export const WINDOW_CREATOR_VIEW = '2d-konva__window-creator__window-view';

export const LEFT_PANEL_DEFAULT_WIDTH = 210;
