import React from 'react';
import { Menu, MenuProps } from 'antd';
import './AntdMenu.scss';

interface IntusMenuProps extends React.FC<MenuProps> {
  Item: typeof Menu.Item;
}

const IntusMenu: IntusMenuProps = ({ ...rest }) => {
  return <Menu rootClassName={'intus-menu'} {...rest} />;
};

IntusMenu.Item = Menu.Item;

export default IntusMenu;
