import React from 'react';
import BrowserPanelItem from '../BrowserPanelItem';
import { ConstructionSiteStructure, NodeType, PanelItemProps } from '@/models';

import { useUpdateProjectEnvironmentData } from '@/shared/hooks/updateProjectDataHooks/useUpdateProjectEnvironmentData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { useAppSelector } from '@/store/hooks';
import { getIsNodeSelected } from '@/store/slices/canvasBuildingSlice';

interface ConstructionSitePanelItemProps extends PanelItemProps {
  constructionSite: ConstructionSiteStructure;
}

const ConstructionSitePanelItem: React.FC<ConstructionSitePanelItemProps> = ({
  isParentSelected,
  isParentLocked,
  isParentHidden,
  constructionSite,
}) => {
  const { constructionSiteUtils } = useUpdateProjectEnvironmentData();

  const { isIsolateModeEnabled } = useIsolationHandlers();
  const isNodeSelected = useAppSelector(
    getIsNodeSelected(constructionSite.guid)
  );

  const handleShowHideConstructionSite = () => {
    constructionSiteUtils.updateConstructionSiteUserData(
      'isHidden',
      !constructionSite.userData?.isHidden
    );
  };

  const handleLockUnlockConstructionSite = () => {
    constructionSiteUtils.updateConstructionSiteUserData(
      'isLocked',
      !constructionSite.userData?.isLocked
    );
  };

  return (
    <BrowserPanelItem
      text="Construction site"
      depthLevel={2}
      isParentSelected={isParentSelected || isNodeSelected}
      isParentLocked={isParentLocked || constructionSite?.userData?.isLocked}
      isParentHidden={isParentHidden || constructionSite.userData?.isHidden}
      isLocked={!!constructionSite.userData?.isLocked}
      isHidden={!!constructionSite.userData?.isHidden}
      nodeType={NodeType.ConstructionSite}
      elementGUID={constructionSite.guid}
      showHideAction={
        !isIsolateModeEnabled ? handleShowHideConstructionSite : undefined
      }
      lockUnlockAction={handleLockUnlockConstructionSite}
      isLastChildItem={true}
    />
  );
};

export default ConstructionSitePanelItem;
