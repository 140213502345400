import { ProjectStructure, UnitSystemTypes } from '@/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { projectsApi } from '@/store/apis/projectsApi';

interface ProjectState {
  projects: { [key: string]: Partial<ProjectStructure> };
}

const initialState: ProjectState = {
  projects: {},
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ProjectStructure>) => {
      state.projects[action.payload.id] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      projectsApi.endpoints?.fetchProject.matchFulfilled,
      (state, { payload }) => {
        state.projects[payload.id] = {
          name: payload.name,
          cameras: payload.cameras,
          unitSystem: payload.unitSystem,
        };
      }
    );
    builder.addMatcher(
      projectsApi.endpoints?.createNewCamera.matchFulfilled,
      (state, action) => {
        state.projects[action.meta.arg.originalArgs.projectId]?.cameras?.push(
          action.payload
        );
      }
    );
  },
});

export const { setProject } = projectSlice.actions;

export const getProjectName = (state: RootState, id: string) =>
  state.canvasReducer.project.projects?.[id]?.name;

export const getProjectCameras = (state: RootState, id: string) =>
  state.canvasReducer.project.projects?.[id]?.cameras;

export const getProjectUnits =
  (id: string) =>
  (state: RootState): UnitSystemTypes => {
    return (
      state.canvasReducer.project.projects?.[id].unitSystem ??
      UnitSystemTypes.Metric
    );
  };
