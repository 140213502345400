import React from 'react';

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-[#8D8B8F] hover:fill-[#D23736]"
    >
      <path d="M9 5H11C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5ZM8 5C8 3.89543 8.89543 3 10 3C11.1046 3 12 3.89543 12 5H16C16.2761 5 16.5 5.22386 16.5 5.5C16.5 5.77614 16.2761 6 16 6H15.4364L14.2313 14.8378C14.0624 16.0765 13.0044 17 11.7542 17H8.24578C6.99561 17 5.93762 16.0765 5.76871 14.8378L4.56355 6H4C3.72386 6 3.5 5.77614 3.5 5.5C3.5 5.22386 3.72386 5 4 5H8ZM9 8.5C9 8.22386 8.77614 8 8.5 8C8.22386 8 8 8.22386 8 8.5V13.5C8 13.7761 8.22386 14 8.5 14C8.77614 14 9 13.7761 9 13.5V8.5ZM11.5 8C11.7761 8 12 8.22386 12 8.5V13.5C12 13.7761 11.7761 14 11.5 14C11.2239 14 11 13.7761 11 13.5V8.5C11 8.22386 11.2239 8 11.5 8ZM6.75954 14.7027C6.86089 15.4459 7.49568 16 8.24578 16H11.7542C12.5043 16 13.1391 15.4459 13.2405 14.7027L14.4272 6H5.57281L6.75954 14.7027Z" />
    </svg>
  );
};

export default DeleteIcon;
