import React from 'react';
import {
  HeaderPanelItemProps,
  NodeType,
  UserBuildingCoordinatesObject,
} from '@/models';
import BrowserPanelItem from '../BrowserPanelItem';
import { IntusCollapse } from '@/shared/elements';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { getIsNodeSelected } from '@/store/slices/canvasBuildingSlice';
import { useAppSelector } from '@/store/hooks';
import { BlocksPanelItem } from './BlocksPanelItem';

interface BuildingsPanelItem extends HeaderPanelItemProps {
  buildings?: UserBuildingCoordinatesObject[];
}

export const BuildingsPanelItem = ({
  depthLevel,
  buildings,
  keys,
  onCollapseChange,
}: BuildingsPanelItem) => {
  const userBuildingUtils = useUpdateUserBuildingData();

  const { isIsolateModeEnabled } = useIsolationHandlers();

  const handleDeleteBuilding = (buildingGUID: string) => {
    userBuildingUtils.deleteUserBuildings([buildingGUID]);
  };

  const handleShowHideBuilding = (
    buildingGUID: string,
    previousValue: boolean
  ) => {
    userBuildingUtils.updateUserBuildingUserData(
      buildingGUID,
      'isHidden',
      !previousValue
    );
  };

  const handleLockUnlockBuilding = (
    buildingGUID: string,
    previousValue: boolean
  ) => {
    userBuildingUtils.updateUserBuildingUserData(
      buildingGUID,
      'isLocked',
      !previousValue
    );
  };

  const handleRenameBuilding = (buildingGUID: string, newName: string) => {
    userBuildingUtils.updateUserBuildingName(buildingGUID, newName);
  };

  const getBuildings = () =>
    buildings &&
    buildings.map((building) => {
      const isBuildingSelected = useAppSelector(
        getIsNodeSelected(building.guid)
      );

      return {
        key: building.guid,
        label: (
          <BrowserPanelItem
            text={building.name}
            elementGUID={building.guid}
            nodeType={NodeType.Building}
            deleteAction={() => handleDeleteBuilding(building.guid)}
            isParentSelected={isBuildingSelected}
            isHidden={!!building.userData?.isHidden}
            isLocked={!!building.userData?.isLocked}
            showHideAction={
              !isIsolateModeEnabled
                ? () =>
                    handleShowHideBuilding(
                      building.guid,
                      !!building.userData?.isHidden
                    )
                : undefined
            }
            lockUnlockAction={() =>
              handleLockUnlockBuilding(
                building.guid,
                !!building.userData?.isLocked
              )
            }
            renameAction={(name) => handleRenameBuilding(building.guid, name)}
            depthLevel={depthLevel}
          />
        ),
        children: (
          // TODO: Add verification later. if 1 block - show floors, if more-> show blocks
          // <FloorsPanelItem
          //   block={building.blocks[0]}
          //   onCollapseChange={onCollapseChange}
          //   keys={keys}
          //   depthLevel={depthLevel + 1}
          //   building={building}
          //   isParentSelected={isBuildingSelected}
          // />
          <BlocksPanelItem
            building={building}
            onCollapseChange={onCollapseChange}
            keys={keys}
            depthLevel={depthLevel + 1}
            isParentSelected={isBuildingSelected}
            isParentHidden={!!building.userData?.isHidden}
            isParentLocked={!!building.userData?.isLocked}
          ></BlocksPanelItem>
        ),
      };
    });

  return (
    <IntusCollapse
      items={getBuildings()}
      collapsible={'icon'}
      onChange={onCollapseChange}
      activeKey={keys}
      destroyInactivePanel={true}
    />
  );
};
