import React from 'react';
import MetricsIcon from '@/shared/icons/MetricsIcon';

const MetricsHeader = () => {
  return (
    <div className="flex items-center justify-start gap-1 px-3 pt-3">
      <MetricsIcon />
      <span className="font-medium ">METRICS</span>
    </div>
  );
};

export default MetricsHeader;
