import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';
import { User } from '@/models/user';

const userApi = createApi({
  reducerPath: 'user',
  keepUnusedDataFor: 10000,
  tagTypes: ['User'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    fetchUser: build.query<User, void>({
      providesTags: ['User'],
      query: () => ({
        url: '/user/settings',
        method: 'GET',
      }),
    }),
    updateUser: build.mutation<User, Partial<User>>({
      query: (projectData) => ({
        url: '/user/settings',
        method: 'PUT',
        body: projectData,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const { useUpdateUserMutation, useFetchUserQuery } = userApi;
export { userApi };
