import React from 'react';

const CutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8569 11.3116C13.6969 11.5416 13.3869 11.6016 13.1569 11.4516L9.92687 9.21L10.8369 8.6L13.7169 10.6116C13.9469 10.7716 14.0069 11.0816 13.8569 11.3116ZM5.68478 10.829C5.74378 11.043 5.77678 11.268 5.77678 11.5L5.77578 11.5C5.77578 12.879 4.65378 14 3.27578 14C1.89778 14 0.77578 12.879 0.77578 11.5C0.77578 10.121 1.89778 9 3.27578 9C4.06178 9 4.76378 9.365 5.22278 9.934L8.12278 8.001L5.22078 6.069C4.76278 6.636 4.06178 7 3.27678 7C1.89878 7 0.776781 5.879 0.776781 4.5C0.776781 3.121 1.89878 2 3.27678 2C4.65478 2 5.77678 3.121 5.77678 4.5C5.77678 4.731 5.74478 4.954 5.68578 5.166L9.03378 7.395L13.2228 4.668C13.4528 4.515 13.7628 4.578 13.9158 4.807C14.0688 5.034 14.0078 5.346 13.7768 5.5L5.68478 10.829ZM3.27678 3C2.44978 3 1.77678 3.673 1.77678 4.5C1.77678 5.327 2.44978 6 3.27678 6C4.10378 6 4.77678 5.327 4.77678 4.5C4.77678 3.673 4.10378 3 3.27678 3ZM3.27678 10C2.44978 10 1.77678 10.673 1.77678 11.5C1.77678 12.327 2.44978 13 3.27678 13C4.10378 13 4.77678 12.327 4.77678 11.5C4.77678 10.673 4.10378 10 3.27678 10Z"
        fill="#242424"
      />
    </svg>
  );
};

export default CutIcon;
