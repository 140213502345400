import { CanvasActionsModes, EditModes, SelectedNodeSource } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setHoveredNode as setHoveredNodeReducer,
  resetHoveredNode as resetHoveredNodesReducer,
  getIsNodeHovered,
  getExtrudeNode,
} from '@/store/slices/canvasBuildingSlice';
import { ThreeEvent } from '@react-three/fiber';
import { getCanvasMode, getEditMode } from '@/store/slices/canvasModesSlice';

interface UseHoveredNodeProps {
  nodeGUID: string;
  isLocked?: boolean;
  source?: SelectedNodeSource;
}

export const useHoveredNode = ({
  nodeGUID,
  isLocked,
  source,
}: UseHoveredNodeProps) => {
  const dispatch = useAppDispatch();
  const isSelectionMode =
    useAppSelector(getCanvasMode) === CanvasActionsModes.selection;

  const isExtrudeMode = !!useAppSelector(getExtrudeNode);
  const isEditMode = useAppSelector(getEditMode) !== EditModes.Unset;
  const isNodeHovered = useAppSelector(getIsNodeHovered(nodeGUID));
  const isLockedToHover = source
    ? isLocked && source === SelectedNodeSource.Viewer
    : isLocked;

  const setHoveredNode = (event?: ThreeEvent<PointerEvent>) => {
    event?.stopPropagation();
    if (isLockedToHover) return;

    isSelectionMode &&
      nodeGUID &&
      !isExtrudeMode &&
      dispatch(setHoveredNodeReducer(nodeGUID));
  };

  const resetHoveredNode = (event?: ThreeEvent<PointerEvent>) => {
    event?.stopPropagation();
    !isEditMode && dispatch(resetHoveredNodesReducer());
  };

  return {
    setHoveredNode,
    resetHoveredNode,
    isNodeHovered,
  };
};
