import React, { useEffect, useMemo, useRef } from 'react';
import { EnvironmentObject, NodeType } from '@/models';
import {
  generateBuildingGeometry,
  generateBuildingShape,
  getBBoxCenter,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { GeolibGeoJSONPoint } from 'geolib/es/types';
import { BBox } from '@turf/turf';
import * as THREE from 'three';
import {
  BUILDING_SELECTED_CONTOUR_COLOR,
  SELECTED_BUILDING_MATERIAL_COLOR,
} from '@/shared/materials';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import { ThreeEvent } from '@react-three/fiber';
import { useAppSelector } from '@/store/hooks';
import {
  getIsNodeHovered,
  getIsNodeSelected,
} from '@/store/slices/canvasBuildingSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';

const ProjectCanvasBuilding = ({
  envBuilding,
  mapBoundingBox,
  isParentSelected,
  isParentLocked,
}: {
  envBuilding: EnvironmentObject;
  mapBoundingBox: BBox;
  isParentSelected: boolean;
  isParentLocked?: boolean;
}) => {
  if (envBuilding?.userData?.isHidden) return null;

  const isEnvBuildingSelected = useAppSelector(
    getIsNodeSelected(envBuilding.guid)
  );

  const { selectNode } = useSelectedNodes();

  const mapCenter: GeolibGeoJSONPoint = getBBoxCenter(mapBoundingBox);
  const meshRef = useRef<THREE.Mesh>(null);
  const segmentsRef = useRef<THREE.LineSegments>(null);

  const { setHoveredNode, resetHoveredNode } = useHoveredNode({
    nodeGUID: envBuilding.guid,
    isLocked: isParentLocked || envBuilding?.userData?.isLocked,
  });

  useEffect(() => {
    segmentsRef.current!.geometry = new THREE.EdgesGeometry(
      meshRef.current!.geometry
    );
    segmentsRef.current!.geometry.computeBoundingBox();
    segmentsRef.current!.material = new THREE.LineBasicMaterial({
      color: BUILDING_SELECTED_CONTOUR_COLOR,
    });
    meshRef.current!.userData = envBuilding;
  }, []);

  const isCurrentNodeHovered = useAppSelector(
    getIsNodeHovered(envBuilding.guid)
  );

  useEffect(() => {
    (segmentsRef.current!.material as THREE.LineBasicMaterial).colorWrite =
      isCurrentNodeHovered;
  }, [isCurrentNodeHovered]);

  const building = () => {
    const shape = useMemo(
      () => generateBuildingShape(envBuilding.coordinates, mapCenter),
      []
    );
    const geometry = useMemo(
      () => generateBuildingGeometry(shape, envBuilding.levelsCount || 1),
      []
    );

    const onPointerDown = (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation();

      selectNode({
        event,
        type: NodeType.SurroundingBuilding,
        guid: envBuilding.guid,
        isLocked: isParentLocked || !!envBuilding.userData?.isLocked,
        isSelected: isEnvBuildingSelected,
      });
    };

    return (
      <>
        <mesh
          castShadow
          key={geometry.uuid}
          geometry={geometry}
          ref={meshRef}
          onPointerEnter={setHoveredNode}
          onPointerLeave={resetHoveredNode}
          onPointerDown={onPointerDown}
          userData={{
            ...envBuilding,
            nodeType: NodeType.SurroundingBuilding,
          }}
        >
          <meshStandardMaterial
            color={
              isEnvBuildingSelected || isParentSelected
                ? SELECTED_BUILDING_MATERIAL_COLOR
                : '#b4b4b4'
            }
          />
        </mesh>
        <lineSegments ref={segmentsRef} />
      </>
    );
  };

  return <>{building()}</>;
};

export default ProjectCanvasBuilding;
