import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import defaultMapIcon from '../icons/DefaultMapIcon.png';
import satelliteMapIcon from '../icons/SatelliteMapIcon.png';
import { CanvasMapTypes } from '@/models';
import { getCanvasMapType, setMapType } from '@/store/slices/canvasModesSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSatelliteMapImage } from '@/store/slices/canvasMapSlice';

const MapView = () => {
  const mapType = useAppSelector(getCanvasMapType);
  const dispatch = useAppDispatch();
  const satelliteMapImage = useAppSelector(getSatelliteMapImage);

  const isLightMap = mapType === CanvasMapTypes.light;
  const isSatelliteMapAvailable = !!satelliteMapImage;

  const changeMapType = () => {
    isLightMap
      ? dispatch(setMapType(CanvasMapTypes.satellite))
      : dispatch(setMapType(CanvasMapTypes.light));
  };

  const getTooltipText = () =>
    isLightMap ? 'Switch to Satellite map' : 'Switch to Default map';
  const getTooltipSubText = () =>
    !isSatelliteMapAvailable ? 'Loading...' : undefined;

  const getMapIcon = () => {
    return isLightMap ? (
      <img src={defaultMapIcon} alt="Default map icon" width={20} height={20} />
    ) : (
      <img
        src={satelliteMapIcon}
        alt="Satellite map icon"
        width={20}
        height={20}
      />
    );
  };

  return (
    <ToolbarButton
      icon={getMapIcon()}
      tooltipText={getTooltipText()}
      tooltipSubText={getTooltipSubText()}
      onClick={changeMapType}
      disabled={!isSatelliteMapAvailable}
    />
  );
};

export default MapView;
