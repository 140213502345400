import React, { useEffect } from 'react';
import { FreeDrawMode, RectangleDrawMode } from './CanvasBuildingModes';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getDrawMode,
  getIsStoreyCreationStep,
  setIsInDrawMode,
} from '@/store/slices/canvasModesSlice';
import { DrawModes } from '@/models';
import CenterLineDrawMode from '@/shared/components/CanvasActionHandlers/CanvasBuildingModes/CenterLineDrawMode';
import { resetExternalElementsState } from '@/store/slices/canvasExternalElementsSlice';

const CanvasBuildingActionHandlers = () => {
  const drawMode = useAppSelector(getDrawMode);
  const dispatch = useAppDispatch();
  const isStoreyCreationStep = useAppSelector(getIsStoreyCreationStep);

  useEffect(() => {
    return () => {
      dispatch(setIsInDrawMode(false));
      dispatch(resetExternalElementsState());
    };
  }, []);

  if (isStoreyCreationStep) return null;
  return (
    <>
      {drawMode === DrawModes.FreeDraw && <FreeDrawMode />}
      {drawMode === DrawModes.Rectangle && <RectangleDrawMode />}
      {drawMode === DrawModes.CenterLine && <CenterLineDrawMode />}
    </>
  );
};

export default CanvasBuildingActionHandlers;
