import React, { useCallback, useEffect, useState } from 'react';

import { MetricLimits, NodeType, UserBuildingBlock } from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import TotalSurface from '@/shared/elements/TotalSurface/TotalSurface';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import {
  BlockSearchResults,
  useFindNodeData,
} from '@/shared/hooks/useFindNodeData';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useUpdateUserBuildingBlockMutation } from '@/store/apis/projectsApi';
import { isEqual } from 'lodash';

const BlockFrame = ({ blocksGUIDs }: { blocksGUIDs: string[] }) => {
  const { getNodeData, findUserBuildingBlock } = useFindNodeData();
  const [updateUserBuildingBlock] = useUpdateUserBuildingBlockMutation();
  const userBuildingUtils = useUpdateUserBuildingData();

  const selectedBlocksData = blocksGUIDs.map(
    (blockGUID) =>
      getNodeData({
        guid: blockGUID,
        nodeType: NodeType.Block,
      }) as BlockSearchResults
  );

  const getBlockName = (block: BlockSearchResults) => {
    return block?.name;
  };

  const userBuildingBlocks = blocksGUIDs.map((blockGUID) =>
    findUserBuildingBlock(blockGUID)
  );
  const [initialBuildingBlocks, setInitialBuildingBlocks] =
    useState<UserBuildingBlock[]>(userBuildingBlocks);

  const updateInitialBuildingBlocks = useCallback(
    (prevBlocks: UserBuildingBlock[]) =>
      !isEqual(
        blocksGUIDs,
        prevBlocks.map((block) => block.guid)
      )
        ? userBuildingBlocks
        : prevBlocks,
    [blocksGUIDs, userBuildingBlocks]
  );

  useEffect(() => {
    setInitialBuildingBlocks(updateInitialBuildingBlocks);
  }, [updateInitialBuildingBlocks]);

  const handleSubmit = () => {
    userBuildingBlocks.forEach((userBuildingBlock) => {
      updateUserBuildingBlock({
        data: userBuildingBlock,
      });
    });
    setInitialBuildingBlocks(userBuildingBlocks);
  };

  const changeStoreysCountMetric = (value: string) => {
    initialBuildingBlocks.forEach((userBuildingBlock) => {
      const updatedBlock = changeStoreysNumberInBlock(value, userBuildingBlock);

      userBuildingUtils.updateUserBuildingBlockStoreys({
        blockGUID: userBuildingBlock.guid,
        updatedBlock: updatedBlock!,
      });
    });
  };

  const {
    getBlockStoreysCountMetric,
    getBlockHeightMetric,
    getFloorHeightMetric,
    getFacadesAreaMetricForBlocks,
    getGrossInternalAreaForBlocks,
    changeStoreysNumberInBlock,
  } = useFrameProperties();

  const blocksStoreysCountMetric =
    getBlockStoreysCountMetric(selectedBlocksData);

  return (
    <>
      <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {selectedBlocksData.length > 1
            ? `Block (${selectedBlocksData.length})`
            : getBlockName(selectedBlocksData[0])}
        </span>
      </div>

      <div
        className={
          'flex flex-col text-xs border border-l-0 border-solid border-light-gray-20 !bg-white overflow-y-auto text-dark-gray-100'
        }
      >
        <MetricsHeader />
        <PropertyList
          alignValueLeft
          properties={[
            { name: 'Floor height', value: getFloorHeightMetric() },
            {
              name: 'Floor count',
              value: blocksStoreysCountMetric,
              isEditable: true,
              onEdit: changeStoreysCountMetric,
              onSubmit: handleSubmit,
              min: MetricLimits.FloorsMin,
              max: MetricLimits.FloorsMax,
              staticValue: true,
            },
            {
              name: 'Block height',
              value: getBlockHeightMetric(blocksStoreysCountMetric),
            },
          ]}
        />
      </div>

      <TotalSurface
        facadesArea={getFacadesAreaMetricForBlocks(selectedBlocksData)}
        grossInternalArea={getGrossInternalAreaForBlocks(selectedBlocksData)}
      />
    </>
  );
};

export default BlockFrame;
