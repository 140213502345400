import React from 'react';
import { BaseIconProps } from '@/models';

export const CubeIcon: React.FC<BaseIconProps> = ({
  fill = '#fff',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M5.87105 6.30045C5.55379 6.16448 5.18636 6.31145 5.05039 6.62872C4.91442 6.94599 5.06139 7.31341 5.37866 7.44938L9.37486 9.16204V13.1249C9.37486 13.4701 9.65468 13.7499 9.99986 13.7499C10.345 13.7499 10.6249 13.4701 10.6249 13.1249V9.16204L14.6211 7.44938C14.9383 7.31341 15.0853 6.94599 14.9493 6.62872C14.8133 6.31145 14.4459 6.16448 14.1287 6.30045L9.99986 8.06994L5.87105 6.30045ZM11.3462 1.7231C10.4797 1.38983 9.52033 1.38983 8.65383 1.7231L2.45191 4.10845C1.7278 4.38695 1.25 5.08265 1.25 5.85847V14.1415C1.25 14.9173 1.72781 15.613 2.45191 15.8915L8.65383 18.2768C9.52034 18.6101 10.4797 18.6101 11.3462 18.2768L17.5481 15.8915C18.2722 15.613 18.75 14.9173 18.75 14.1415V5.85847C18.75 5.08265 18.2722 4.38695 17.5481 4.10845L11.3462 1.7231ZM9.10255 2.88978C9.68022 2.6676 10.3198 2.6676 10.8974 2.88978L17.0994 5.27513C17.3407 5.36797 17.5 5.59987 17.5 5.85847V14.1415C17.5 14.4001 17.3407 14.632 17.0994 14.7248L10.8974 17.1102C10.3198 17.3323 9.68022 17.3323 9.10255 17.1102L2.90064 14.7248C2.65927 14.632 2.5 14.4001 2.5 14.1415V5.85847C2.5 5.59987 2.65927 5.36797 2.90064 5.27513L9.10255 2.88978Z"
        fill={fill}
      />
    </svg>
  );
};
