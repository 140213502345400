import { useEffect } from 'react';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';

const UseMouseOverCanvas = (
  positionHandler: ({ x, y }: { x: number; y: number }) => void
) => {
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) =>
      positionHandler({ x: event.pageX, y: event.pageY });

    const canvas = document.getElementById(PROJECT_CANVAS_ID);

    const handleMouseOver = (event: MouseEvent) => {
      handleMouseMove(event);
      canvas?.removeEventListener('mouseup', handleMouseOver);
      document.removeEventListener('mouseover', handleMouseOver);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseover', handleMouseOver);
    canvas?.addEventListener('mouseup', handleMouseOver);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
};

export default UseMouseOverCanvas;
