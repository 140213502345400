import React from 'react';
import { OperationType, WindowColor } from '@/models/window-configurator.model';
import FixedOperationTypeIcon from '../icons/OperationTypes/FixedIcon';
import DualActionRightTopIcon from '../icons/OperationTypes/DualActionRightTopIcon';
import DualActionLeftTopIcon from '../icons/OperationTypes/DualActionLeftTopIcon';
import CasementLeftIcon from '../icons/OperationTypes/CasementLeftIcon';
import CasementRightIcon from '../icons/OperationTypes/CasementRightIcon';
import AwningIcon from '../icons/OperationTypes/AwningIcon';
import HopperIcon from '../icons/OperationTypes/Hopper';

export const getHexByName = (colors: WindowColor[], name: string) => {
  return colors.find((color) => color.name === name)?.hex;
};

export const getColorDescriptionByName = (
  colors: WindowColor[],
  name: string
) => {
  return colors.find((color) => color.name === name)?.description;
};

export const getOperationTypeIcon = (operationType: string) => {
  switch (operationType) {
    case OperationType.Fixed:
      return <FixedOperationTypeIcon />;
    case OperationType.DualActionRightTop:
      return <DualActionRightTopIcon />;
    case OperationType.DualActionLeftTop:
      return <DualActionLeftTopIcon />;
    case OperationType.CasementLeft:
      return <CasementLeftIcon />;
    case OperationType.CasementRight:
      return <CasementRightIcon />;
    case OperationType.Awning:
      return <AwningIcon />;
    case OperationType.Hopper:
      return <HopperIcon />;
  }
};
