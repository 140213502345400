import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const CenterLineIcon: React.FC<BaseIconProps> = ({ fill = '#fff' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C5.27614 3 5.5 3.22386 5.5 3.5V16.5C5.5 16.7761 5.27614 17 5 17C4.72386 17 4.5 16.7761 4.5 16.5V3.5C4.5 3.22386 4.72386 3 5 3ZM15 3C15.2761 3 15.5 3.22386 15.5 3.5V16.5C15.5 16.7761 15.2761 17 15 17C14.7239 17 14.5 16.7761 14.5 16.5V3.5C14.5 3.22386 14.7239 3 15 3Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.5C10.2761 3.5 10.5 3.72386 10.5 4V5C10.5 5.27614 10.2761 5.5 10 5.5C9.72386 5.5 9.5 5.27614 9.5 5V4C9.5 3.72386 9.72386 3.5 10 3.5ZM10 6.5C10.2761 6.5 10.5 6.72386 10.5 7V9C10.5 9.27614 10.2761 9.5 10 9.5C9.72386 9.5 9.5 9.27614 9.5 9V7C9.5 6.72386 9.72386 6.5 10 6.5ZM10 10.5C10.2761 10.5 10.5 10.7239 10.5 11V13C10.5 13.2761 10.2761 13.5 10 13.5C9.72386 13.5 9.5 13.2761 9.5 13V11C9.5 10.7239 9.72386 10.5 10 10.5ZM10 14.5C10.2761 14.5 10.5 14.7239 10.5 15V16C10.5 16.2761 10.2761 16.5 10 16.5C9.72386 16.5 9.5 16.2761 9.5 16V15C9.5 14.7239 9.72386 14.5 10 14.5Z"
        fill={fill}
      />
      <path
        d="M8.5 16C8.5 15.1716 9.17157 14.5 10 14.5C10.8284 14.5 11.5 15.1716 11.5 16C11.5 16.8284 10.8284 17.5 10 17.5C9.17157 17.5 8.5 16.8284 8.5 16Z"
        fill={fill}
      />
      <path
        d="M8.5 4C8.5 3.17157 9.17157 2.5 10 2.5C10.8284 2.5 11.5 3.17157 11.5 4C11.5 4.82843 10.8284 5.5 10 5.5C9.17157 5.5 8.5 4.82843 8.5 4Z"
        fill={fill}
      />
    </svg>
  );
};
