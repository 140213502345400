import React, { useEffect, useMemo, useRef, useState } from 'react';
import IntusTooltip from '../Tooltip/Tooltip';
import WindowInfoPopover from '@/routes/dashboard/projects/project/CanvasExternalElements/LeftPanel/LibraryPanel/WindowInfoPopover/WindowInfoPopover';
import WindowContextMenu from '@/routes/dashboard/projects/project/CanvasExternalElements/LeftPanel/LibraryPanel/WindowContextMenu/WindowContextMenu';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { WindowPlacementModes } from '@/models/shared.model';
import { useAppSelector } from '@/store/hooks';
import { isWindowFromLibrarySelected } from '@/store/slices/windowsReducer/windowPlacementSlice';

interface WindowCardProps {
  placementMode: WindowPlacementModes;
  isWindowPlacementMode: boolean;
  windowData: SavedWindow;
  onSelect: (id: SavedWindow) => void;
}

const WindowCard: React.FC<WindowCardProps> = ({
  placementMode,
  isWindowPlacementMode,
  windowData,
  onSelect,
}) => {
  const [isWindowCardDragging, setIsWindowCardDragging] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isCardHighlighted, setIsCardHighlighted] = useState(false);
  const [shouldCheckHighlightStatus, setShouldCheckHighlightStatus] =
    useState(true);
  const windowRef = useRef<HTMLDivElement>(null);
  const [isWindowNameHovered, setIsWindowNameHovered] = useState(false);
  const selected = useAppSelector(isWindowFromLibrarySelected(windowData.id));

  const disabled = useMemo(() => {
    return (
      placementMode === WindowPlacementModes.Selection && isWindowPlacementMode
    );
  }, [placementMode, isWindowPlacementMode]);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setIsWindowCardDragging(true);
    setIsTooltipVisible(false);
    if (windowRef.current) {
      e.dataTransfer.setDragImage(windowRef.current, 0, 0);
    }
  };

  const disableHighlightStatus = () => {
    setIsCardHighlighted(false);
    setShouldCheckHighlightStatus(false);
  };

  useEffect(() => {
    if (!shouldCheckHighlightStatus) return;
    const checkHighlightStatus = () => {
      const timeDiff =
        (new Date().getTime() - new Date(windowData.createdAt).getTime()) /
        1000;

      if (timeDiff <= 30) {
        setIsCardHighlighted(true);
      } else {
        setIsCardHighlighted(false);
      }
    };

    checkHighlightStatus();
    const interval = setInterval(checkHighlightStatus, 1000);

    return () => clearInterval(interval);
  }, [windowData.createdAt, shouldCheckHighlightStatus]);

  useEffect(() => {
    isCardHighlighted &&
      document.addEventListener('mousedown', disableHighlightStatus);

    return () => {
      isCardHighlighted &&
        document.removeEventListener('mousedown', disableHighlightStatus);
    };
  }, [isCardHighlighted]);

  const handleTooltipVisibleChange = (visible: boolean) => {
    if (!isWindowCardDragging) {
      setIsTooltipVisible(visible);
    }
  };

  const getBorderClass = () => {
    if (disabled) return '';

    if (selected) {
      return 'outline outline-2 outline-offset-[-1.5px] outline-light-green-100';
    }
    if (isCardHighlighted) {
      return 'outline outline-light-green-60 outline-offset-[-1.5px]';
    }
    return '';
  };

  useEffect(() => {
    selected && onSelect(windowData);
  }, [windowData]);

  return (
    <>
      <div
        className={`h-[152px] box-border ${getBorderClass()} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <IntusTooltip
          title={
            <span>
              To apply drag and drop <br />
              the window to the wall
            </span>
          }
          trigger="hover"
          open={isTooltipVisible && !disabled && !isWindowPlacementMode}
          onOpenChange={handleTooltipVisibleChange}
          placement="left"
          mouseEnterDelay={0.5}
          overlayStyle={{ marginRight: '4px' }}
        >
          <div
            draggable={!disabled}
            className={`h-[118px] bg-light-gray-10 flex justify-center items-center ${disabled || placementMode === WindowPlacementModes.WindowPlacement ? '' : 'cursor-grab'}`}
            onDragStart={handleDragStart}
            onClick={() => !disabled && onSelect(windowData)}
            onDragEnd={() => setIsWindowCardDragging(false)}
          >
            <div className="h-24 mx-4" ref={windowRef}>
              <img
                className="object-contain w-full h-full"
                src={windowData.image}
                alt="generated window image"
              />
            </div>
          </div>
        </IntusTooltip>
        <IntusTooltip
          title={isWindowNameHovered ? windowData.name : ''}
          placement="right"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0}
          overlayStyle={{ marginLeft: '4px' }}
        >
          <div className="py-1 px-2 flex justify-between items-center">
            <div className="flex items-center h-5">
              <div
                onMouseEnter={() => setIsWindowNameHovered(true)}
                onMouseLeave={() => setIsWindowNameHovered(false)}
                className={`font-normal leading-5 text-xs mr-1 max-w-[114px] truncate ...`}
              >
                {windowData.name}
              </div>
              <WindowInfoPopover windowData={windowData} disabled={disabled} />
            </div>
            <WindowContextMenu disabled={disabled} windowData={windowData} />
          </div>
        </IntusTooltip>
      </div>
      {/*Divider in this element counts as a part of a window card*/}
      <div
        className={`bg-light-gray-20 h-[2px] ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      />
    </>
  );
};

export default WindowCard;
