import React from 'react';

const SplitIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13L8 3"
        stroke="#414042"
        strokeLinecap="round"
        strokeDasharray="2 2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3H4C3.44772 3 3 3.44772 3 4V12C3 12.5523 3.44772 13 4 13H12C12.5523 13 13 12.5523 13 12V4C13 3.44772 12.5523 3 12 3ZM4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2H4Z"
        fill="#414042"
      />
    </svg>
  );
};

export default SplitIcon;
