import * as THREE from 'three';
import { ShapeUtils } from 'three';
import { FlatVector3 } from '@/models';
import {
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
  convertMillimetersToMeters,
} from './distance';
import { addSpacesToThousands } from './format-data';
import { FLOOR_HEIGHT_IN_MILIMETERS } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { convertFlatVector3ToVector2 } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

interface ObjectDimensions {
  width: string;
  length: string;
}

export const getAreaInMeters = (
  objectPoints: FlatVector3[],
  multiplyRate: number = 1
) => {
  const targetPoints = objectPoints.map((point) =>
    convertFlatVector3ToVector2(point)
  );

  const floorArea = ShapeUtils.area(targetPoints) / Math.pow(multiplyRate, 2);
  return Number(Math.abs(floorArea)).toFixed(2);
};

export const getObjectDimensions = (
  points: FlatVector3[],
  multiplyRate: number,
  isImperialUnits: boolean
): ObjectDimensions => {
  const width = new THREE.Vector3(...points[0]).distanceTo(
    new THREE.Vector3(...points[1])
  );
  const length = new THREE.Vector3(...points[2]).distanceTo(
    new THREE.Vector3(...points[1])
  );

  const widthInMillimeters = convertMetersToMillimeters(width / multiplyRate);

  const lengthInMillimeters = convertMetersToMillimeters(length / multiplyRate);

  return {
    width: addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(widthInMillimeters)
        : Number(widthInMillimeters).toFixed(0),
      isImperialUnits
    ),
    length: addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(lengthInMillimeters)
        : Number(lengthInMillimeters).toFixed(0),
      isImperialUnits
    ),
  };
};

export const getWallWidth = (points: FlatVector3[], multiplyRate: number) => {
  return (
    new THREE.Vector3(...points[0]).distanceTo(
      new THREE.Vector3(...points[1])
    ) / multiplyRate
  );
};

export const getWallArea = (wallWidth: number) => {
  return (
    wallWidth *
    convertMillimetersToMeters(FLOOR_HEIGHT_IN_MILIMETERS.toString())
  );
};

export const getFacadesArea = ({
  walls,
  multiplyRate,
}: {
  walls: {
    points: FlatVector3[];
  }[];
  multiplyRate: number;
}) => {
  const facadeArea = walls.reduce((acc, wall) => {
    const width = getWallWidth(wall.points, multiplyRate);
    const wallArea =
      width * convertMillimetersToMeters(FLOOR_HEIGHT_IN_MILIMETERS.toString());
    return acc + Number(wallArea);
  }, 0);

  return Number(facadeArea.toFixed(2));
};

export const getFloorHeight = (isImperialUnits: boolean) => {
  return isImperialUnits
    ? convertMillimetersToFtInch(FLOOR_HEIGHT_IN_MILIMETERS)
    : addSpacesToThousands(
        FLOOR_HEIGHT_IN_MILIMETERS.toString(),
        isImperialUnits
      );
};

export const getTotalBlockHeight = (
  storeysCount: string,
  isImperialUnits: boolean
) => {
  if (storeysCount === '-') return '-';
  const buildingHeightInMillimeters =
    Number(storeysCount) * FLOOR_HEIGHT_IN_MILIMETERS;

  return isImperialUnits
    ? addSpacesToThousands(
        convertMillimetersToFtInch(buildingHeightInMillimeters),
        isImperialUnits
      )
    : addSpacesToThousands(
        buildingHeightInMillimeters.toString(),
        isImperialUnits
      );
};
