import { FlatVector2 } from '@/models/canvas.model';
import { FlatVector2Axis } from '@/components/WindowCreator/models/konva-model';

export interface WindowColor {
  name: string;
  description: string;
  hex: string;
}

export interface WindowConfigurator {
  colors: WindowColor[];
  glazing: {
    name: string;
    description: string;
  }[];
  operationTypes: {
    name: OperationType;
    description: string;
    swing: string;
    side: string;
  }[];
  baseWindow: {
    name: string;
    distanceToFloor: number;
    glazing: string;
    height: number;
    innerColor: string;
    operationType: OperationType;
    outerColor: string;
    width: number;
  };
}
export enum OperationType {
  Fixed = 'Fixed',
  DualActionLeftTop = 'DualActionLeftTop',
  DualActionRightTop = 'DualActionRightTop',
  CasementLeft = 'CasementLeft',
  CasementRight = 'CasementRight',
  Hopper = 'Hopper',
  Awning = 'Awning',
}

export interface MullionData {
  points: FlatVector2Axis;
}
export interface InnerWindowData {
  points: FlatVector2[];
  operationType: OperationType;
  glazing: string;
}

export interface WindowFrame {
  name: string;
  points: FlatVector2[];
  innerColor: string;
  outerColor: string;
  distanceToFloor: number;
  image: string;

  innerWindows: InnerWindowData[];
  mullions: MullionData[];
}
