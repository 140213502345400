import React, { useEffect } from 'react';
import { CanvasActionsModes, EditModes, NodeType } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getMapUUID } from '@/store/slices/canvasMapSlice';
import {
  getCanvasMode,
  getEditMode,
  resetCanvasMode,
  setMode,
} from '@/store/slices/canvasModesSlice';
import CanvasSelectionActionHandlers from '@/shared/components/CanvasActionHandlers/CanvasSelectionActionHandlers';
import CanvasBuildingActionHandlers from '@/shared/components/CanvasActionHandlers/CanvasBuildingActionHandlers';
import CanvasEditActionHandlers from '@/shared/components/CanvasActionHandlers/CanvasEditActionHandlers';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useUpdateProjectEnvironmentData } from '@/shared/hooks/updateProjectDataHooks/useUpdateProjectEnvironmentData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import {
  getEditedNode,
  getExtrudeNode,
  getSelectedNodes,
  getSelectedNodesByType,
  setEditedNode,
} from '@/store/slices/canvasBuildingSlice';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { hideSelectionBoxArea } from '@/shared/helpers';

const CanvasActionHandlers = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(getCanvasMode);
  const editMode = useAppSelector(getEditMode);
  const isExtrudeMode = !!useAppSelector(getExtrudeNode);
  const mapUUID = useAppSelector(getMapUUID)!;
  const { clearAllSelectedNodes, addNodesToSelectedNodes } = useSelectedNodes();
  const { deleteSelectedConstructions } = useUpdateUserBuildingData();
  const isDeleteAvailable =
    mode === CanvasActionsModes.selection &&
    editMode === EditModes.Unset &&
    !isExtrudeMode;

  const { surroundingBuildingsUtils } = useUpdateProjectEnvironmentData();

  const selectedNodes = useAppSelector(getSelectedNodes);
  const selectedSurroundingBuildings: string[] = useAppSelector(
    getSelectedNodesByType(NodeType.SurroundingBuilding)
  ).map((node) => node.guid);

  const selectedBuildings = useAppSelector(
    getSelectedNodesByType(NodeType.Building)
  ).map((node) => node.guid)[0];

  const selectedBlocks = useAppSelector(getSelectedNodesByType(NodeType.Block));
  const isAllSurroundingBuildingsSelected =
    useAppSelector(getSelectedNodesByType(NodeType.SurroundingBuildings))
      .length === 1;

  const {
    handleDisableIsolateMode,
    isIsolateModeEnabled,
    handleIsolateAction,
  } = useIsolationHandlers();

  const editedNode = useAppSelector(getEditedNode);

  const handleDelete = () => {
    selectedSurroundingBuildings.length &&
      surroundingBuildingsUtils.deleteSurroundingBuildings(
        selectedSurroundingBuildings
      );

    isAllSurroundingBuildingsSelected &&
      surroundingBuildingsUtils.deleteAllSurroundingBuildings();

    selectedBlocks &&
      deleteSelectedConstructions(
        selectedBlocks.map((block) => ({
          guid: block.guid,
          nodeType: NodeType.Block,
        }))
      );

    selectedBuildings &&
      deleteSelectedConstructions([
        {
          guid: selectedBuildings,
          nodeType: NodeType.Building,
        },
      ]);

    clearAllSelectedNodes();
  };

  const keydownEvent = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'i') {
      if (isIsolateModeEnabled) {
        handleDisableIsolateMode();
      } else {
        handleIsolateAction(Object.values(selectedNodes));
      }
    }

    switch (event.key) {
      case 'Escape': {
        if (mode === CanvasActionsModes.selection) {
          clearAllSelectedNodes();
          if (isIsolateModeEnabled) handleDisableIsolateMode();
        }
        if (editMode === EditModes.Split) {
          dispatch(setMode(CanvasActionsModes.selection));
          editedNode && addNodesToSelectedNodes([editedNode]);
          dispatch(setEditedNode(undefined));
        }
        break;
      }
      case 'Delete':
      case 'Backspace': {
        isDeleteAvailable && handleDelete();
        break;
      }
    }
  };

  const handlePointerMove = () => {
    hideSelectionBoxArea();
  };

  useEffect(() => {
    document.addEventListener('keydown', keydownEvent);
    return () => {
      document.removeEventListener('keydown', keydownEvent);
    };
  }, [
    mode,
    editMode,
    isExtrudeMode,
    selectedNodes,
    isIsolateModeEnabled,
    isAllSurroundingBuildingsSelected,
  ]);

  useEffect(() => {
    if (editMode !== EditModes.Unset) {
      clearAllSelectedNodes();
    } else {
      dispatch(setEditedNode(undefined));
    }
  }, [editMode]);

  useEffect(() => {
    dispatch(resetCanvasMode());
    return () => {
      dispatch(resetCanvasMode());
    };
  }, []);

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    mode !== CanvasActionsModes.selection &&
      canvas?.addEventListener('pointermove', handlePointerMove);

    return () => {
      canvas?.removeEventListener('pointermove', handlePointerMove);
    };
  }, [mode]);

  return (
    <>
      {mode === CanvasActionsModes.selection && (
        <CanvasSelectionActionHandlers mapUUID={mapUUID} />
      )}
      {mode === CanvasActionsModes.building && <CanvasBuildingActionHandlers />}
      {mode === CanvasActionsModes.edit && <CanvasEditActionHandlers />}
    </>
  );
};

export default CanvasActionHandlers;
