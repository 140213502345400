import React from 'react';

const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-[#8D8B8F] hover:fill-[#414042]"
    >
      <path d="M16.236 3.76386C15.2123 2.74017 13.5525 2.74017 12.5289 3.76386L4.65722 11.6355C4.28304 12.0097 4.01623 12.4775 3.88467 12.99L3.01571 16.3755C2.97177 16.5467 3.02148 16.7284 3.14646 16.8534C3.27144 16.9783 3.45312 17.028 3.62432 16.9841L7.00978 16.1151C7.52234 15.9836 7.99015 15.7168 8.36433 15.3426L16.236 7.47097C17.2596 6.44728 17.2596 4.78755 16.236 3.76386ZM13.236 4.47097C13.8691 3.8378 14.8957 3.8378 15.5288 4.47097C16.162 5.10413 16.162 6.1307 15.5288 6.76386L14.75 7.54269L12.4571 5.24979L13.236 4.47097ZM11.75 5.9569L14.0429 8.24979L7.65722 14.6355C7.40969 14.883 7.10023 15.0595 6.76117 15.1465L4.19447 15.8053L4.85327 13.2386C4.9403 12.8996 5.1168 12.5901 5.36433 12.3426L11.75 5.9569Z" />
    </svg>
  );
};

export default EditIcon;
