import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const RectangleDrawIcon: React.FC<BaseIconProps> = ({
  fill = '#fff',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="3.5" y="4.5" width="13" height="11" stroke={fill} />
      <rect x="15" y="3" width="3" height="3" rx="1.5" fill={fill} />
      <rect x="15" y="14" width="3" height="3" rx="1.5" fill={fill} />
      <rect x="2" y="3" width="3" height="3" rx="1.5" fill={fill} />
      <rect x="2" y="14" width="3" height="3" rx="1.5" fill={fill} />
    </svg>
  );
};
