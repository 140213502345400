import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { HeaderModes } from '@/models/shared.model';
import { LEFT_PANEL_DEFAULT_WIDTH } from '@/shared/constants';

interface SharedState {
  headerMode: HeaderModes;
  leftPanelWidth: number;
}

const initialState: SharedState = {
  headerMode: HeaderModes.Default,
  leftPanelWidth: LEFT_PANEL_DEFAULT_WIDTH,
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setHeaderMode: (state, action: PayloadAction<HeaderModes>) => {
      state.headerMode = action.payload;
    },
    setLeftPanelWidth: (state, action: PayloadAction<number>) => {
      state.leftPanelWidth = action.payload;
    },
  },
});

export const { setHeaderMode, setLeftPanelWidth } = sharedSlice.actions;

export const getHeaderMode = (state: RootState) =>
  state.canvasReducer.shared.headerMode;

export const getLeftPanelWidth = (state: RootState) =>
  state.canvasReducer.shared.leftPanelWidth;
