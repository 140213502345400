import { ThreeEvent } from '@react-three/fiber';
import React from 'react';

export const isRightClick = (
  event: MouseEvent | PointerEvent | ThreeEvent<PointerEvent>
) => event.button === 2;

export const isLeftClick = (
  event: MouseEvent | PointerEvent | ThreeEvent<PointerEvent> | React.MouseEvent
) => event.button === 0;

export const isWithoutClick = (
  event: MouseEvent | PointerEvent | ThreeEvent<PointerEvent>
) => event.buttons === 0;
