import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import {
  SavedWindow,
  WindowOffset,
} from '@/components/WindowCreator/models/konva-model';

interface PlacedWindow {
  id: number;
  // 0,0 is bottom left point.
  attachPosition: WindowOffset;
}
interface WindowPlacementState {
  selectedWindowFromLibrary?: SavedWindow;
  selectedWindowPosition?: { x: number; y: number };
  placedWindows: PlacedWindow[];
}

const initialState: WindowPlacementState = {
  placedWindows: [],
};

export const windowPlacementSlice = createSlice({
  name: 'windowsPlacement',
  initialState,
  reducers: {
    setSelectedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow>
    ) => {
      state.selectedWindowFromLibrary = action.payload;
    },
    addPlacedWindow: (state, { payload }: PayloadAction<PlacedWindow>) => {
      state.placedWindows.push(payload);
    },
    resetWindowPlacementSlice: () => initialState,
    resetSelectedWindowFromLibrary: (state) => {
      state.selectedWindowFromLibrary = undefined;
    },
  },
});

export const {
  setSelectedWindowFromLibrary,
  resetSelectedWindowFromLibrary,
  addPlacedWindow,
  resetWindowPlacementSlice,
} = windowPlacementSlice.actions;

export const getSelectedWindowFromLibrary = (state: RootState) =>
  state.windowsReducer.windowsPlacement.selectedWindowFromLibrary;

export const isWindowFromLibrarySelected = (id: number) => (state: RootState) =>
  state.windowsReducer.windowsPlacement.selectedWindowFromLibrary?.id === id;

export const isSomeWindowFromLibrarySelected = (state: RootState) =>
  !!state.windowsReducer.windowsPlacement.selectedWindowFromLibrary;

export const getPlacedWindows = (state: RootState): PlacedWindow[] =>
  state.windowsReducer.windowsPlacement.placedWindows;
