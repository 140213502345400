import React, { useState } from 'react';
import { IntusInput } from '@/shared/elements';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { InputProps } from 'antd/lib';

const IntusSearch: React.FC<InputProps> = ({
  placeholder = 'Search',
  value,
  ...rest
}) => {
  const [inFocus, setInFocus] = useState(false);
  const filled = !!value;

  return (
    <IntusInput
      placeholder={placeholder}
      value={value}
      allowClear={{ clearIcon: <CloseOutlined /> }}
      prefix={
        <SearchOutlined
          className={`${inFocus ? 'text-light-green-100' : 'text-black'}
          transition duration-[0.2s]
          `}
        />
      }
      onFocus={() => setInFocus(true)}
      onBlur={() => setInFocus(false)}
      style={{
        borderColor: filled ? '#000' : '',
      }}
      {...rest}
    />
  );
};

export default IntusSearch;
