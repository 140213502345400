import React from 'react';

const ThreeDIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.89551 14.6377V13.4277C2.56543 13.9564 3.32194 14.2207 4.16504 14.2207C4.84863 14.2207 5.39323 14.0498 5.79883 13.708C6.20898 13.3662 6.41406 12.9059 6.41406 12.3271C6.41406 11.0465 5.50488 10.4062 3.68652 10.4062H2.87305V9.45605H3.64551C5.26335 9.45605 6.07227 8.85449 6.07227 7.65137C6.07227 6.53939 5.45475 5.9834 4.21973 5.9834C3.53158 5.9834 2.88216 6.2181 2.27148 6.6875V5.59375C2.90039 5.22005 3.64323 5.0332 4.5 5.0332C5.32487 5.0332 5.9834 5.25195 6.47559 5.68945C6.97233 6.12695 7.2207 6.6875 7.2207 7.37109C7.2207 8.66081 6.56445 9.49023 5.25195 9.85938V9.88672C5.95833 9.95508 6.51888 10.2035 6.93359 10.6318C7.35286 11.0557 7.5625 11.5866 7.5625 12.2246C7.5625 13.1042 7.24577 13.8151 6.6123 14.3574C5.9834 14.8952 5.15169 15.1641 4.11719 15.1641C3.1875 15.1641 2.44694 14.9886 1.89551 14.6377ZM9.83203 15V5.19727H12.5391C15.9935 5.19727 17.7207 6.79004 17.7207 9.97559C17.7207 11.4886 17.2399 12.7054 16.2783 13.626C15.3213 14.542 14.0384 15 12.4297 15H9.83203ZM10.9805 6.23633V13.9609H12.4434C13.7285 13.9609 14.7288 13.6169 15.4443 12.9287C16.1598 12.2406 16.5176 11.2653 16.5176 10.0029C16.5176 7.49186 15.1823 6.23633 12.5117 6.23633H10.9805Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ThreeDIcon;
