import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { FlatVector3 } from '@/models';

interface CanvasMapState {
  uuid: string;
  defaultMapImage?: string;
  satelliteMapImage?: string;
  multiplyRate: number;
  constructionSiteCanvasCoordinates?: FlatVector3[];
  constructionSiteCanvasCenter?: FlatVector3;
}

const initialState: CanvasMapState = {
  uuid: '',
  multiplyRate: 0,
};

export const canvasMapSlice = createSlice({
  name: 'canvasMap',
  initialState,
  reducers: {
    setMapUUID: (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
    },
    setDefaultMapImage: (state, action: PayloadAction<string>) => {
      state.defaultMapImage = action.payload;
    },
    setSatelliteMapImage: (state, action: PayloadAction<string>) => {
      state.satelliteMapImage = action.payload;
    },
    setMultiplyRate: (state, action: PayloadAction<number>) => {
      state.multiplyRate = action.payload;
    },
    setConstructionSiteCanvasCoordinates: (
      state,
      action: PayloadAction<FlatVector3[]>
    ) => {
      state.constructionSiteCanvasCoordinates = action.payload;
    },

    setConstructionSiteCanvasCenter: (
      state,
      action: PayloadAction<FlatVector3>
    ) => {
      state.constructionSiteCanvasCenter = action.payload;
    },
    resetCanvasMapData: () => initialState,
  },
});

export const {
  setMapUUID,
  setSatelliteMapImage,
  setDefaultMapImage,
  setMultiplyRate,
  resetCanvasMapData,
  setConstructionSiteCanvasCoordinates,
  setConstructionSiteCanvasCenter,
} = canvasMapSlice.actions;

export const getMapUUID = (state: RootState) =>
  state.canvasReducer.canvasMap.uuid;

export const getMultiplyRate = (state: RootState) =>
  state.canvasReducer.canvasMap.multiplyRate;

export const getDefaultMapImage = (state: RootState) =>
  state.canvasReducer.canvasMap.defaultMapImage;
export const getSatelliteMapImage = (state: RootState) =>
  state.canvasReducer.canvasMap.satelliteMapImage;

export const getConstructionSiteCenter = (state: RootState) =>
  state.canvasReducer.canvasMap.constructionSiteCanvasCenter;

export const getConstructionSiteCanvasCoordinates = (state: RootState) =>
  state.canvasReducer.canvasMap.constructionSiteCanvasCoordinates;

export const getIsMapLoaded = (state: RootState) =>
  !!getDefaultMapImage(state) || !!getSatelliteMapImage(state);
