import React from 'react';
import { DotIcon, Checkmark } from '@/shared/icons';

interface CheckItemProps {
  isChecked?: boolean;
  text: string;
}

const CheckItem: React.FC<CheckItemProps> = ({ isChecked, text }) => {
  return (
    <div
      className={`text-xs/[20px] flex items-center transition-all
      ${isChecked ? 'text-black' : 'text-dark-gray-60'} 
      `}
    >
      <span className={'mr-1 flex items-center'}>
        {isChecked ? <Checkmark /> : <DotIcon />}
      </span>
      {text}
    </div>
  );
};

export default CheckItem;
