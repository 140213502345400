import React from 'react';
import logo from '@/images/logo-small.svg';
import Account from '@/components/Account';

const Header = () => {
  return (
    <div
      id="header"
      className="flex items-center bg-white border-0 border-b border-b-light-gray-20 border-solid h-12"
    >
      <div className="bg-light-green-100 w-12 h-12 flex justify-center items-center">
        <img src={logo} alt="logo" />
      </div>
      <span className="uppercase text-black text-base ml-4">web designer</span>
      <div id="header-actions" className="ml-auto mr-2.5">
        <Account />
      </div>
    </div>
  );
};

export default Header;
