import React from 'react';

export const MirrorWindowIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.1356C1 14.5595 1.49443 14.7911 1.82009 14.5197L6.33095 10.7607C6.57175 10.56 6.57065 10.1898 6.32866 9.99056L1.81781 6.27662C1.49161 6.00805 1 6.24009 1 6.66262L1 14.1356Z"
        fill="#414042"
      />
      <path
        d="M10.1506 10.0453C9.91188 10.2467 9.91406 10.6151 10.1551 10.8136L14.6688 14.5298C14.9949 14.7984 15.4866 14.5663 15.4866 14.1438L15.4866 6.62108C15.4866 6.19578 14.9893 5.96467 14.6642 6.23886L10.1506 10.0453ZM14.5208 13.1557L11.1985 10.4225L14.5208 7.52519L14.5208 13.1557Z"
        fill="#414042"
      />
      <path
        d="M8.24339 8.10156C7.97669 8.10156 7.7605 7.88537 7.7605 7.61867L7.7605 5.48445C7.7605 5.21776 7.97669 5.00156 8.24339 5.00156C8.51008 5.00156 8.72628 5.21776 8.72628 5.48445L8.72628 7.61867C8.72628 7.88537 8.51008 8.10156 8.24339 8.10156Z"
        fill="#414042"
      />
      <path
        d="M8.24339 12.1016C7.97669 12.1016 7.7605 11.8854 7.7605 11.6187L7.7605 9.48445C7.7605 9.21776 7.97669 9.00156 8.24339 9.00156C8.51008 9.00156 8.72628 9.21776 8.72628 9.48445L8.72628 11.6187C8.72628 11.8854 8.51008 12.1016 8.24339 12.1016Z"
        fill="#414042"
      />
      <path
        d="M8.24339 16.1016C7.97669 16.1016 7.7605 15.8854 7.7605 15.6187L7.7605 13.4845C7.7605 13.2178 7.97669 13.0016 8.24339 13.0016C8.51008 13.0016 8.72628 13.2178 8.72628 13.4845L8.72628 15.6187C8.72628 15.8854 8.51008 16.1016 8.24339 16.1016Z"
        fill="#414042"
      />
      <path
        d="M8.21557 0.969853C9.64975 1.02683 11.2415 1.91381 12.0556 2.97326L11.1059 2.97326C10.8591 2.97326 10.659 3.17338 10.659 3.42023C10.659 3.66708 10.8591 3.86719 11.1059 3.86719L13.3555 3.86719C13.4664 3.86719 13.5563 3.77728 13.5563 3.66638L13.5563 1.41017C13.5563 1.16699 13.3592 0.969853 13.116 0.969853C12.8728 0.969853 12.6757 1.16699 12.6757 1.41017L12.6757 2.21298C11.68 0.964231 9.91727 0.0542957 8.22329 0.00407532L8.21557 0.00407532C6.36074 0.0859975 4.73827 1.02095 3.72284 2.42363C3.57692 2.62521 3.63202 2.9031 3.83202 3.05118C4.05929 3.21944 4.38069 3.15388 4.55104 2.92818C5.40497 1.79679 6.71602 1.05333 8.20301 0.97082L8.21557 0.969853Z"
        fill="#414042"
      />
    </svg>
  );
};
