import React from 'react';

export const RotateIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 12.2241C14.2865 12.0787 15.1269 12 16 12C20.4183 12 24 14.0147 24 16.5C24 17.6525 23.2297 18.7039 21.963 19.5"
        stroke="#414042"
        strokeLinecap="round"
      />
      <path
        d="M19.5 20.5482C18.4428 20.838 17.2552 21.0006 16 21.0006C11.5817 21.0006 8 18.9859 8 16.5006C8 15.2388 8.92319 14.0984 10.4103 13.2812"
        stroke="#414042"
        strokeLinecap="round"
      />
      <path
        d="M18.4998 21.1872L17.5231 22.484C17.3569 22.7046 17.4011 23.018 17.6217 23.1842C17.8422 23.3503 18.1557 23.3062 18.3219 23.0856L20.0235 20.8263C20.1896 20.6057 20.1455 20.2922 19.9249 20.1261L17.6656 18.4245C17.445 18.2583 17.1316 18.3025 16.9654 18.523C16.7993 18.7436 16.8434 19.0571 17.064 19.2232L18.3574 20.1974L18.4998 21.1872Z"
        fill="#414042"
      />
      <path
        d="M13.968 11.7112L14.9447 10.4145C15.1108 10.1939 15.0667 9.8804 14.8461 9.71426C14.6255 9.54813 14.312 9.59226 14.1459 9.81284L12.4443 12.0721C12.2781 12.2927 12.3223 12.6062 12.5429 12.7723L14.8022 14.474C15.0227 14.6401 15.3362 14.596 15.5024 14.3754C15.6685 14.1548 15.6243 13.8413 15.4038 13.6752L14.1104 12.7011L13.968 11.7112Z"
        fill="#414042"
      />
    </svg>
  );
};
