import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getEditMode, setMode } from '@/store/slices/canvasModesSlice';
import { hideSelectionBoxArea } from '@/shared/helpers';
import { isRightClick } from '@/shared/helpers';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { resetExternalElementsState } from '@/store/slices/canvasExternalElementsSlice';
import { CanvasActionsModes, EditModes, NodeType } from '@/models';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { getEditedNode } from '@/store/slices/canvasBuildingSlice';

const CanvasEditActionHandlers = () => {
  const editedNode = useAppSelector(getEditedNode);
  const editMode = useAppSelector(getEditMode);
  const dispatch = useAppDispatch();

  const { addNodesToSelectedNodes } = useSelectedNodes();

  const handlePointerDown = (event: PointerEvent) => {
    hideSelectionBoxArea();
    if (isRightClick(event)) return;

    dispatch(setMode(CanvasActionsModes.selection));
    if (
      editedNode &&
      editedNode.type !== NodeType.Wall &&
      editMode === EditModes.Split
    ) {
      addNodesToSelectedNodes([editedNode]);
    }
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    editMode !== EditModes.Unset &&
      canvas?.addEventListener('pointerdown', handlePointerDown);
    return () => {
      canvas?.removeEventListener('pointerdown', handlePointerDown);
      dispatch(resetExternalElementsState());
    };
  }, []);

  return <></>;
};

export default CanvasEditActionHandlers;
