import { User } from '@auth0/auth0-react';

export const getUserName = (user: User) =>
  user?.name
    ? getMicrosoftUserName(user)
    : `${user?.given_name} ${user?.family_name?.[0]}.`;

const getMicrosoftUserName = (user: User) => {
  const names = user?.name?.split(' ');
  const firstName = names?.[0];
  const lastName = names?.[names?.length - 1];
  return `${firstName} ${lastName?.charAt(0).toUpperCase()}.`;
};
