import React from 'react';
import { Line } from 'react-konva';

import {
  generateFramePoints,
  OUTER_BORDER_THICKNESS,
} from '@/components/WindowCreator/elements/creator-windows.helpers';
import { FlatVector2 } from '@/models';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getHexByName } from '../helpers/config';

interface WindowBorderProps {
  points: FlatVector2[];
  idx: number;
  borderWidth?: number;
  color?: string;
}

export const WindowBorder = ({
  points,
  idx,
  borderWidth = OUTER_BORDER_THICKNESS,
  color,
}: WindowBorderProps) => {
  const configColors = useFetchWindowConfigQuery().data!.colors;
  const finalPoints = generateFramePoints(points, idx, borderWidth);

  return (
    <Line
      points={finalPoints}
      closed
      fill={color ? getHexByName(configColors, color) : '#F0EEE6'}
      stroke="#8D8B8F"
    />
  );
};
