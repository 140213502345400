import React from 'react';

const CameraIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <rect width="44" height="44" rx="6" fill="none" />
      <path
        d="M12.5 16C11.1193 16 10 17.1193 10 18.5V25.5C10 26.8807 11.1193 28 12.5 28H19.5C20.8807 28 22 26.8807 22 25.5V24.5L24.4 26.3C25.0592 26.7944 26 26.324 26 25.5V18.5C26 17.6759 25.0592 17.2056 24.4 17.7L22 19.5V18.5C22 17.1193 20.8807 16 19.5 16H12.5ZM22 20.75L25 18.5V25.5L22 23.25V20.75ZM21 18.5V25.5C21 26.3284 20.3284 27 19.5 27H12.5C11.6716 27 11 26.3284 11 25.5V18.5C11 17.6716 11.6716 17 12.5 17H19.5C20.3284 17 21 17.6716 21 18.5Z"
        fill="#242424"
      />
      <path
        d="M32.1444 23.5922L34.71 20.6172C34.8054 20.5063 34.7194 20.3438 34.5655 20.3438H29.4343C29.2804 20.3438 29.1944 20.5063 29.2897 20.6172L31.8554 23.5922C31.9288 23.6773 32.071 23.6773 32.1444 23.5922Z"
        fill="black"
      />
    </svg>
  );
};

export default CameraIcon;
