import React from 'react';
import { Group } from 'react-konva';
import { FlatVector2 } from '@/models';
import { Html } from 'react-konva-utils';
import { IntusTooltip } from '@/shared/elements';
import { useAppDispatch } from '@/store/hooks';
import {
  resetHoverArea,
  setHoverArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';

const ERROR_SIZE = 36;

const WindowError = ({
  position,
  errorList,
  hoverArea,
}: {
  position: FlatVector2;
  errorList: string[];
  hoverArea: FlatVector2[][];
}) => {
  const dispatch = useAppDispatch();

  const errorsText = (
    <div className={'text-sm'}>
      {errorList.map((error) => (
        <div className={'p-0 m-0 flex items-center'} key={error}>
          <div
            className={'w-1 h-1 bg-red-80 rounded-full ml-2 mr-3 leading-5'}
          />
          {error}
        </div>
      ))}
    </div>
  );

  return (
    <Group x={position[0] - ERROR_SIZE} y={position[1]}>
      <Html>
        <IntusTooltip
          title={errorsText}
          placement={'right'}
          overlayClassName={'white'}
          overlayInnerStyle={{
            background: 'white',
            backgroundColor: 'white',
            color: '#414042',
            padding: 8,
            borderRadius: 4,
          }}
          overlayStyle={{
            maxWidth: '100%',
          }}
        >
          <div
            className={`text-white bg-red-80 hover:bg-red w-[36px] h-[36px] rounded-full border-2 
            border-white border-solid flex items-center justify-center text-3xl select-none`}
            onPointerEnter={() => dispatch(resetHoverArea())}
            onPointerLeave={() => dispatch(setHoverArea(hoverArea))}
          >
            !
          </div>
        </IntusTooltip>
      </Html>
    </Group>
  );
};

export default WindowError;
