import React from 'react';

const SaveScreenIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.34722C2 3.05089 3.05089 2 4.34722 2H5.79167C6.09082 2 6.33333 2.24251 6.33333 2.54167C6.33333 2.84082 6.09082 3.08333 5.79167 3.08333H4.34722C3.6492 3.08333 3.08333 3.6492 3.08333 4.34722V5.79167C3.08333 6.09082 2.84082 6.33333 2.54167 6.33333C2.24251 6.33333 2 6.09082 2 5.79167V4.34722ZM13.6667 2.54167C13.6667 2.24251 13.9092 2 14.2083 2H15.6528C16.9491 2 18 3.05089 18 4.34722V5.79167C18 6.09082 17.7575 6.33333 17.4583 6.33333C17.1592 6.33333 16.9167 6.09082 16.9167 5.79167V4.34722C16.9167 3.6492 16.3508 3.08333 15.6528 3.08333H14.2083C13.9092 3.08333 13.6667 2.84082 13.6667 2.54167ZM2.54167 13.6667C2.84082 13.6667 3.08333 13.9092 3.08333 14.2083V15.6528C3.08333 16.3508 3.6492 16.9167 4.34722 16.9167H5.79167C6.09082 16.9167 6.33333 17.1592 6.33333 17.4583C6.33333 17.7575 6.09082 18 5.79167 18H4.34722C3.05089 18 2 16.9491 2 15.6528V14.2083C2 13.9092 2.24251 13.6667 2.54167 13.6667ZM17.4583 13.6667C17.7575 13.6667 18 13.9092 18 14.2083V15.6528C18 16.9491 16.9491 18 15.6528 18H14.2083C13.9092 18 13.6667 17.7575 13.6667 17.4583C13.6667 17.1592 13.9092 16.9167 14.2083 16.9167H15.6528C16.3508 16.9167 16.9167 16.3508 16.9167 15.6528V14.2083C16.9167 13.9092 17.1592 13.6667 17.4583 13.6667Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.07869 6C8.69992 6 8.35365 6.21011 8.18426 6.54274L7.79399 7.6H7.33333C6.59695 7.6 6 8.07701 6 8.8V11.8909C6 12.6139 6.59695 13.2 7.33333 13.2H12.6667C13.403 13.2 14 12.6139 14 11.8909V8.8C14 8.07701 13.403 7.6 12.6667 7.6H12.206L11.8157 6.54274C11.6463 6.21011 11.3001 6 10.9213 6H9.07869ZM8.4 10C8.4 10.8837 9.11634 11.6 10 11.6C10.8837 11.6 11.6 10.8837 11.6 10C11.6 9.11634 10.8837 8.4 10 8.4C9.11634 8.4 8.4 9.11634 8.4 10ZM10 10.8C10.4418 10.8 10.8 10.4418 10.8 10C10.8 9.55817 10.4418 9.2 10 9.2C9.55817 9.2 9.2 9.55817 9.2 10C9.2 10.4418 9.55817 10.8 10 10.8Z"
        fill="#414042"
      />
    </svg>
  );
};

export default SaveScreenIcon;
