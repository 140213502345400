import React, { useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { GeolibGeoJSONPoint } from 'geolib/es/types';
import {
  convertBufferGeometryTo3DVectorList,
  getBBoxCenter,
  getXYZ,
  GPSRelativePosition,
} from '../project-canvas.helpers';
import { NodeType, ProjectStructure } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setConstructionSiteCanvasCenter,
  setConstructionSiteCanvasCoordinates,
} from '@/store/slices/canvasMapSlice';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import { getSelectedNodeByGUID } from '@/store/slices/canvasBuildingSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';

interface ProjectConstructionSite {
  project: ProjectStructure;
  isAllEnvironmentSelected?: boolean;
  isEnvironmentLocked?: boolean;
}
const ProjectConstructionSite: React.FC<ProjectConstructionSite> = ({
  project,
  isAllEnvironmentSelected,
  isEnvironmentLocked,
}) => {
  const { selectNode } = useSelectedNodes();
  const { environment, boundingBox } = project;
  const { constructionSite } = environment;

  const dispatch = useAppDispatch();

  const { isNodeHovered } = useHoveredNode({ nodeGUID: constructionSite.guid });

  const { setHoveredNode, resetHoveredNode } = useHoveredNode({
    nodeGUID: constructionSite.guid,
    isLocked: isEnvironmentLocked || constructionSite.userData?.isLocked,
  });

  const isCurrentNodeSelected = !!useAppSelector(
    getSelectedNodeByGUID(constructionSite.guid)
  );

  const isSelected = isAllEnvironmentSelected || isCurrentNodeSelected;

  const siteGeometry = useMemo(() => {
    const mapCenter: GeolibGeoJSONPoint = getBBoxCenter(boundingBox);
    const canvasCoordinates = constructionSite.points.map((point) =>
      GPSRelativePosition(point as GeolibGeoJSONPoint, mapCenter)
    );
    const siteShape = new THREE.Shape(
      canvasCoordinates.map((point) => new THREE.Vector2(point[0], point[1]))
    );
    const shapeGeometry = new THREE.ShapeGeometry(siteShape);
    shapeGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(
        canvasCoordinates.map((point) => [point[0], point[1], 0.001]).flat(),
        3
      )
    );
    shapeGeometry.rotateX(Math.PI / 2);
    shapeGeometry.rotateY(Math.PI / 2);
    shapeGeometry.rotateZ(Math.PI);
    shapeGeometry.computeBoundingBox();

    const edgesGeometry = new THREE.EdgesGeometry(shapeGeometry);

    return { shapeGeometry, edgesGeometry };
  }, [boundingBox, constructionSite.points]);

  const siteGeometryParams = useMemo(
    () => ({
      transparent: true,
      side: THREE.DoubleSide,
      opacity: isSelected ? 0.8 : 0.05,
      color: new THREE.Color(isSelected ? '#B6C8FF' : '#FFCC02'),
      borderColor: new THREE.Color(
        isSelected || isNodeHovered ? '#0094FF' : '#B6C8FF'
      ),
      toneMapped: false,
    }),
    [isSelected, isNodeHovered]
  );

  useEffect(() => {
    const siteCenter = new THREE.Vector3();
    siteGeometry.shapeGeometry.boundingBox?.getCenter(siteCenter);

    dispatch(
      setConstructionSiteCanvasCoordinates(
        convertBufferGeometryTo3DVectorList(
          siteGeometry.shapeGeometry,
          siteGeometry.shapeGeometry.getAttribute('position').count
        ).map((v) => getXYZ(v))
      )
    );
    dispatch(setConstructionSiteCanvasCenter(getXYZ(siteCenter)));
  }, [siteGeometry]);

  if (constructionSite.userData?.isHidden) return null;

  return (
    <>
      <mesh
        geometry={siteGeometry.shapeGeometry}
        onPointerDown={(event) =>
          selectNode({
            event,
            type: NodeType.ConstructionSite,
            guid: constructionSite.guid,
            isLocked:
              isEnvironmentLocked || !!constructionSite.userData?.isLocked,
            isSelected: isSelected,
          })
        }
        onPointerEnter={setHoveredNode}
        onPointerLeave={resetHoveredNode}
        userData={{
          guid: constructionSite.guid,
          nodeType: NodeType.ConstructionSite,
        }}
      >
        <meshBasicMaterial {...siteGeometryParams} />
      </mesh>
      <lineSegments geometry={siteGeometry.edgesGeometry}>
        <lineBasicMaterial color={siteGeometryParams.borderColor} />
      </lineSegments>
    </>
  );
};

export default ProjectConstructionSite;
