import { useEffect, useState } from 'react';

const MINIMAL_SUPPORTED_RESOLUTION = 1280;

export const useMinimalScreenResolution = () => {
  const isWidthNotSupported = () =>
    window.innerWidth < MINIMAL_SUPPORTED_RESOLUTION;

  const [showScreenResolutionError, setShowScreenResolutionError] = useState(
    isWidthNotSupported()
  );

  const handleResize = () => {
    setShowScreenResolutionError(isWidthNotSupported());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    showScreenResolutionError,
  };
};
