import React from 'react';
import { BaseIconProps } from '@/models';

// While this property does not usual appear for icon, it is provided by Collapse Element, where this icon is used.
// Do not expect to see this property for other components (isActive)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const ExpandIcon: React.FC<BaseIconProps> = ({ isActive }) => {
  const styles = { transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)' };
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <path
        d="M9.59219 11.8556L6.61719 9.28996C6.50625 9.19464 6.34375 9.28058 6.34375 9.43449V14.5657C6.34375 14.7196 6.50625 14.8056 6.61719 14.7103L9.59219 12.1446C9.67734 12.0712 9.67734 11.929 9.59219 11.8556Z"
        fill="#808285"
      />
    </svg>
  );
};
