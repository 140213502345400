import React, { useState } from 'react';
import { Space } from 'antd/lib';
import UserSettings from '@/components/UserSettings';
import { Avatar, IntusDropdown } from '@/shared/elements';
import { useAuth0 } from '@auth0/auth0-react';
import { SettingOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { LogOutIcon, VectorDownIcon } from '@/shared/icons';
import { getUserName } from '@/shared/helpers/user-data';

const menuItems: MenuProps['items'] = [
  {
    label: 'Settings',
    key: 'settings',
    icon: <SettingOutlined />,
  },
  {
    label: 'Log out',
    key: 'logout',
    icon: <LogOutIcon className={'ant-dropdown-menu-item-icon'} />,
  },
];

const Account = () => {
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);

  const { logout, user } = useAuth0();

  const onMenuClick = ({ key }: { key: string }) => {
    switch (key) {
      case 'settings':
        setIsSettingsOpened(true);
        break;
      case 'logout':
        logout({
          logoutParams: { returnTo: `${window.location.origin}` },
        });
        break;
    }
  };

  return (
    <>
      <div className="flex items-center group">
        <div className="text-black mr-2.5 cursor-pointer">
          <IntusDropdown
            customMenuProps={{
              items: menuItems,
              onClick: onMenuClick,
            }}
            menuType="simplified"
          >
            <Space size={'small'}>
              <Avatar
                imgSrc={user?.picture}
                initial={getUserName(user!).at(0)}
              />
              <div className={'max-w-[150px] truncate'}>
                {getUserName(user!)}
              </div>
              <VectorDownIcon
                stroke={'#000'}
                className={
                  'mt-[3px] transition-all duration-100 group-hover:mt-[6px]'
                }
              />
            </Space>
          </IntusDropdown>
        </div>
      </div>
      <UserSettings isOpen={isSettingsOpened} setIsOpen={setIsSettingsOpened} />
    </>
  );
};
export default Account;
