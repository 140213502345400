import React, { useState, useEffect, useRef } from 'react';
import { Form, InputRef } from 'antd';
import { InputProps } from 'antd/lib';

import IntusInput from '../Input/Input';
import {
  addSpacesToThousands,
  removeSpacesFromThousands,
  sanitizeInputMetricValue,
} from '@/shared/helpers/format-data';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { UnitSystemTypes } from '@/models';
import { useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';
import { limitsValidator } from '@/shared/form/validators';
import './EditableMetric.scss';

interface EditableMetricProps {
  onEdit: (value: string) => void;
  onSubmit: () => void;
  value: string;
  inputProps?: InputProps;
  min?: number;
  max?: number;
  staticValue?: boolean;
}

export const EditableMetric: React.FC<EditableMetricProps> = ({
  onEdit,
  onSubmit,
  value,
  min,
  max,
  inputProps = {},
  staticValue,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [originalValue, setOriginalValue] = useState(value);
  const [form] = Form.useForm<{ name: string }>();
  const inputRef = useRef<InputRef>(null);
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const handleEditMetric = () => {
    form
      .validateFields()
      .then(() => {
        onEdit(form.getFieldValue('name'));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateAndBlur = () => {
    form
      .validateFields()
      .then(() => {
        setIsEditing(false);
        onSubmit();
        if (!inputRef.current) return;
        inputRef.current.blur();
      })
      .then(() => form.setFieldValue('name', value))
      .catch((e) => {
        console.log(e);
      });
  };

  const handleResetInput = () => {
    if (!inputRef.current) return;
    setIsEditing(false);
    inputRef.current.blur();
    onEdit(originalValue);
    form.setFieldsValue({ name: originalValue });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      validateAndBlur();
    }
    if (event.key === 'Escape') {
      handleResetInput();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current?.input &&
      !inputRef.current.input?.contains(event.target as Node)
    ) {
      handleResetInput();
    }
  };

  const handleMouseLeave = () => {
    if (inputRef.current?.input !== document.activeElement) {
      setIsEditing(false);
    }
  };

  const handleMouseMove = () => {
    if (!isEditing) {
      setOriginalValue(form.getFieldValue('name'));
      setIsEditing(true);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ name: value?.toString() });
  }, [isImperialUnits]);

  useEffect(() => {
    if (staticValue) {
      form.setFieldsValue({ name: value?.toString() });
    }
  }, [value]);

  useEffect(() => {
    isEditing &&
      inputRef.current?.input?.addEventListener('keydown', handleKeyDown);
    isEditing && document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      inputRef.current?.input?.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, value, handleClickOutside]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.blur();
    }
  }, [isEditing]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValueWithoutSpaces = sanitizeInputMetricValue(
      removeSpacesFromThousands(event.target.value, isImperialUnits),
      isImperialUnits,
      1,
      staticValue
    );
    form.setFieldsValue({
      name: addSpacesToThousands(
        addSpacesToThousands(sanitizedValueWithoutSpaces, isImperialUnits),
        isImperialUnits
      ),
    });
    handleEditMetric();
  };

  return (
    <Form form={form} initialValues={{ name: value }} className="max-w-full">
      <Form.Item
        name="name"
        className="custom-form-item mb-0"
        rules={
          min && max
            ? [
                limitsValidator({
                  min,
                  max,
                  isImperialUnits,
                  staticValue,
                  customValidationMessage: null,
                }),
              ]
            : []
        }
      >
        <IntusInput
          value={value}
          ref={inputRef}
          size="small"
          autoComplete="off"
          className={isEditing ? '' : 'transparent-border'}
          onChange={handleChange}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          {...inputProps}
        />
      </Form.Item>
    </Form>
  );
};
