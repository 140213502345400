import React from 'react';

const TreeText = () => {
  return (
    <>
      <div className="one-window-container">
        <svg
          width="234"
          height="26"
          viewBox="0 0 234 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.5755 15.7274V1.58984H47.9458V24.3731H45.2362L32.9994 9.03287V24.3731H28.6292V1.62348H32.1953L43.5755 15.7274Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M69.3515 20.1527V24.3578H51.9229V1.625H69.3515V5.8301H56.2407V11.0612H68.8446V15.014H56.2844V20.1527H69.3515Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M114.506 1.46484L106.027 24.4331H102.269L99.3407 17.2087L96.8497 9.94233L94.3936 17.2676L91.5354 24.4331H87.7682L79.1238 1.46484H84.0972L89.6474 18.0582L95.3725 1.46484H98.2569L104.148 18.0582L109.532 1.46484H114.506Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M121.673 24.4331H117.302V1.46484H121.673V24.4331Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M141.312 15.727V1.42969H145.753V24.4316H142.991L130.614 8.95682V24.4316H126.174V1.46333H129.775L141.312 15.727Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M158.768 1.46317C160.565 1.43066 162.35 1.75414 164.012 2.41352C165.435 2.9799 166.696 3.86511 167.692 4.99545C168.613 6.06631 169.332 7.2847 169.816 8.59502C170.307 9.94682 170.561 11.368 170.567 12.8001C170.599 14.255 170.378 15.7049 169.912 17.0893C169.462 18.4462 168.757 19.712 167.831 20.8234C166.828 21.9934 165.544 22.9112 164.091 23.4979C162.406 24.1708 160.591 24.4891 158.768 24.4314H149.52V1.46317H158.768ZM153.925 5.56734V20.2936H158.768C161.261 20.2936 163.123 19.5535 164.353 18.0733C165.587 16.5547 166.207 14.6577 166.101 12.7328C166.098 10.8839 165.413 9.09584 164.169 7.68672C163.482 6.97152 162.637 6.41311 161.696 6.05226C160.755 5.69141 159.743 5.53723 158.733 5.60099L153.925 5.56734Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M185.479 1.00374C187.843 0.945457 190.172 1.56934 192.165 2.79511C193.956 3.88471 195.387 5.4425 196.291 7.28616C197.162 9.10674 197.592 11.0938 197.55 13.0976C197.537 14.591 197.278 16.073 196.78 17.4877C196.295 18.8622 195.561 20.1434 194.613 21.2723C193.584 22.4431 192.289 23.3698 190.828 23.9804C189.141 24.6825 187.317 25.0294 185.479 24.998C183.639 25.0277 181.812 24.6927 180.112 24.0141C178.634 23.4268 177.324 22.5067 176.292 21.3312C175.336 20.2081 174.596 18.9292 174.107 17.555C173.61 16.1295 173.359 14.635 173.364 13.1313C173.36 11.6111 173.623 10.1011 174.142 8.66543C174.645 7.26233 175.396 5.95347 176.362 4.79674C177.39 3.59864 178.699 2.65308 180.182 2.0382C181.85 1.33144 183.656 0.978621 185.479 1.00374ZM185.479 4.94813C183.889 4.90356 182.326 5.3517 181.021 6.22648C179.854 6.99153 178.942 8.06687 178.399 9.32143C177.884 10.5517 177.649 11.8736 177.708 13.1985C177.721 15.2028 178.425 17.1463 179.71 18.724C180.977 20.2547 182.883 21.02 185.444 21.02C188.005 21.02 189.91 20.2463 191.178 18.6988C192.459 17.1185 193.152 15.1695 193.144 13.1649C193.17 12.1751 193.035 11.1875 192.742 10.2381C192.458 9.3031 192.021 8.41741 191.449 7.61416C190.821 6.77899 189.982 6.11291 189.01 5.67981C187.906 5.18113 186.698 4.9309 185.479 4.94813Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M13.106 1.00357C15.4732 0.946687 17.8043 1.57039 19.8012 2.79494C21.5899 3.88667 23.0204 5.44387 23.9268 7.28599C24.7907 9.10883 25.2207 11.0943 25.1854 13.0974C25.1701 14.5913 24.9075 16.0732 24.4075 17.4876C23.9257 18.8636 23.1916 20.1454 22.2399 21.2722C21.2153 22.4467 19.9191 23.3742 18.4552 23.9802C16.7678 24.6823 14.944 25.0293 13.106 24.9979C11.2659 25.0268 9.43931 24.6919 7.73926 24.0139C6.26374 23.4266 4.95668 22.5064 3.92837 21.331C2.96507 20.2122 2.22432 18.9321 1.74323 17.5548C1.24147 16.1303 0.990373 14.6352 1.00028 13.1311C0.992746 11.6114 1.25281 10.1014 1.76945 8.66527C2.27208 7.26216 3.02319 5.9533 3.98955 4.79657C5.01663 3.6014 6.3223 2.65627 7.80044 2.03803C9.47105 1.33008 11.2807 0.97724 13.106 1.00357ZM13.106 4.94796C11.6109 4.90016 10.1361 5.29029 8.87652 6.06671C7.61698 6.84313 6.63214 7.96924 6.05233 9.29603C5.54191 10.5274 5.30664 11.8485 5.36182 13.1731C5.37859 15.1768 6.08218 17.1191 7.36341 18.6986C8.63371 20.2293 10.545 20.9946 13.0972 20.9946C15.6582 20.9946 17.5637 20.2209 18.831 18.6734C20.1118 17.092 20.807 15.1444 20.8064 13.1395C20.8295 12.1492 20.6909 11.1616 20.3956 10.2127C20.1045 9.28358 19.6587 8.40595 19.0758 7.61399C18.4486 6.77882 17.6088 6.11274 16.6372 5.67964C15.5334 5.18011 14.3256 4.92985 13.106 4.94796Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M233.78 1.46484L225.301 24.4331H221.534L218.606 17.2087L216.115 9.94233L213.659 17.2676L210.801 24.4331H207.034L198.389 1.46484H203.371L208.922 18.0582L214.638 1.46484H217.575L223.457 18.0582L228.798 1.46484H233.78Z"
            fill="#898789"
            stroke="#898789"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="one-tree-container">
        <svg
          width="163"
          height="26"
          viewBox="0 0 163 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.5141 15.7112V1.58984H47.8768V24.3418H45.1719L32.9564 9.0283V24.3418H28.5938V1.62343H32.1537L43.5141 15.7112Z"
            fill="#6DB642"
            stroke="#6DB642"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M69.245 20.1204V24.3182H51.8467V1.625H69.245V5.74722H56.157V10.9693H68.7389V14.9823H56.2006V20.112L69.245 20.1204Z"
            fill="#6DB642"
            stroke="#6DB642"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M13.0976 1.00357C15.4607 0.94678 17.7877 1.5694 19.7812 2.79182C21.5668 3.88165 22.9947 5.43613 23.8995 7.27504C24.76 9.09533 25.1892 11.0769 25.156 13.0764C25.1454 14.5817 24.8832 16.0756 24.3794 17.5008C23.8985 18.8744 23.1656 20.154 22.2156 21.2788C21.195 22.4536 19.9005 23.3799 18.4375 23.9822C16.7531 24.683 14.9324 25.0294 13.0976 24.9981C11.2607 25.0269 9.4373 24.6926 7.74022 24.0158C6.26557 23.4324 4.96011 22.5134 3.93597 21.3376C2.96683 20.2006 2.22703 18.8993 1.75463 17.5008C0.748456 14.622 0.748456 11.5055 1.75463 8.62673C2.25454 7.22784 3.00456 5.92372 3.97087 4.77317C5.00847 3.586 6.3202 2.64874 7.80129 2.03622C9.46899 1.32951 11.2755 0.977279 13.0976 1.00357ZM13.0976 4.94108C11.6031 4.90009 10.1308 5.29515 8.87402 6.07434C7.61728 6.85353 6.63497 7.98038 6.05622 9.30677C5.5467 10.536 5.31183 11.8547 5.36692 13.1771C5.38236 15.1775 6.08486 17.1168 7.36503 18.693C8.63311 20.221 10.541 20.985 13.0889 20.985C15.6454 20.985 17.5475 20.221 18.8127 18.6762C20.0912 17.0944 20.7851 15.1475 20.7846 13.1435C20.8076 12.1578 20.6692 11.1746 20.3745 10.2303C20.0883 9.28912 19.64 8.40085 19.0483 7.60247C18.4227 6.76619 17.5845 6.09843 16.6139 5.6631C15.5168 5.157 14.3142 4.89856 13.0976 4.9075V4.94108Z"
            fill="#6DB642"
            stroke="#6DB642"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M85.5877 24.3769V5.52893H78.2061V1.625H97.4018V5.52893H90.0114V24.3769H85.5877Z"
            fill="#6DB642"
            stroke="#6DB642"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M120.672 24.0823V24.3761H115.437L108.649 16.7697H104.426V24.3761H100.063V1.59059H105.621C108.082 1.59059 109.914 1.59059 111.135 1.59059C112.264 1.54035 113.391 1.71169 114.449 2.09418C115.507 2.47667 116.472 3.06231 117.287 3.81541C118.008 4.5187 118.575 5.35483 118.953 6.27375C119.331 7.19267 119.512 8.17549 119.486 9.16338C119.518 10.7875 119.03 12.3818 118.09 13.7306C117.006 15.1304 115.39 16.0602 113.596 16.3164L120.672 24.0823ZM111.127 5.61206H104.443V12.891H111.127C111.65 12.9329 112.176 12.8699 112.673 12.7061C113.17 12.5422 113.625 12.281 114.012 11.9389C114.398 11.5969 114.706 11.1814 114.917 10.7189C115.128 10.2563 115.237 9.75667 115.237 9.25153C115.237 8.7464 115.128 8.24675 114.917 7.78418C114.706 7.32161 114.398 6.90618 114.012 6.56413C113.625 6.22209 113.17 5.96087 112.673 5.79699C112.176 5.63312 111.65 5.57015 111.127 5.61206Z"
            fill="#6DB642"
            stroke="#6DB642"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M141.168 20.1456V24.3434H123.717V1.625H141.168V5.82277H128.08V11.0532H140.688V14.9823H128.097V20.112L141.168 20.1456Z"
            fill="#6DB642"
            stroke="#6DB642"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
          <path
            d="M162.327 20.1456V24.3434H144.876V1.625H162.327V5.82277H149.239V11.0532H161.838V14.9823H149.257V20.112L162.327 20.1456Z"
            fill="#6DB642"
            stroke="#6DB642"
            strokeWidth="0.1141"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </>
  );
};

export default TreeText;
