import { Scene } from 'three';

export const findObjectByUserData = (scene: Scene, property: string) => {
  let foundObject = null;
  scene.traverse((object) => {
    if (object.userData[property]) {
      foundObject = object;
    }
  });
  return foundObject;
};
