import React, { BaseSyntheticEvent, useEffect, useMemo, useRef } from 'react';
import { ContextAction, ContextMenu, IntusTooltip } from '@/shared/elements';
import {
  CustomError,
  NodeType,
  PanelItemProps,
  SelectedNodeSource,
} from '@/models';
import LockIcon from './icons/LockedIcon.svg';
import UnlockIcon from './icons/UnlocedIcon.svg';
import VisibleIcon from './icons/VisibleIcon.svg';
import InvisibleIcon from './icons/InvisibleIcon.svg';
import DotIcon from './icons/Dot.svg';
import BrowserPanelIconButton from './BrowserPanelIconButton';
import EditableField from '@/shared/elements/EditableField';
import { ContextActionTypes } from '@/shared/elements/ContextMenu/ContextMenu';
import { Form } from 'antd';
import { setFormErrorFromServer } from '@/shared/form/error-handlers';
import { requiredValidator } from '@/shared/form/validators';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import { useAppSelector } from '@/store/hooks';
import {
  getIsNodeIsolated,
  getSelectedNodeByGUID,
} from '@/store/slices/canvasBuildingSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';

interface BrowserPanelProps extends PanelItemProps {
  elementGUID: string;
  nodeType: NodeType;
  text: string;
  deleteAction?: () => void;
  showHideAction?: () => void;
  isolateEndIsolateAction?: () => void;
  lockUnlockAction?: () => void;
  renameAction?: (name: string) => void;
  isLocked: boolean;
  isHidden: boolean;
  isLastChildItem?: boolean;
}

const APPROXIMATE_OPENING_TIME_OF_COLLAPSE = 500;

const BrowserPanelItem: React.FC<BrowserPanelProps> = ({
  elementGUID,
  nodeType,
  text,
  deleteAction,
  showHideAction,
  lockUnlockAction,
  renameAction,
  isParentSelected,
  isParentLocked,
  isParentHidden,
  depthLevel,
  isLastChildItem,
  isLocked,
  isHidden,
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [form] = Form.useForm<{ name: string }>();
  const { setHoveredNode, resetHoveredNode } = useHoveredNode({
    nodeGUID: elementGUID,
    isLocked: isLocked || isParentLocked,
  });
  const { selectNode } = useSelectedNodes();

  const { handleIsolateNode, allowedTypesForIsolation, isIsolateModeEnabled } =
    useIsolationHandlers();

  const isNodeIsolated = useAppSelector(getIsNodeIsolated(elementGUID));

  const [inEditMode, setInEditMode] = React.useState(false);

  const selectedNode = useAppSelector(getSelectedNodeByGUID(elementGUID));
  const isElementSelected = !!selectedNode;

  const contextActions = useMemo(() => {
    const actions: ContextAction[] = [];

    if (renameAction) {
      actions.push({
        label: 'Rename',
        action: () => {
          handleRename();
        },
        divider: true,
      });
    }

    allowedTypesForIsolation.includes(nodeType) &&
      actions.push({
        label: 'Isolate selection/End isolate',
        action: () => handleIsolateNode({ guid: elementGUID, type: nodeType }),
      });

    if (showHideAction) {
      actions.push({
        label: 'Show/Hide',
        action: () => {
          handleShowHide();
        },
      });
    }
    if (lockUnlockAction) {
      actions.push({
        label: 'Lock/Unlock',
        action: () => {
          handleLockUnlock();
        },
        divider:
          nodeType === NodeType.Building ||
          nodeType === NodeType.SurroundingBuilding,
      });
    }
    if (deleteAction) {
      actions.push({
        label: 'Delete',
        action: () => {
          handleDelete();
        },
        type: ContextActionTypes.Danger,
      });
    }
    return actions;
  }, [renameAction, lockUnlockAction, showHideAction, deleteAction]);

  useEffect(() => {
    const elementToStick = headerRef.current?.closest(
      '.ant-collapse-header'
    ) as HTMLDivElement | null;

    if (elementToStick) {
      elementToStick.classList.remove('stick');
      if (isElementSelected) {
        elementToStick.classList.add('stick');
      }
    }
  }, [isElementSelected]);

  const handleSelectedNodes = (event: React.MouseEvent) => {
    if (nodeType) {
      selectNode({
        event,
        type: nodeType,
        guid: elementGUID,
        isLocked: isLocked,
        isSelected: isElementSelected,
        source: SelectedNodeSource.Panel,
      });
    }
  };

  const handleRename = () => {
    setInEditMode(true);
  };

  const handleShowHide = (event?: BaseSyntheticEvent) => {
    event?.stopPropagation();
    showHideAction && showHideAction();
  };

  const handleLockUnlock = (event?: BaseSyntheticEvent) => {
    event?.stopPropagation();
    lockUnlockAction && lockUnlockAction();
  };

  const handleFinishEditing = async () => {
    if (form.getFieldValue('name') === text || !renameAction) {
      setInEditMode(false);
      return;
    }
    try {
      setInEditMode(false);
      await form.validateFields();
      renameAction && renameAction(form.getFieldValue('name'));
    } catch (err) {
      if (Object.hasOwn(err as never, 'data')) {
        setFormErrorFromServer(form, err as CustomError);
        setInEditMode(true);
      } else {
        await handleResetEditing();
      }
    }
  };

  const handleResetEditing = async () => {
    try {
      form.setFieldValue('name', text);
      await form.validateFields();
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => setInEditMode(false), 200);
    }
  };

  const handleDelete = () => {
    deleteAction && deleteAction();
  };

  const getLockIcon = () => {
    if (isParentLocked && !isLocked) {
      return <img src={DotIcon} alt={'Locked by parent'} />;
    }
    return isLocked ? (
      <img src={LockIcon} alt={'Lock Icon'} className={'w-3 h-3'} />
    ) : (
      <img src={UnlockIcon} alt={'Unlock Icon'} className={'w-3 h-3'} />
    );
  };

  const getHideIcon = () => {
    if (isIsolateModeEnabled) return;
    if (isParentHidden && !isHidden) {
      return <img src={DotIcon} alt={'Locked by parent'} />;
    }
    return isHidden ? (
      <img src={InvisibleIcon} alt={'Invisible Icon'} className={'w-3 h-3'} />
    ) : (
      <img src={VisibleIcon} alt={'Visible Icon'} className={'w-3 h-3'} />
    );
  };

  useEffect(() => {
    const element = headerRef.current?.offsetParent?.classList.contains(
      'ant-collapse-header'
    )
      ? headerRef.current?.offsetParent
      : headerRef.current?.children[0];

    const className =
      isParentSelected && !isElementSelected
        ? 'active-browser-item'
        : 'active-parent-browser-item';

    element?.classList.remove('active-browser-item');
    element?.classList.remove('active-parent-browser-item');

    (isElementSelected || isParentSelected) &&
      element?.classList.add(className);
  }, [isElementSelected, isParentSelected]);

  useEffect(() => {
    if (depthLevel) {
      const isCollapsibleParent =
        headerRef.current?.offsetParent?.classList.contains(
          'ant-collapse-header'
        );
      isCollapsibleParent
        ? headerRef.current?.offsetParent?.setAttribute(
            'depthLevel',
            depthLevel.toString()
          )
        : (headerRef.current?.children[0] as HTMLDivElement).style.setProperty(
            'padding-left',
            `${16 * depthLevel}px`
          );
    }
  }, [depthLevel]);

  useEffect(() => {
    const timeoutId =
      isElementSelected &&
      selectedNode.source !== SelectedNodeSource.Panel &&
      setTimeout(() => {
        headerRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }, APPROXIMATE_OPENING_TIME_OF_COLLAPSE);
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [isElementSelected]);

  const getEmptyActionButton = (show?: boolean, hasAction?: boolean) =>
    !hasAction && !show ? (
      <div className={`w-4 h-6`} />
    ) : (
      <div
        className={`w-4 h-6 flex justify-center items-center opacity-${show ? '100' : '0'} group-hover:opacity-100 transition duration-300`}
      >
        <img src={DotIcon} alt={'No feature here'} />
      </div>
    );

  return (
    <ContextMenu
      onClick={handleSelectedNodes}
      actions={contextActions}
      onContextAction={handleSelectedNodes}
    >
      <IntusTooltip
        title={inEditMode ? '' : form.getFieldValue('name') || text}
        placement="right"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0}
        overlayStyle={{ marginLeft: '4px' }}
      >
        <div
          className={`select-none z-5 relative ${isHidden || isLocked || isParentLocked || isParentHidden || (isIsolateModeEnabled && !isNodeIsolated) ? 'opacity-60' : ''}
                   ${isLastChildItem ? 'hover:shadow-[0_-1px_0_theme("colors.light-gray.20"),_0_1px_0_theme("colors.light-gray.20")] hover:relative hover:z-15' : ''}`}
          ref={headerRef}
          onPointerOver={() => setHoveredNode()}
          onPointerOut={() => resetHoveredNode()}
        >
          <div
            className={`flex items-center justify-between group leading-6 transition-all duration-300 h-8 pr-3 [&_input]:mr-1`}
          >
            <Form
              form={form}
              initialValues={{ name: text }}
              className="flex-1 w-full text-nowrap overflow-hidden text-ellipsis"
              onFinish={handleFinishEditing}
            >
              <Form.Item
                name="name"
                noStyle
                rules={[requiredValidator('Name is required')]}
              >
                <EditableField
                  isEditing={inEditMode && !!renameAction}
                  onClickOutside={handleFinishEditing}
                  onEscape={handleResetEditing}
                  setEditMode={setInEditMode}
                  size="small"
                  textSize="xs"
                  textClassNames={`w-full text-nowrap overflow-hidden text-ellipsis ${depthLevel && depthLevel > 1 ? 'font-light' : 'font-regular'}`}
                  disabled={!renameAction}
                />
              </Form.Item>
            </Form>
            {!inEditMode && (
              <div className={'flex gap-1 items-center'}>
                {lockUnlockAction ? (
                  <BrowserPanelIconButton
                    icon={getLockIcon()}
                    onClick={handleLockUnlock}
                    isApplied={isLocked || isParentLocked}
                  />
                ) : (
                  getEmptyActionButton(
                    isLocked || isParentLocked,
                    lockUnlockAction
                  )
                )}
                {showHideAction ? (
                  <BrowserPanelIconButton
                    icon={getHideIcon()}
                    onClick={handleShowHide}
                    isApplied={isHidden || isParentHidden}
                  />
                ) : (
                  getEmptyActionButton(
                    isParentHidden && !isHidden && !isIsolateModeEnabled,
                    showHideAction
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </IntusTooltip>
    </ContextMenu>
  );
};

export default BrowserPanelItem;
