import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { WindowElementType } from '@/components/WindowCreator/models/konva-model';
import { FlatVector2 } from '@/models';
import { WindowCreatorModes } from '@/models/shared.model';

interface WindowsCreatorState {
  selectedNodes: {
    [id: number]: { type: WindowElementType; points: FlatVector2[] };
  };
  validationError: {
    [id: number]: string[];
  };
  selectedArea?: FlatVector2[][];
  hoveredArea?: FlatVector2[][];
  mode: WindowCreatorModes;
}

const initialState: WindowsCreatorState = {
  selectedNodes: {},
  validationError: {},
  mode: WindowCreatorModes.Selection,
};

export const windowCreatorSlice = createSlice({
  name: 'windows',
  initialState,
  reducers: {
    setWindowSelectedNode: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        type: WindowElementType;
        points: FlatVector2[];
      }>
    ) => {
      state.selectedNodes = {
        [payload.id]: { type: payload.type, points: payload.points },
      };
    },
    clearSelectedNodes: (state) => {
      state.selectedNodes = {};
    },
    setSelectArea: (state, action: PayloadAction<FlatVector2[][]>) => {
      state.selectedArea = action.payload;
    },
    resetSelectedArea: (state) => {
      state.selectedArea = undefined;
    },
    setHoverArea: (state, action: PayloadAction<FlatVector2[][]>) => {
      state.hoveredArea = action.payload;
    },
    resetHoverArea: (state) => {
      state.hoveredArea = undefined;
    },

    clearSelectRelatedData: (state) => {
      state.selectedNodes = {};
      state.selectedArea = undefined;
      state.hoveredArea = undefined;
    },
    setValidationError: (
      state,
      { payload }: PayloadAction<{ id: number; errors: string[] }>
    ) => {
      state.validationError = {
        ...state.validationError,
        [payload.id]: payload.errors,
      };
    },
    setWindowCreatorMode: (
      state,
      action: PayloadAction<WindowCreatorModes>
    ) => {
      state.mode = action.payload;
    },
  },
});

export const {
  setWindowSelectedNode,
  clearSelectedNodes,
  setSelectArea,
  setHoverArea,
  setValidationError,
  setWindowCreatorMode,
  resetSelectedArea,
  resetHoverArea,
  clearSelectRelatedData,
} = windowCreatorSlice.actions;

export const getIsWindowNodeSelected = (id?: number) => (state: RootState) =>
  id ? !!state.windowsReducer.windows.selectedNodes[id] : false;

export const getSelectedArea = (state: RootState) =>
  state.windowsReducer.windows.selectedArea;

export const getHoveredArea = (state: RootState) =>
  state.windowsReducer.windows.hoveredArea;

export const getSomeWindowHaveValidationErrors = (state: RootState) =>
  Object.values(state.windowsReducer.windows.validationError).some(
    (obj) => obj.length > 0
  );

export const getWindowCreatorMode = (state: RootState) =>
  state.windowsReducer.windows.mode;

export const getSelectedWindowNodeType = (state: RootState) =>
  Object.values(state.windowsReducer.windows.selectedNodes)[0]?.type;

export const getSelectedWindowNodePoints = (state: RootState) =>
  Object.values(state.windowsReducer.windows.selectedNodes)[0]?.points;

export const getHasSelectedNode = (state: RootState) =>
  Object.keys(state.windowsReducer.windows.selectedNodes).length > 0;

export const getValidationErrors = (id: number) => (state: RootState) =>
  state.windowsReducer.windows.validationError[id];
