import { useEffect, useMemo, useState } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { C_FloorMaterial } from '@/shared/materials';
import { triangulateGeometryAndUpdate } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  pointTargetOnMesh,
  PROJECT_CANVAS_ID,
} from '@/shared/helpers/canvas-verifiers';
import { ExtrudeHandlerData } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';

interface UseExtrudeHandlersProps {
  extrudeNode: ExtrudeHandlerData;
}

export const useExtrudeHandlers = ({
  extrudeNode,
}: UseExtrudeHandlersProps) => {
  const three = useThree();
  const [mousePosition, setMousePosition] = useState(new THREE.Vector3());

  const [maxNegativeDistance, setMaxNegativeDistance] = useState<number>();
  const [maxPositiveDistance, setMaxPositiveDistance] = useState<number>();

  const [extrudeHandlerPosition, setExtrudeHandlerPosition] =
    useState<THREE.Vector3>(null!);

  const surfaceForMousePosition = useMemo(() => {
    //TODO CALCULATE NUMBER DEPENDING ON HOW BIG MAP
    const points = [
      new THREE.Vector3(100, extrudeNode.defaultCenter[1], 100),
      new THREE.Vector3(100, extrudeNode.defaultCenter[1], -100),
      new THREE.Vector3(-100, extrudeNode.defaultCenter[1], -100),
      new THREE.Vector3(-100, extrudeNode.defaultCenter[1], 100),
      new THREE.Vector3(100, extrudeNode.defaultCenter[1], 100),
    ];
    const mesh = new THREE.Mesh(
      new THREE.BufferGeometry().setFromPoints(points),
      C_FloorMaterial
    );
    triangulateGeometryAndUpdate(mesh.geometry, points);

    return mesh;
  }, [extrudeNode]);

  const handleMaxNegativeDistance = (distance: number) => {
    if (!maxNegativeDistance) {
      setMaxNegativeDistance(distance);
      return;
    }

    distance > maxNegativeDistance && setMaxNegativeDistance(distance);
  };

  const handleMaxPositiveDistance = (distance: number) => {
    if (!maxPositiveDistance) {
      setMaxPositiveDistance(distance);
      return;
    }

    distance > maxPositiveDistance && setMaxPositiveDistance(distance);
  };

  const onPointerMove = (event: PointerEvent) => {
    const position = pointTargetOnMesh(event, three, surfaceForMousePosition);
    if (!position) return;

    setMousePosition(position);
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    canvas?.addEventListener('pointermove', onPointerMove);
    return () => {
      canvas?.removeEventListener('pointermove', onPointerMove);
    };
  }, []);

  return {
    handleMaxPositiveDistance,
    handleMaxNegativeDistance,
    maxPositiveDistance,
    maxNegativeDistance,
    extrudeHandlerPosition,
    setExtrudeHandlerPosition,
    mousePosition,
  };
};
