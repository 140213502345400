import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes, SelectedNode } from '@/models';
import { IntusButton, IntusIconButton } from '@/shared/elements';
import { CursorIcon } from '@/shared/icons';
import { WindowPlacementModes } from '@/models/shared.model';
import WindowPlacementIcon from '@/shared/elements/EditToolbar/icons/WindowPlacementIcon';
import { getLeftPanelWidth } from '@/store/slices/sharedSlice';
import WindowPlacementContainer from '@/components/WindowPlacement/WindowPlacementContainer';
import {
  getWindowPlacementMode,
  resetExternalElementsState,
  setWindowPlacementMode,
} from '@/store/slices/canvasExternalElementsSlice';
import TextTooltip from '@/shared/elements/TextTooltip/TextTooltip';
import escapeIcon from '@/images/EscapeIcon.svg';
import { resetWindowPlacementSlice } from '@/store/slices/windowsReducer/windowPlacementSlice';

const DEFAULT_WINDOW_PLACEMENT_HEIGHT = 250;

interface WindowPlacementProps {
  selectedWall: SelectedNode;
}

const WindowPlacement: React.FC<WindowPlacementProps> = ({ selectedWall }) => {
  const dispatch = useAppDispatch();
  const placementMode = useAppSelector(getWindowPlacementMode);
  const leftPanelWidth = useAppSelector(getLeftPanelWidth);

  const [placementHeight, setPlacementHeight] = useState(
    DEFAULT_WINDOW_PLACEMENT_HEIGHT
  );

  const placementWidth = window.innerWidth - leftPanelWidth - 220;

  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (placementMode === WindowPlacementModes.WindowPlacement) {
        dispatch(setWindowPlacementMode(WindowPlacementModes.Selection));
        return;
      } else {
        dispatch(setMode(CanvasActionsModes.selection));
        dispatch(resetExternalElementsState());
        dispatch(resetWindowPlacementSlice());
      }
    }
  };

  const getIconFillColor = (expectedMode: WindowPlacementModes): string => {
    return expectedMode === placementMode ? '#fff' : '#414042';
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [placementMode]);

  const tooltipText = (
    <>
      <img src={escapeIcon} alt={'tooltip-icon'} className={'pr-1 pt-1'} />
      <span className="font-normal">to exit without saving</span>
    </>
  );

  return (
    <>
      <div
        className={`absolute top-0 z-10 border border-solid border-light-gray-20 border-t-0`}
        style={{
          left: leftPanelWidth,
          width: placementWidth,
        }}
      >
        <Resizable
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          minHeight={250}
          maxHeight={'75vh'}
          onResize={(_, __, elementRef) =>
            setPlacementHeight(elementRef.offsetHeight)
          }
        >
          <div className="absolute right-0 top-2 w-full z-50 flex justify-center">
            <IntusIconButton
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(setWindowPlacementMode(WindowPlacementModes.Selection))
              }
              icon={
                <CursorIcon
                  fill={getIconFillColor(WindowPlacementModes.Selection)}
                />
              }
              isActive={WindowPlacementModes.Selection === placementMode}
            />
            <IntusIconButton
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(
                  setWindowPlacementMode(WindowPlacementModes.WindowPlacement)
                )
              }
              icon={
                <WindowPlacementIcon
                  fill={getIconFillColor(WindowPlacementModes.WindowPlacement)}
                />
              }
              isActive={WindowPlacementModes.WindowPlacement === placementMode}
            />
          </div>
          <div className="absolute top-2 right-6 z-50">
            <IntusButton className=" ml-auto text-white px-5" disabled>
              Save
            </IntusButton>
          </div>
          <div className={'bg-light-gray-10 w-full h-full min-h-[250px]'}>
            <WindowPlacementContainer
              placementHeight={placementHeight}
              placementWidth={placementWidth}
              selectedWall={selectedWall}
            />
          </div>
        </Resizable>
      </div>

      <TextTooltip
        text={tooltipText}
        visible={placementMode === WindowPlacementModes.Selection}
      />
    </>
  );
};

export default WindowPlacement;
