import React from 'react';
import { ProjectStructure } from '@/models';
import ProjectConstructionSite from './ProjectConstructionSite';
import ProjectCanvasBuildings from './ProjectCanvasBuildings';
import { useAppSelector } from '@/store/hooks';
import { getIsNodeSelected } from '@/store/slices/canvasBuildingSlice';

interface ProjectEnvironmentProps {
  project: ProjectStructure;
}

const ProjectEnvironment: React.FC<ProjectEnvironmentProps> = ({ project }) => {
  if (project.environment.userData?.isHidden) return null;

  const isEnvironmentSelected = useAppSelector(
    getIsNodeSelected(project.environment.guid)
  );

  return (
    <group>
      <ProjectConstructionSite
        project={project}
        isAllEnvironmentSelected={isEnvironmentSelected}
        isEnvironmentLocked={project.environment.userData?.isLocked}
      />
      <ProjectCanvasBuildings
        project={project}
        isAllEnvironmentSelected={isEnvironmentSelected}
        isEnvironmentLocked={project.environment.userData?.isLocked}
      />
    </group>
  );
};

export default ProjectEnvironment;
