import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CanvasCamera,
  ConstructionSiteDto,
  CreateUserBuildingData,
  FetchProjectsResponse,
  ProjectCreateData,
  ProjectDeleteData,
  ProjectStructure,
  SavedCamera,
  SurroundingBuildingsDto,
  UserBuildingBlockDto,
  UserBuildingCoordinatesObject,
  UserBuildingDto,
  UserBuildingPartDto,
  UserBuildingStoreyDto,
  UserDataDto,
} from '@/models';
import { baseQuery } from '@/middlewares/http-interceptors';

const projectsApi = createApi({
  reducerPath: 'projects',
  keepUnusedDataFor: 10000,
  tagTypes: ['Project'],
  baseQuery,
  endpoints: (build) => ({
    fetchProjects: build.query<FetchProjectsResponse, string>({
      providesTags: ['Project'],
      query: (searchValue: string) => ({
        url: `/projects?name=${searchValue}`,
        method: 'GET',
      }),
    }),
    createProject: build.mutation<ProjectStructure, ProjectCreateData>({
      query: (projectData) => ({
        url: '/projects',
        method: 'POST',
        body: projectData,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    fetchProject: build.query<ProjectStructure, string>({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'GET',
      }),
    }),
    updateProject: build.mutation<
      ProjectStructure[],
      Partial<ProjectStructure>
    >({
      query: (projectData) => ({
        url: `/projects`,
        method: 'PUT',
        body: projectData,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    deleteProject: build.mutation<ProjectStructure[], number>({
      query: (projectId: number) => ({
        url: `/projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),

    deleteConstruction: build.mutation<
      null,
      { projectId: number; data: ProjectDeleteData }
    >({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/delete`,
        method: 'put',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    createNewCamera: build.mutation<
      SavedCamera,
      //Project id required for proper camera storage in project
      { projectId: string; data: CanvasCamera }
    >({
      query: ({ data, projectId }) => ({
        url: `/projects/${projectId}/cameras`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateCameraProperties: build.mutation<
      null,
      //Project id required for proper camera storage in project
      { projectId: string; data: SavedCamera }
    >({
      query: ({ data }) => ({
        url: `/projects/cameras/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    createUserBuilding: build.mutation<
      UserBuildingCoordinatesObject,
      { projectId: string; data: CreateUserBuildingData }
    >({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/buildings`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingNode: build.mutation<
      null,
      {
        data: UserBuildingPartDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings/blocks/storeys/walls`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateProjectEnvironment: build.mutation<
      null,
      {
        data: UserDataDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/environment`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateProjectConstructionSite: build.mutation<
      null,
      {
        data: ConstructionSiteDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/environment/constructionSite`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateProjectSurroundingBuildings: build.mutation<
      null,
      {
        data: SurroundingBuildingsDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/environment/surrounding`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingBlock: build.mutation<
      null,
      {
        data: UserBuildingBlockDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings/blocks`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingStorey: build.mutation<
      null,
      {
        data: UserBuildingStoreyDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings/blocks/storeys`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
    updateUserBuildingData: build.mutation<
      null,
      {
        data: UserBuildingDto;
      }
    >({
      query: ({ data }) => ({
        url: `/projects/buildings`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Project']),
    }),
  }),
});

export const {
  useFetchProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useDeleteConstructionMutation,
  useFetchProjectQuery,
  useCreateNewCameraMutation,
  useUpdateCameraPropertiesMutation,
  useCreateUserBuildingMutation,
  useLazyFetchProjectQuery,
  useUpdateUserBuildingNodeMutation,
  useUpdateProjectEnvironmentMutation,
  useUpdateProjectConstructionSiteMutation,
  useUpdateProjectSurroundingBuildingsMutation,
  useUpdateUserBuildingStoreyMutation,
  useUpdateUserBuildingBlockMutation,
  useUpdateUserBuildingDataMutation,
} = projectsApi;
export { projectsApi };
