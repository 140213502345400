import React, { useState } from 'react';
import {
  DistanceInput,
  DrawModes,
  MetricLimits,
  NodeType,
  UserBuildingBlock,
} from '@/models';
import { IntusTooltip } from '@/shared/elements';
import { RectangleDrawIcon } from '@/shared/icons/RectangleDrawIcon';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import PropertyList, {
  FrameProperty,
} from '@/shared/elements/PropertyList/PropertyList';
import TotalSurface from '@/shared/elements/TotalSurface/TotalSurface';
import { CenterLineIcon } from '@/shared/icons/CenterLineIcon';
import { FreeDrawIcon } from '@/shared/icons';
import {
  BlockSearchResults,
  BuildingSearchResults,
  useFindNodeData,
} from '@/shared/hooks/useFindNodeData';
import { getCommonValue } from '../propertyPanel-helpers';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useUpdateUserBuildingBlockMutation } from '@/store/apis/projectsApi';

const BuildingFrame = ({ buildingsGUID }: { buildingsGUID: string[] }) => {
  const { getNodeData, findUserBuildingBlock, findUserBuilding } =
    useFindNodeData();
  const userBuildingUtils = useUpdateUserBuildingData();

  const [updateUserBuildingBlock] = useUpdateUserBuildingBlockMutation();

  const selectedBuildingsData = buildingsGUID.map(
    (buildingGUID) =>
      getNodeData({
        guid: buildingGUID,
        nodeType: NodeType.Building,
      }) as BuildingSearchResults
  );

  const blocksGUIDs = selectedBuildingsData
    .map((building) =>
      building.childNodes
        .filter((node) => node.type === NodeType.Block)
        .map((node) => node.guid)
    )
    .flat();

  const userBuildingBlocks = blocksGUIDs.map((blockGUID) =>
    findUserBuildingBlock(blockGUID)
  );
  const [initialBuildingBlocks, setInitialBuildingBlocks] =
    useState<UserBuildingBlock[]>(userBuildingBlocks);

  const userBuilding = findUserBuilding(buildingsGUID[0]);

  const handleSubmit = () => {
    userBuildingBlocks.forEach((userBuildingBlock) => {
      updateUserBuildingBlock({
        data: userBuildingBlock,
      });
    });
    setInitialBuildingBlocks(userBuildingBlocks);
  };

  const changeStoreysCountMetric = (value: string) => {
    initialBuildingBlocks.forEach((userBuildingBlock) => {
      const updatedBlock = changeStoreysNumberInBlock(value, userBuildingBlock);

      userBuildingUtils.updateUserBuildingBlockStoreys({
        blockGUID: userBuildingBlock.guid,
        updatedBlock: updatedBlock,
      });
    });
  };

  const blocksData = blocksGUIDs.map(
    (blockGUID) =>
      getNodeData({
        guid: blockGUID,
        nodeType: NodeType.Block,
      }) as BlockSearchResults
  );

  const {
    getBuildingDimensionsMetric,
    getFloorHeightMetric,
    getBlockStoreysCountMetric,
    getBlockHeightMetric,
    getFacadesAreaMetricForBlocks,
    getGrossInternalAreaForBlocks,
    updateBuildingWidth,
    changeStoreysNumberInBlock,
    changeDimensionInRectangleBuilding,
  } = useFrameProperties();

  const changeDimensionMetric = (
    value: string,
    dimension: DistanceInput.Width | DistanceInput.Length
  ) => {
    initialBuildingBlocks.forEach((userBuildingBlock) => {
      const updatedBlock = changeDimensionInRectangleBuilding({
        value: value,
        userBuildingBlock,
        dimension,
      });

      userBuildingUtils.updateUserBuildingBlockStoreys({
        blockGUID: userBuildingBlock.guid,
        updatedBlock: updatedBlock,
      });
    });
  };

  const blocksStoreysCountMetric = getBlockStoreysCountMetric(blocksData);

  const getBuildingsType = () => {
    const drawModes = selectedBuildingsData?.map(
      (building) => building.drawMode
    );

    return getCommonValue(drawModes, DrawModes.FreeDraw);
  };

  const buildingType = getBuildingsType();

  const getBuildingName = (building: BuildingSearchResults) => {
    return building?.name;
  };

  const rectangleBuildingMetrics: FrameProperty[] = [
    {
      name: DistanceInput.Width,
      value: getBuildingDimensionsMetric(blocksData, DistanceInput.Width),
      isEditable: true,
      onEdit: (value: string) =>
        changeDimensionMetric(value, DistanceInput.Width),
      onSubmit: handleSubmit,
      min: MetricLimits.WidthLengthMin,
      max: MetricLimits.WidthLengthMax,
    },
    {
      name: DistanceInput.Length,
      value: getBuildingDimensionsMetric(blocksData, DistanceInput.Length),
      isEditable: true,
      onEdit: (value: string) =>
        changeDimensionMetric(value, DistanceInput.Length),
      onSubmit: handleSubmit,
      min: MetricLimits.WidthLengthMin,
      max: MetricLimits.WidthLengthMax,
    },
    { name: 'Floor height', value: getFloorHeightMetric() },
    {
      name: 'Floor count',
      value: blocksStoreysCountMetric,
      isEditable: true,
      onEdit: changeStoreysCountMetric,
      onSubmit: handleSubmit,
      staticValue: true,
      min: MetricLimits.FloorsMin,
      max: MetricLimits.FloorsMax,
    },
    {
      name: 'Building height',
      value: getBlockHeightMetric(blocksStoreysCountMetric),
    },
  ];

  const freeFormBuildingMetrics = [
    { name: 'Floor height', value: getFloorHeightMetric() },
    {
      name: 'Floor count',
      value: blocksStoreysCountMetric,
      isEditable: true,
      onEdit: changeStoreysCountMetric,
      onSubmit: handleSubmit,
      staticValue: true,
      min: MetricLimits.FloorsMin,
      max: MetricLimits.FloorsMax,
    },
    {
      name: 'Building height',
      value: getBlockHeightMetric(blocksStoreysCountMetric),
    },
  ];

  const updateCenterLineWidth = (width: string) => {
    initialBuildingBlocks.forEach((userBuildingBlock) => {
      const updatedBlock = updateBuildingWidth(width, userBuilding);

      userBuildingUtils.updateUserBuildingBlockStoreys({
        blockGUID: userBuildingBlock.guid,
        updatedBlock: updatedBlock,
      });
    });
  };

  const centerLineBuildingMetrics: FrameProperty[] = [
    {
      name: DistanceInput.BuildingWidth,
      value: getBuildingDimensionsMetric(blocksData, DistanceInput.Width),
      isEditable: true,
      onEdit: (val: string) => updateCenterLineWidth(val),
      onSubmit: handleSubmit,
      min: MetricLimits.CenterLineBuildingWidthMin,
      max: MetricLimits.CenterLineBuildingWidthLengthMax,
    },
    { name: 'Floor height', value: getFloorHeightMetric() },
    {
      name: 'Floor count',
      value: blocksStoreysCountMetric,
      isEditable: true,
      onEdit: changeStoreysCountMetric,
      onSubmit: handleSubmit,
      staticValue: true,
      min: MetricLimits.FloorsMin,
      max: MetricLimits.FloorsMax,
    },
    {
      name: 'Building height',
      value: getBlockHeightMetric(blocksStoreysCountMetric),
    },
  ];

  const getBuildingMetrics = (): FrameProperty[] => {
    switch (buildingType) {
      case DrawModes.Rectangle:
        return rectangleBuildingMetrics;
      case DrawModes.FreeDraw:
        return freeFormBuildingMetrics;
      case DrawModes.CenterLine:
        return centerLineBuildingMetrics;
      default:
        return freeFormBuildingMetrics;
    }
  };

  const getBuildingIcon = () => {
    switch (buildingType) {
      case DrawModes.Rectangle:
        return <RectangleDrawIcon fill="#414042" />;
      case DrawModes.FreeDraw:
        return <FreeDrawIcon fill="#414042" />;
      case DrawModes.CenterLine:
        return <CenterLineIcon fill="#414042" />;
      default:
        return <FreeDrawIcon fill="#414042" />;
    }
  };

  const getTooltipTitle = () => {
    switch (buildingType) {
      case DrawModes.Rectangle:
        return 'Rectangle building';
      case DrawModes.FreeDraw:
        return 'Free form building';
      case DrawModes.CenterLine:
        return 'Center line building';
      default:
        return 'Free form building';
    }
  };

  return (
    <>
      <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <div className="whitespace-nowrap text-ellipsis overflow-hidden w-[158px]">
          {selectedBuildingsData.length > 1
            ? `Building (${selectedBuildingsData.length})`
            : getBuildingName(selectedBuildingsData[0])}
        </div>
        <IntusTooltip
          title={getTooltipTitle()}
          placement="left"
          overlayStyle={{ marginLeft: '4px' }}
        >
          <div className="flex items-center">{getBuildingIcon()}</div>
        </IntusTooltip>
      </div>

      <div
        className={
          'flex flex-col text-xs border border-l-0 border-solid border-light-gray-20 !bg-white overflow-y-auto text-dark-gray-100'
        }
      >
        <MetricsHeader />
        <PropertyList alignValueLeft properties={getBuildingMetrics()} />
      </div>

      <TotalSurface
        facadesArea={getFacadesAreaMetricForBlocks(blocksData)}
        grossInternalArea={getGrossInternalAreaForBlocks(blocksData)}
      />
    </>
  );
};

export default BuildingFrame;
