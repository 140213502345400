import { Line } from 'react-konva';

import React, { useRef } from 'react';
import { FlatVector2 } from '@/models';
import { INITIAL_SCALE } from '@/components/WindowCreator/constants';
import { degToRad } from 'three/src/math/MathUtils';

const MEASUREMENT_SIDE_LINE_HEIGHT = 8;

interface MeasurementArrowProps {
  scale: number;
  color: string;
  angle: number;
  point: FlatVector2;
}
export const MeasurementArrow = ({
  scale,
  color,
  angle,
  point,
}: MeasurementArrowProps) => {
  const arrowRef = useRef(null!);

  const radAngle = degToRad(angle);

  const xDiff =
    Math.round(Math.sin(radAngle) * MEASUREMENT_SIDE_LINE_HEIGHT) / 2 / scale;
  const yDiff =
    Math.round(Math.cos(radAngle) * MEASUREMENT_SIDE_LINE_HEIGHT) / 2 / scale;

  const startPoint: FlatVector2 = [point[0] - xDiff, point[1] - yDiff];
  const endPoint: FlatVector2 = [point[0] + xDiff, point[1] + yDiff];

  return (
    <Line
      ref={arrowRef}
      strokeWidth={(INITIAL_SCALE * 2) / scale}
      points={[...startPoint, ...endPoint]}
      stroke={color}
    ></Line>
  );
};
