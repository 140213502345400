import React from 'react';
import { Select, SelectProps } from 'antd';
import { VectorDownIcon } from '@/shared/icons';
import './AntdSelect.scss';

const IntusSelect: React.FC<SelectProps> = ({ ...rest }) => {
  return (
    <Select
      rootClassName={'intus-select'}
      suffixIcon={<VectorDownIcon />}
      {...rest}
    />
  );
};

export default IntusSelect;
