import React from 'react';
import { BaseIconProps } from '@/models';

export const CreateMullionIcon = (props: BaseIconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 14C1 14.5523 1.44772 15 2 15H8.20703C8.07217 14.5232 8 14.02 8 13.5C8 12.681 8.179 11.9039 8.5 11.2055V3V2H9V10.3369C9.28389 9.93379 9.62057 9.57052 10 9.25716V2H14V8.02242C14.3434 8.05337 14.6777 8.11588 15 8.20703V2C15 1.44772 14.5523 1 14 1H2C1.44772 1 1 1.44772 1 2V14ZM2 2V14H6V2H2ZM7 2V14H7.5V13V3V2H7Z"
        fill={props.fill}
      />
      <path
        d="M15 9.25606C14.6816 9.14352 14.3467 9.06579 14 9.02746V11.5V13H15V9.25606Z"
        fill={props.fill}
      />
      <path d="M15 14H14V15C14.5523 15 15 14.5523 15 14Z" fill="#414042" />
      <path
        d="M13 15V14H11.5H10V10.6713C9.3746 11.4442 9 12.4284 9 13.5C9 14.026 9.09023 14.5308 9.25606 15H13Z"
        fill={props.fill}
      />
      <path
        d="M18 13.5C18 15.9853 15.9853 18 13.5 18C11.0147 18 9 15.9853 9 13.5C9 11.0147 11.0147 9 13.5 9C15.9853 9 18 11.0147 18 13.5ZM14 11.5C14 11.2239 13.7761 11 13.5 11C13.2239 11 13 11.2239 13 11.5V13H11.5C11.2239 13 11 13.2239 11 13.5C11 13.7761 11.2239 14 11.5 14H13V15.5C13 15.7761 13.2239 16 13.5 16C13.7761 16 14 15.7761 14 15.5V14H15.5C15.7761 14 16 13.7761 16 13.5C16 13.2239 15.7761 13 15.5 13H14V11.5Z"
        fill={props.fill}
      />
    </svg>
  );
};
