import React from 'react';

const SphereRotateIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.08771 10.5435C3.07376 10.3638 3.06665 10.1823 3.06665 9.99902C3.06665 9.30366 3.16902 8.6322 3.3595 7.9989H2.54385C2.42864 7.9989 2.31756 8.03627 2.22576 8.10398C2.07949 8.70615 2.00136 9.33498 2 9.98183L2 10.0162C2.00105 10.5131 2.04739 10.9994 2.13513 11.4711C2.23435 11.5868 2.37998 11.6605 2.54386 11.6605C2.60325 11.6605 2.66025 11.6508 2.71351 11.6329C2.93208 11.5597 3.08771 11.3489 3.08771 11.1041V10.5435ZM3.49829 12.4126C4.29693 14.5631 6.13208 16.21 8.39679 16.7461V17.4297C8.39679 17.5727 8.41636 17.7139 8.45352 17.8497C5.68087 17.3066 3.42126 15.3313 2.4767 12.7258C2.49894 12.7267 2.52133 12.7271 2.54386 12.7271C2.90767 12.7271 3.23513 12.6088 3.49829 12.4126ZM11.4792 17.8625C14.2825 17.3385 16.5711 15.3524 17.5233 12.7258C17.501 12.7267 17.4787 12.7271 17.4561 12.7271C17.0923 12.7271 16.7648 12.6088 16.5017 12.4125C15.6956 14.5833 13.8334 16.2407 11.5395 16.7608V17.4297C11.5395 17.5772 11.5187 17.7228 11.4792 17.8625ZM10.4728 16.9165V17.4297C10.4728 17.7148 10.2511 17.9537 9.96813 17.9537C9.68518 17.9537 9.46345 17.7148 9.46345 17.4297V16.9119C9.64052 16.9255 9.81944 16.9324 9.99998 16.9324C10.1589 16.9324 10.3166 16.927 10.4728 16.9165ZM16.9123 10.543V11.1041C16.9123 11.3485 17.0674 11.559 17.2854 11.6326C17.3389 11.6507 17.3963 11.6605 17.4561 11.6605C17.62 11.6605 17.7656 11.5869 17.8648 11.4711C17.9536 10.994 18 10.5019 18 9.99902C18 9.34611 17.9218 8.71147 17.7742 8.10396C17.7056 8.05335 17.6262 8.01968 17.5423 8.00592C17.514 8.00128 17.4852 7.9989 17.4561 7.9989H17.2885H16.6405C16.7093 8.22788 16.7667 8.46185 16.8118 8.70014L17.2885 7.9989L16.864 9.01495C16.9097 9.3364 16.9333 9.66495 16.9333 9.99902C16.9333 10.1821 16.9262 10.3635 16.9123 10.543ZM16.2199 6.93223H17.3911C16.1879 4.03583 13.3318 1.99902 9.99998 1.99902C6.66816 1.99902 3.81205 4.03583 2.60889 6.93223H3.78007C4.91177 4.64133 7.27187 3.06569 9.99998 3.06569C12.7281 3.06569 15.0882 4.64133 16.2199 6.93223Z"
        fill="currentColor"
      />
      <path
        d="M15.2632 9.14511C14.9602 9.14502 14.7193 8.88419 14.7193 8.56887C14.7193 8.25354 14.96 7.99263 15.2632 7.99263H17.4561C17.6014 7.99263 17.74 8.05411 17.8417 8.1625C17.9434 8.27078 18 8.41702 18 8.56887V10.905C18 11.2203 17.7593 11.4812 17.4561 11.4812C17.153 11.4812 16.9123 11.2203 16.9123 10.905V10.2813C16.7024 10.5171 16.4656 10.7408 16.2048 10.9506C16.0267 11.094 15.8373 11.2309 15.6376 11.3609C15.5328 11.4291 15.4251 11.4955 15.3147 11.5598C14.0105 12.3199 12.3251 12.8031 10.4728 12.8739L10.4728 17.4563C10.4728 17.7516 10.2511 17.999 9.96813 17.999C9.68518 17.999 9.46345 17.7516 9.46345 17.4563L9.46345 12.8714C7.63628 12.7923 5.97458 12.3117 4.68522 11.5607C4.57431 11.4961 4.46614 11.4294 4.36084 11.3609C4.16171 11.2312 3.97281 11.0947 3.79507 10.9517C3.53441 10.7421 3.29761 10.5186 3.08771 10.283V10.905C3.08771 11.2203 2.84699 11.4812 2.54386 11.4812C2.24072 11.4812 2 11.2203 2 10.905V8.56887C2 8.41702 2.0566 8.27078 2.15825 8.1625C2.26 8.05411 2.39864 7.99263 2.54386 7.99263H4.73682C5.03996 7.99263 5.28068 8.25354 5.28068 8.56887C5.28068 8.8842 5.03996 9.14511 4.73682 9.14511H3.60274C3.6995 9.28349 3.81032 9.42066 3.93556 9.55576C4.03953 9.6679 4.15342 9.77859 4.27741 9.88734C4.4338 10.0245 4.60624 10.1585 4.79507 10.2885C4.83924 10.3188 4.88431 10.349 4.93028 10.3789C6.06941 11.1205 7.6561 11.6326 9.46345 11.7178L9.46345 8.79477L8.70247 9.64965C8.50866 9.86739 8.18666 9.87365 7.98568 9.66308C7.78721 9.45513 7.78188 9.11407 7.97331 8.89902L9.45558 7.23384C9.73428 6.92075 10.1988 6.92075 10.4775 7.23384L11.9598 8.89902C12.1512 9.11407 12.1459 9.45513 11.9474 9.66308C11.7464 9.87365 11.4244 9.86738 11.2306 9.64965L10.4728 8.79835L10.4728 11.7206C12.306 11.6443 13.916 11.129 15.0682 10.3789C15.1146 10.3487 15.1602 10.3182 15.2048 10.2875C15.3931 10.1579 15.565 10.0242 15.7211 9.88734C15.8456 9.77811 15.9599 9.66693 16.0643 9.55428C16.189 9.41966 16.2993 9.28299 16.3957 9.14511H15.2632Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SphereRotateIcon;
