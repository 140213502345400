import { useParams } from 'react-router';
import { useAppDispatch } from '@/store/hooks';
import {
  projectsApi,
  useDeleteConstructionMutation,
  useUpdateProjectConstructionSiteMutation,
  useUpdateProjectEnvironmentMutation,
  useUpdateProjectSurroundingBuildingsMutation,
} from '@/store/apis/projectsApi';

export const useUpdateProjectEnvironmentData = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [updateProjectEnvironment] = useUpdateProjectEnvironmentMutation();
  const [updateConstructionSite] = useUpdateProjectConstructionSiteMutation();
  const [updateSurroundingBuildings] =
    useUpdateProjectSurroundingBuildingsMutation();
  const [deleteConstruction] = useDeleteConstructionMutation();

  const updateConstructionSiteUserData = (key: string, value: unknown) => {
    let newConstructionSiteData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          newConstructionSiteData = {
            guid: projectData.environment.constructionSite.guid,
            userData: {
              ...projectData.environment.constructionSite.userData,
              [key]: value,
            },
          };
          projectData.environment.constructionSite = {
            ...projectData.environment.constructionSite,
            userData: {
              ...projectData.environment.constructionSite.userData,
              [key]: value,
            },
          };
        }
      })
    );
    newConstructionSiteData &&
      updateConstructionSite({
        data: newConstructionSiteData,
      });
  };

  const updateSurroundingBuildingUserData = ({
    buildingGUID,
    key,
    value,
  }: {
    buildingGUID: string;
    key: string;
    value: unknown;
  }) => {
    let newSurroundingBuildingsData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          const buildingIndex =
            projectData.environment.surroundingBuildings.surroundings.findIndex(
              (sb) => sb.guid === buildingGUID
            );
          if (buildingIndex === undefined) return;
          newSurroundingBuildingsData = {
            guid: projectData.environment.surroundingBuildings.guid,
            buildings: [
              {
                guid: projectData.environment.surroundingBuildings.surroundings[
                  buildingIndex
                ].guid,
                userData: {
                  ...projectData.environment.surroundingBuildings.surroundings[
                    buildingIndex
                  ].userData,
                  [key]: value,
                },
              },
            ],
          };
          projectData.environment.surroundingBuildings.surroundings[
            buildingIndex
          ].userData = {
            ...projectData.environment.surroundingBuildings.surroundings[
              buildingIndex
            ].userData,
            [key]: value,
          };
        }
      })
    );
    newSurroundingBuildingsData &&
      updateSurroundingBuildings({
        data: newSurroundingBuildingsData,
      });
  };

  const deleteSurroundingBuilding = (buildingGUID: string) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          projectData.environment.surroundingBuildings.surroundings =
            projectData.environment.surroundingBuildings.surroundings.filter(
              (envBuilding) => envBuilding.guid !== buildingGUID
            );
        }
      })
    );

    deleteConstruction({
      data: {
        surroundingGuids: [buildingGUID],
      },
      projectId: Number(id)!,
    });
  };

  const updateSurroundingBuildingsUserData = (key: string, value: unknown) => {
    let newSurroundingBuildingsData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          newSurroundingBuildingsData = {
            guid: projectData.environment.surroundingBuildings.guid,
            userData: {
              ...projectData.environment.surroundingBuildings.userData,
              [key]: value,
            },
          };
          projectData.environment.surroundingBuildings = {
            ...projectData.environment.surroundingBuildings,
            userData: {
              ...projectData.environment.surroundingBuildings.userData,
              [key]: value,
            },
          };
        }
      })
    );
    newSurroundingBuildingsData &&
      updateSurroundingBuildings({
        data: newSurroundingBuildingsData,
      });
  };

  const updateEnvironmentUserData = (key: string, value: unknown) => {
    let newEnvironmentData;
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          newEnvironmentData = {
            userData: { ...projectData.environment.userData, [key]: value },
            guid: projectData.environment.guid,
          };
          projectData.environment = {
            ...projectData.environment,
            userData: newEnvironmentData.userData,
          };
        }
      })
    );

    newEnvironmentData &&
      updateProjectEnvironment({
        data: newEnvironmentData,
      });
  };

  const deleteSurroundingBuildings = (
    selectedSurroundingBuildings: string[]
  ) => {
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          projectData.environment.surroundingBuildings.surroundings =
            projectData.environment.surroundingBuildings.surroundings.filter(
              (surroundingBuilding) =>
                !selectedSurroundingBuildings.includes(surroundingBuilding.guid)
            );
        }
      })
    );
    deleteConstruction({
      data: {
        surroundingGuids: selectedSurroundingBuildings.map((guid) => guid),
      },
      projectId: Number(id)!,
    });
  };

  const deleteAllSurroundingBuildings = () => {
    let surroundingBuildingsGuid: string[] = [];
    dispatch(
      projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
        if (projectData) {
          surroundingBuildingsGuid =
            projectData.environment.surroundingBuildings.surroundings.map(
              ({ guid }) => guid
            );
          projectData.environment.surroundingBuildings.surroundings = [];
        }
      })
    );
    deleteConstruction({
      data: {
        surroundingGuids: surroundingBuildingsGuid,
      },
      projectId: Number(id)!,
    });
  };

  return {
    constructionSiteUtils: {
      updateConstructionSiteUserData,
    },
    surroundingBuildingsUtils: {
      updateSurroundingBuildingUserData,
      deleteSurroundingBuilding,
      updateSurroundingBuildingsUserData,
      deleteSurroundingBuildings,
      deleteAllSurroundingBuildings,
    },
    environmentUtils: { updateEnvironmentUserData },
  };
};
