import React from 'react';
import { BaseIconProps } from '@/models';

const WindowPlacementIcon: React.FC<BaseIconProps> = ({ fill = '#414042' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3C13 2.44772 12.5523 2 12 2H4C3.44772 2 3 2.44772 3 3V13C3 13.5523 3.44772 14 4 14H8.24905C8.08228 13.6879 7.95333 13.3525 7.86831 13H4L4 9.08462H8.90757C9.309 8.65896 9.79734 8.31617 10.3442 8.08462H8.5V3H12V7.75C12.3444 7.75 12.6793 7.79097 13 7.86831V3ZM13 11.6V9.17071C12.6872 9.06015 12.3506 9 12 9C11.7552 9 11.5173 9.02931 11.2896 9.08462H12V10C12.2209 10 12.4 10.1791 12.4 10.4V11.6H13ZM13 12.4H12.4V13.6C12.4 13.8209 12.2209 14 12 14C11.7791 14 11.6 13.8209 11.6 13.6V13H9.17071C9.30241 13.3726 9.50564 13.7115 9.76389 14H12C12.5523 14 13 13.5523 13 13V12.4ZM7.5 3H4V8.08462H7.5V3Z"
        fill={fill}
      />
      <path
        d="M15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12ZM12.3889 10.4444C12.3889 10.2297 12.2148 10.0556 12 10.0556C11.7852 10.0556 11.6111 10.2297 11.6111 10.4444V11.6111H10.4444C10.2297 11.6111 10.0556 11.7852 10.0556 12C10.0556 12.2148 10.2297 12.3889 10.4444 12.3889H11.6111V13.5556C11.6111 13.7703 11.7852 13.9444 12 13.9444C12.2148 13.9444 12.3889 13.7703 12.3889 13.5556V12.3889H13.5556C13.7703 12.3889 13.9444 12.2148 13.9444 12C13.9444 11.7852 13.7703 11.6111 13.5556 11.6111H12.3889V10.4444Z"
        fill={fill}
      />
    </svg>
  );
};

export default WindowPlacementIcon;
