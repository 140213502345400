import React from 'react';
import { Group, Line } from 'react-konva';
import {
  ERROR_ELEMENT_COLOR,
  INITIAL_SCALE,
} from '@/components/WindowCreator/constants';
import { FlatVector2 } from '@/models';
import { degToRad } from 'three/src/math/MathUtils';

interface CrossProps {
  scale: number;
  center: FlatVector2;
  degAngle: number;
  lineLength: number;
}
export const Cross = ({ scale, center, degAngle, lineLength }: CrossProps) => {
  const xDiff = Math.round(
    (Math.sin(degToRad(degAngle)) * lineLength) / 2 / scale
  );
  const yDiff = Math.round(
    (Math.cos(degToRad(degAngle)) * lineLength) / 2 / scale
  );
  return (
    <Group>
      <Line
        stroke={ERROR_ELEMENT_COLOR}
        strokeWidth={(INITIAL_SCALE * 2) / scale}
        points={[
          ...[center[0] - xDiff, center[1] - yDiff],
          ...[center[0] + xDiff, center[1] + yDiff],
        ]}
      />
      <Line
        stroke={ERROR_ELEMENT_COLOR}
        strokeWidth={(INITIAL_SCALE * 2) / scale}
        points={[
          ...[center[0] - xDiff, center[1] + yDiff],
          ...[center[0] + xDiff, center[1] - yDiff],
        ]}
      />
    </Group>
  );
};
