import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  BUILDING_SELECTED_CONTOUR_COLOR,
  C_FatLineBorderMaterial,
  C_WallMaterial,
} from '@/shared/materials';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
  getAllWallsAtOneSideAtBlock,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  CanvasActionsModes,
  DistanceInput,
  EditModes,
  FlatVector3,
  NodeType,
  SelectedNode,
  UserBuildingBlock,
  UserBuildingStorey,
  UserBuildingSurface,
} from '@/models';
import * as THREE from 'three';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { LineGeometry } from 'three-stdlib';
import {
  getXYZ,
  getExtendedVector,
  getGeometryVectorPointByIdx,
  getMinMaxCoordinatesAtVector3,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import {
  getEditedNode,
  getIsNodeEdited,
  getIsNodeIsolated,
  setEditedNode,
  switchIsolatedFlags,
} from '@/store/slices/canvasBuildingSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { GenericChildSurface } from '@/models/building-nodes.model';
import SplitLine from './SplitLine';
import SplitFaces from './SplitFaces';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { uuidv7 } from 'uuidv7';
import { subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__ESCAPE,
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
} from '@/core/event-names';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import {
  getProcessingEntity,
  resetExternalElementsState,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import { getMultiplyRate } from '@/store/slices/canvasMapSlice';
import { getWallWidth } from '@/shared/helpers/metrics';
import {
  findInsertionIndex,
  pointTargetOnMesh,
} from '@/shared/helpers/canvas-verifiers';
import { booleanClockwise, lineString } from '@turf/turf';
import { isRightClick } from '@/shared/helpers';
import {
  getStatusIsolateMode,
  setEditMode,
  setMode,
} from '@/store/slices/canvasModesSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { flatten } from 'lodash';

interface SplitWallProps extends GenericChildSurface {
  data: UserBuildingSurface;
  blockData: UserBuildingBlock;
  blockGUID: string;
  storeyData: UserBuildingStorey;
  isParentLocked: boolean;
  isParentEdited?: boolean;
  isRoof?: boolean;
  isLowestFloor?: boolean;
  isolateMode: boolean;

  storeyGUID: string;
  buildingGUID: string;
}

const SplitWall: React.FC<SplitWallProps> = ({
  data,
  blockData,
  blockGUID,
  storeyData,
  isParentEdited,
  isRoof,
  isLowestFloor,
  isolateMode,
  buildingGUID,
  storeyGUID,
}) => {
  const dispatch = useAppDispatch();
  const isWallIsolated = useAppSelector(getIsNodeIsolated(data.guid));
  const isIsolateModeEnabled = useAppSelector(getStatusIsolateMode);

  const multiplyRate = useAppSelector(getMultiplyRate);
  const splitNode = useAppSelector(getEditedNode);
  const isCurrentNodeEdited = useAppSelector(getIsNodeEdited(data.guid));

  const three = useThree();

  const { addNodesToSelectedNodes } = useSelectedNodes();

  const isBlockSplitted = useAppSelector(getIsNodeEdited(blockGUID));

  const [splitPosition, setSplitPosition] = useState<THREE.Vector3 | null>(
    null
  );
  const [isPointerOver, setIsPointerOver] = useState(false);

  const processingEntity = useAppSelector(getProcessingEntity);
  const isDirectionalInputActive = processingEntity?.active;

  const { updateBlockStoreys, updateUserBuildingStoreyData } =
    useUpdateUserBuildingData();

  useEffect(() => {
    //Max distance shouldn't be equal to wall width
    dispatch(setShowDirectionalInput({ isShow: true }));
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          display: true,
          processing: true,
        },
        {
          type: DistanceInput.RightEdgeDistance,
          display: true,
          processing: true,
        },
      ])
    );
  }, []);

  const { points } = data;
  const wallCoordinates = useMemo(
    () => convertFlatVector3ToVectors(points),
    [points]
  );

  const allWallsAtOneSide = useMemo(() => {
    return getAllWallsAtOneSideAtBlock(blockData, data.guid);
  }, [data.guid]);

  const minMaxCoordinates = useMemo(() => {
    if (isBlockSplitted) {
      const coordinatesOfWallsAtOneSide = allWallsAtOneSide
        .map((wall) => convertFlatVector3ToVectors(wall.points))
        .flat();
      return getMinMaxCoordinatesAtVector3(coordinatesOfWallsAtOneSide);
    }
    return getMinMaxCoordinatesAtVector3(wallCoordinates);
  }, []);

  const facadeCoordinates = useMemo(
    () =>
      minMaxCoordinates &&
      convertFlatVector3ToVectors([
        [points[0][0], minMaxCoordinates.min.y, points[0][2]],
        [points[1][0], minMaxCoordinates.min.y, points[1][2]],
        [points[2][0], minMaxCoordinates.max.y, points[2][2]],
        [points[3][0], minMaxCoordinates.max.y, points[3][2]],
      ]),
    [points, minMaxCoordinates]
  );

  const isBuildingClockwise = useMemo(() => {
    const clockwiseRing = lineString(
      storeyData.floor.points.map((point) => [point[0], point[2]])
    );
    return booleanClockwise(clockwiseRing);
  }, [storeyData.floor.points]);

  const wallWidth = getWallWidth(points, multiplyRate);
  const wallWidthInMillimeters = Number(convertMetersToMillimeters(wallWidth));

  const updateDirectionalInputValueForDistance = (
    cursorPoint?: THREE.Vector3
  ) => {
    const position = cursorPoint ?? splitPosition;
    if (!position) return;
    const leftDistance = getWallWidth(
      [
        [position.x, points[0][1], position.z],
        getXYZ(wallCoordinates[1]),
        getXYZ(wallCoordinates[2]),
        [position.x, points[3][1], position.z],
      ],
      multiplyRate
    );

    if (leftDistance > Number(wallWidth) || leftDistance < 0) return;

    const rightDistance = Number(wallWidth) - leftDistance;

    const maxSplitDistance = (wallWidthInMillimeters - 1).toFixed(0);

    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          processing: true,
          value: convertMetersToMillimeters(
            !isBuildingClockwise
              ? leftDistance.toString()
              : rightDistance.toString()
          ).toString(),
          min: 1,
          max: Number(maxSplitDistance),
          validationMessage: 'Invalid value',
        },
        {
          type: DistanceInput.RightEdgeDistance,
          processing: true,
          value: convertMetersToMillimeters(
            !isBuildingClockwise
              ? rightDistance.toString()
              : leftDistance.toString()
          ).toString(),
          min: 1,
          max: Number(maxSplitDistance),
          validationMessage: 'Invalid value',
        },
      ])
    );
  };

  const splitWallGeometry = useMemo(
    () => createGeometryFromVectorList(wallCoordinates, 'vertical'),
    [wallCoordinates]
  );

  const splitFacadeGeometry = useMemo(() => {
    if (isBlockSplitted && storeyData.storeyNumber === 1)
      return createGeometryFromVectorList(
        facadeCoordinates as THREE.Vector3[],
        'vertical'
      );
  }, [facadeCoordinates]);

  const edgeCoordinates = useMemo(() => {
    const coordinates: THREE.Vector3[][] = [];
    const coordinatesForEdge =
      isBlockSplitted && storeyData.storeyNumber === 1
        ? facadeCoordinates
        : wallCoordinates;
    if (!coordinatesForEdge) return;
    for (let i = 0; i < coordinatesForEdge.length - 1; i++) {
      coordinates.push([coordinatesForEdge[i], coordinatesForEdge[i + 1]]);
    }
    coordinates.push([
      coordinatesForEdge[coordinatesForEdge.length - 1],
      coordinatesForEdge[0],
    ]);
    return coordinates;
  }, [wallCoordinates, facadeCoordinates]);

  const verticalBorderIndexes = edgeCoordinates?.reduce(
    (acc: number[], curr, index) => {
      curr[0].z === curr[1].z && acc.push(index);
      return acc;
    },
    []
  );
  const topBorderIndex = edgeCoordinates?.findIndex((bc) => {
    const max = Math.max(...wallCoordinates.map((c) => c.y));
    return bc[0].y === max && bc[1].y === max;
  });
  const bottomBorderIndex = edgeCoordinates?.findIndex((bc) => {
    const min = Math.min(...wallCoordinates.map((c) => c.y));
    return bc[0].y === min && bc[1].y === min;
  });

  const handlePointerMove = (
    event: ThreeEvent<PointerEvent> | PointerEvent
  ) => {
    event.stopPropagation();
    setIsPointerOver(true);
    if (isBlockSplitted && !allWallsAtOneSide?.length) return;

    if (isCurrentNodeEdited || isParentEdited) {
      const cursorPoint = pointTargetOnMesh(
        event,
        three,
        new THREE.Mesh(
          !isBlockSplitted ? splitWallGeometry : splitFacadeGeometry,
          C_WallMaterial
        )
      );

      if (cursorPoint) {
        updateDirectionalInputValueForDistance(cursorPoint);
        setSplitPosition(cursorPoint);
      }
    }
  };

  const handlePointerLeave = () => {
    setIsPointerOver(false);
    setSplitPosition(null);
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          value: '0',
        },
        {
          type: DistanceInput.RightEdgeDistance,
          value: '0',
        },
      ])
    );
  };

  const getNewWallsForSaveSplitting = (
    wallData: UserBuildingSurface,
    walls: UserBuildingSurface[],
    splitPosition: THREE.Vector3
  ) => {
    const wallPoints = wallData.points;

    const newWalls: UserBuildingSurface[] = [];

    const newWall1Points: FlatVector3[] = [
      wallPoints[0],
      [splitPosition.x, wallPoints[1][1], splitPosition.z],
      [splitPosition.x, wallPoints[2][1], splitPosition.z],
      wallPoints[3],
    ];

    const newWall2Points: FlatVector3[] = [
      [splitPosition.x, wallPoints[0][1], splitPosition.z],
      wallPoints[1],
      wallPoints[2],
      [splitPosition.x, wallPoints[3][1], splitPosition.z],
    ];

    walls.forEach((wall, wallIndex) => {
      if (wall.guid === wallData.guid) {
        newWalls.push({
          ...wall,
          name: wall.name ? `${wall.name} 1` : `Wall ${wallIndex + 1} 1`,
          guid: uuidv7(),
          points: newWall1Points,
        });
        newWalls.push({
          ...wall,
          guid: uuidv7(),
          name: wall.name ? `${wall.name} 2` : `Wall ${wallIndex + 1} 2`,
          points: newWall2Points,
        });
      } else {
        newWalls.push({ ...wall, name: wall.name ?? `Wall ${wallIndex + 1}` });
      }
    });

    return newWalls;
  };

  const getCeilingPoints = (storeyGUID: string) => {
    const storeyData = blockData.storeys.find(
      (storey) => storey.guid === storeyGUID
    );
    return storeyData ? storeyData.ceiling.points : [];
  };

  const getFloorPoints = (storeyGUID: string) => {
    const storeyData = blockData.storeys.find(
      (storey) => storey.guid === storeyGUID
    );
    return storeyData ? storeyData.floor.points : [];
  };

  const getNewCeilingAndFloorPoints = (storeyGUID: string) => {
    const ceilingPoints = getCeilingPoints(storeyGUID);
    const floorPoints = getFloorPoints(storeyGUID);

    const newCeilingPoint = new THREE.Vector3(
      splitPosition?.x,
      ceilingPoints[0][1],
      splitPosition?.z
    );

    const newFloorPoint = new THREE.Vector3(
      splitPosition?.x,
      floorPoints[0][1],
      splitPosition?.z
    );

    const insertionCeilingIndex = findInsertionIndex(
      ceilingPoints,
      newCeilingPoint
    );

    const insertionFloorIndex = findInsertionIndex(floorPoints, newFloorPoint);

    const newCeilingPoints = [
      ...ceilingPoints.slice(0, insertionCeilingIndex),
      getXYZ(newCeilingPoint),
      ...ceilingPoints.slice(insertionCeilingIndex),
    ];

    const newFloorPoints = [
      ...floorPoints.slice(0, insertionFloorIndex),
      getXYZ(newFloorPoint),
      ...floorPoints.slice(insertionFloorIndex),
    ];

    return { newCeilingPoints, newFloorPoints };
  };

  const getNewStoreysWithSplitWalls = (
    storeys: UserBuildingStorey[],
    splitPosition: THREE.Vector3,
    allWallsAtOneSide: UserBuildingSurface[] | undefined
  ): UserBuildingStorey[] => {
    return storeys.map((storey) => {
      const { newCeilingPoints, newFloorPoints } = getNewCeilingAndFloorPoints(
        storey.guid
      );

      let newWalls = storey.walls;

      allWallsAtOneSide?.forEach((wall) => {
        newWalls = getNewWallsForSaveSplitting(wall, newWalls, splitPosition);
      });

      return {
        ...storey,
        ceiling: {
          ...storey.ceiling,
          points: newCeilingPoints,
        },
        floor: {
          ...storey.floor,
          points: newFloorPoints,
        },
        walls: newWalls,
      };
    });
  };

  const updateWallsOrStoreys = () => {
    if (!splitPosition) return;
    if (!isBlockSplitted) {
      const { newCeilingPoints, newFloorPoints } =
        getNewCeilingAndFloorPoints(storeyGUID);
      const newWalls = getNewWallsForSaveSplitting(
        data,
        storeyData.walls,
        splitPosition
      );

      updateUserBuildingStoreyData({
        buildingGuid: buildingGUID,
        blockGuid: blockGUID,
        storeyGuid: storeyGUID,
        newStorey: {
          ...storeyData,
          ceiling: { ...storeyData.ceiling, points: newCeilingPoints },
          floor: { ...storeyData.floor, points: newFloorPoints },
          walls: newWalls,
        },
      });

      const newGuidsToIsolate = newWalls
        .filter(
          (wall) =>
            !storeyData.walls.find(
              (storeyWall) => storeyWall.guid === wall.guid
            )
        )
        .map((wall) => wall.guid);
      const newSelectedNodes: SelectedNode[] = newGuidsToIsolate.map(
        (guid) => ({
          type: NodeType.Wall,
          guid: guid,
        })
      );
      addNodesToSelectedNodes(newSelectedNodes);
      isIsolateModeEnabled && dispatch(switchIsolatedFlags(newGuidsToIsolate));
    } else {
      const newStoreys = getNewStoreysWithSplitWalls(
        blockData.storeys,
        splitPosition,
        allWallsAtOneSide
      );
      updateBlockStoreys({
        buildingGUID,
        blockGUID,
        newStoreys,
      });

      const newGuidsToIsolate = flatten(
        newStoreys.map((storey) =>
          storey.walls
            .filter(
              (wall) =>
                !blockData.storeys.find((storey) =>
                  storey.walls.find(
                    (storeyWall) => storeyWall.guid === wall.guid
                  )
                )
            )
            .map((wall) => wall.guid)
        )
      );
      isIsolateModeEnabled && dispatch(switchIsolatedFlags(newGuidsToIsolate));
      if (splitNode) {
        addNodesToSelectedNodes([splitNode]);
      }
    }
  };

  const resetEditMode = () => {
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(setEditMode(EditModes.Unset));
    dispatch(setEditedNode(undefined));
  };

  const finishSplit = (event?: ThreeEvent<PointerEvent>) => {
    event?.stopPropagation();
    if (event && isRightClick(event)) return;

    if (isCurrentNodeEdited || isParentEdited) {
      updateWallsOrStoreys();
      resetEditMode();
      dispatch(resetExternalElementsState());
      dispatch(setMode(CanvasActionsModes.selection));
    }
  };

  const generateWallBorders = useCallback(() => {
    return edgeCoordinates?.map((v, i) => {
      const geometry = new LineGeometry();
      const isValidEdge = v.every((coord) => Number.isFinite(coord.y));
      if (!isValidEdge) return null;
      geometry.setPositions([...getXYZ(v[0]), ...getXYZ(v[1])]);

      const isContourEdge =
        verticalBorderIndexes?.includes(i) ||
        (topBorderIndex === i && isRoof && !isPointerOver) ||
        (bottomBorderIndex === i && isLowestFloor);
      const material = C_FatLineBorderMaterial.clone();
      material.linewidth = isContourEdge
        ? material.linewidth + 0.0004
        : material.linewidth;

      //colors only the outer borders if the block is edited
      if (isBlockSplitted) {
        if (isContourEdge) {
          material.color = BUILDING_SELECTED_CONTOUR_COLOR;
        }
      }

      //color all borders if wall is edited
      if (isParentEdited || isCurrentNodeEdited) {
        material.color = BUILDING_SELECTED_CONTOUR_COLOR;
        //need to render after default borders
        material.transparent = true;
        material.opacity = 1;
      }

      const line = new Line2(geometry, material);
      line.geometry.instanceCount = 2;

      line.computeLineDistances();

      return <primitive object={line} key={`split-wall-line-${i}`} />;
    });
  }, [
    edgeCoordinates,
    verticalBorderIndexes,
    isCurrentNodeEdited,
    isBlockSplitted,
    topBorderIndex,
    isRoof,
    isLowestFloor,
    bottomBorderIndex,
  ]);

  const onInputSet = () => {
    dispatch(
      setDirectionalInputValues([{ ...processingEntity, active: false }])
    );
    updateDirectionalInputValueForDistance();
  };

  const onInputUpdate = (evt: CustomEvent) => {
    if (
      (processingEntity?.type === DistanceInput.LeftEdgeDistance ||
        processingEntity?.type === DistanceInput.RightEdgeDistance) &&
      splitPosition !== null
    ) {
      const leftDistance =
        convertMillimetersToMeters(evt.detail) * multiplyRate;

      const rightDistance =
        convertMillimetersToMeters(
          (
            Number(convertMetersToMillimeters(wallWidth)) - evt.detail
          ).toString()
        ) * multiplyRate;

      const extendedVector = getExtendedVector(
        getGeometryVectorPointByIdx(1, splitWallGeometry),
        getGeometryVectorPointByIdx(0, splitWallGeometry),
        processingEntity.type === DistanceInput.LeftEdgeDistance
          ? leftDistance
          : rightDistance
      );
      if (
        evt.detail > 0 &&
        evt.detail < Number(convertMetersToMillimeters(wallWidth))
      ) {
        setSplitPosition(extendedVector);

        const secondInputValue = (wallWidthInMillimeters - evt.detail).toFixed(
          0
        );

        dispatch(
          setDirectionalInputValues([
            {
              type:
                processingEntity.type === DistanceInput.LeftEdgeDistance
                  ? DistanceInput.LeftEdgeDistance
                  : DistanceInput.RightEdgeDistance,
              processing: true,
              active: true,
            },
            {
              type:
                processingEntity.type === DistanceInput.LeftEdgeDistance
                  ? DistanceInput.RightEdgeDistance
                  : DistanceInput.LeftEdgeDistance,
              value: secondInputValue,
            },
          ])
        );
      }
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      (!isBlockSplitted || (isLowestFloor && isBlockSplitted))
    ) {
      finishSplit();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [isDirectionalInputActive]);

  const onEscape = () => {
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          processing: true,
        },
        {
          type: DistanceInput.RightEdgeDistance,
          processing: true,
        },
      ])
    );
  };

  useEffect(() => {
    subscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    subscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    return () => {
      unsubscribe(DIRECTIONAL_INPUT__SET, onInputSet);
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
      unsubscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    };
  }, [splitPosition, processingEntity, wallWidth]);

  const isDisplay =
    (!isolateMode || (isolateMode && isWallIsolated)) &&
    !(isBlockSplitted && storeyData.storeyNumber !== 1);

  const topBorder = useMemo(() => {
    const borderHalfSize = 0.02;
    const start = convertFlatVector3ToVector(data.points[2]);
    const end = convertFlatVector3ToVector(data.points[3]);
    const perpendicular = getPerpendicularVectorToVectors([start, end], true);
    const leftStart = getTranslatedVector(start, borderHalfSize, perpendicular);
    const rightStart = getTranslatedVector(
      start,
      -borderHalfSize,
      perpendicular
    );
    const leftEnd = getTranslatedVector(end, borderHalfSize, perpendicular);
    const rightEnd = getTranslatedVector(end, -borderHalfSize, perpendicular);

    return new THREE.Mesh(
      createGeometryFromVectorList(
        [leftStart, leftEnd, rightEnd, rightStart, leftStart],
        'horizontal'
      ),
      new THREE.MeshBasicMaterial({
        color: new THREE.Color('#fff'),
        toneMapped: false,
        side: THREE.DoubleSide,
      })
    );
  }, [data]);

  const handleTopBorderMouseMove = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation();
    const start = convertFlatVector3ToVector(data.points[2]);
    const end = convertFlatVector3ToVector(data.points[3]);
    const closestPoint = new THREE.Vector3();
    new THREE.Line3(start, end).closestPointToPoint(
      event.point,
      true,
      closestPoint
    );
    updateDirectionalInputValueForDistance(closestPoint);
    setSplitPosition(closestPoint);
    setIsPointerOver(true);
  };

  return (
    <>
      {isDisplay && (
        <mesh
          geometry={!isBlockSplitted ? splitWallGeometry : splitFacadeGeometry}
          material={C_WallMaterial}
          onPointerLeave={handlePointerLeave}
          onPointerDown={finishSplit}
          onPointerMove={handlePointerMove}
          userData={{
            ...data.userData,
            nodeType: NodeType.Wall,
            originalBuilding: {
              guid: buildingGUID,
            },
          }}
          visible={!isPointerOver}
        >
          {generateWallBorders()}
        </mesh>
      )}
      {isRoof && topBorder && (
        <primitive
          object={topBorder}
          onPointerDown={finishSplit}
          onPointerLeave={handlePointerLeave}
          onPointerMove={handleTopBorderMouseMove}
          visible={false}
          position={new THREE.Vector3(0, 0.0001, 0)}
        />
      )}
      {splitPosition && minMaxCoordinates && isPointerOver && (
        <>
          <SplitLine
            position={splitPosition}
            minMaxCoordinates={minMaxCoordinates}
          />
          <SplitFaces
            position={splitPosition}
            minMaxCoordinates={minMaxCoordinates}
            wallPoints={points}
            isBuildingClockwise={isBuildingClockwise}
          />
        </>
      )}
    </>
  );
};

export default React.memo(SplitWall);
