import React from 'react';
import Icon from '@ant-design/icons';
import { GetProps } from 'react-redux';

type CustomIconComponentProps = GetProps<typeof Icon>;

const GeoMarkerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
  >
    <path
      d="M6 0.666748C9.31371 0.666748 12 3.35304 12 6.66675C12 9.15024 10.1069 11.7532 6.4 14.5334C6.16296 14.7112 5.83704 14.7112 5.6 14.5334C1.89307 11.7532 0 9.15024 0 6.66675C0 3.35304 2.68629 0.666748 6 0.666748ZM6 4.66675C4.89543 4.66675 4 5.56218 4 6.66675C4 7.77132 4.89543 8.66675 6 8.66675C7.10457 8.66675 8 7.77132 8 6.66675C8 5.56218 7.10457 4.66675 6 4.66675Z"
      fill="#64BC50"
    />
  </svg>
);

export const GeoMarkerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={GeoMarkerSvg} {...props} />
);
