import React from 'react';
import { Form } from 'antd';
import { Avatar, IntusModal } from '@/shared/elements';
import { useAuth0 } from '@auth0/auth0-react';
import { useFetchUserQuery, useUpdateUserMutation } from '@/store/apis/userApi';
import { User } from '@/models/user';
import { IntusSelect } from '@/shared/elements';
import { unitSystems } from '@/models';
import { getUserName } from '@/shared/helpers/user-data';
import { CustomModalProps } from '@/models/shared.model';

const UserSettings: React.FC<CustomModalProps> = ({
  isOpen,
  setIsOpen,
  ...rest
}) => {
  const { user } = useAuth0();
  const [form] = Form.useForm<User>();
  const settings = useFetchUserQuery().data;

  const [updateUser] = useUpdateUserMutation();

  const handleFormChange = (newValues: Partial<User>) => {
    updateUser(newValues);
  };

  return (
    <IntusModal
      open={isOpen}
      title={'Settings'}
      footer={null}
      onCancel={() => setIsOpen(false)}
      {...rest}
    >
      <div
        className={
          'flex flex-col gap-[24px] min-h-[340px] mt-[32px] mb-[100px]'
        }
      >
        <Avatar
          imgSrc={user?.picture}
          width={60}
          height={60}
          fontSize={30}
          initial={getUserName(user!).at(0)}
        />
        <div>
          <div className={'text-sm my-1'}>Name</div>
          <div className={'text-xs'}>{getUserName(user!)}</div>
        </div>
        <div>
          <div className={'text-sm my-1'}>Email</div>
          <div className={'text-xs'}>{user?.email}</div>
        </div>
        <div className={'w-[100%] h-[1px] bg-light-gray-20'} />
        <div className={'w-40'}>
          <Form
            form={form}
            name="settings"
            initialValues={settings}
            onValuesChange={handleFormChange}
          >
            <div className={'text-sm my-1'}>Units</div>
            <Form.Item<User> name={'unitSystem'}>
              <IntusSelect options={unitSystems} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </IntusModal>
  );
};

export default UserSettings;
