import React from 'react';
import Icon from '@ant-design/icons';
import { GetProps } from 'react-redux';

type CustomIconComponentProps = GetProps<typeof Icon>;

const LocationSVG = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0.5C8.48528 0.5 10.5 2.51472 10.5 5C10.5 6.86262 9.0802 8.81485 6.3 10.9C6.12222 11.0333 5.87778 11.0333 5.7 10.9C2.9198 8.81485 1.5 6.86262 1.5 5C1.5 2.51472 3.51472 0.5 6 0.5ZM6 3.5C5.17157 3.5 4.5 4.17157 4.5 5C4.5 5.82843 5.17157 6.5 6 6.5C6.82843 6.5 7.5 5.82843 7.5 5C7.5 4.17157 6.82843 3.5 6 3.5Z"
      fill="currentColor"
    />
  </svg>
);
export const LocationIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={LocationSVG} {...props} />
);
