import { CanvasMapTypes } from '@/models';
import { useEffect, useState } from 'react';
import { sec } from '@/middlewares/security';
import axios from 'axios';
import { BASE_URL } from '@/config/environment/environment.dev';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCanvasMapType } from '@/store/slices/canvasModesSlice';
import { useNavigate } from 'react-router';
import { NotificationService } from '@/shared/services/error-handler.service';
import {
  getDefaultMapImage,
  getSatelliteMapImage,
  setDefaultMapImage,
  setSatelliteMapImage,
} from '@/store/slices/canvasMapSlice';

export const useLoadMap = (projectId: number) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const canvasMapType = useAppSelector(getCanvasMapType);
  const defaultMapImage = useAppSelector(getDefaultMapImage);
  const satelliteMapImage = useAppSelector(getSatelliteMapImage);

  const [isFetching, setIsFetching] = useState(true);

  const setMap = (data: string, mapType?: CanvasMapTypes) => {
    switch (mapType) {
      case CanvasMapTypes.light: {
        dispatch(setDefaultMapImage(data));
        break;
      }
      case CanvasMapTypes.satellite: {
        dispatch(setSatelliteMapImage(data));
      }
    }
  };

  const getMap = (mapType: CanvasMapTypes) =>
    mapType === CanvasMapTypes.light ? defaultMapImage : satelliteMapImage;

  const fetchMap = async (mapType: CanvasMapTypes) => {
    try {
      const token = await sec.getAccessTokenSilently()();
      const mapImage = await axios({
        url: `${BASE_URL}/projects/${projectId}/map`,
        headers: {
          authorization: `Bearer ${token}`,
        },
        params: {
          style: mapType,
        },
        responseType: 'blob',
        method: 'GET',
      });
      setMap(URL.createObjectURL(mapImage.data), mapType);
      setIsFetching(false);
    } catch (e) {
      navigate('/');
      NotificationService.error(e);
    }
  };

  useEffect(() => {
    fetchMap(CanvasMapTypes.light).then(() => {
      fetchMap(CanvasMapTypes.satellite);
    });
  }, []);

  return { map: getMap(canvasMapType) ?? '', isFetching };
};
