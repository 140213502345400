import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { NotificationService } from '@/shared/services/error-handler.service';

export const rtkQueryErrorHandler: Middleware =
  //eslint-disable-next-line
  () => (next) => (action: any) => {
    if (isRejectedWithValue(action)) {
      NotificationService.error({
        data: [
          {
            errorMessage: action.payload?.data?.[0]
              ? action.payload.data[0]?.errorMessage
              : 'Oops, something went wrong. Please try again later.',
          },
        ],
      });
    }

    return next(action);
  };
