import React from 'react';

const TwoDIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5625 15H1.65625V14.002L4.51367 11.1377C5.24284 10.4085 5.76237 9.80241 6.07227 9.31934C6.38672 8.83171 6.54395 8.31217 6.54395 7.76074C6.54395 7.19564 6.38216 6.75814 6.05859 6.44824C5.73503 6.13835 5.27474 5.9834 4.67773 5.9834C3.80273 5.9834 2.96419 6.3571 2.16211 7.10449V5.92871C2.92773 5.33171 3.81868 5.0332 4.83496 5.0332C5.70996 5.0332 6.39811 5.27018 6.89941 5.74414C7.40072 6.2181 7.65137 6.85384 7.65137 7.65137C7.65137 8.28027 7.47819 8.89551 7.13184 9.49707C6.78548 10.0941 6.16341 10.8369 5.26562 11.7256L3.00293 13.9609V13.9883H7.5625V15ZM9.83203 15V5.19727H12.5391C15.9935 5.19727 17.7207 6.79004 17.7207 9.97559C17.7207 11.4886 17.2399 12.7054 16.2783 13.626C15.3213 14.542 14.0384 15 12.4297 15H9.83203ZM10.9805 6.23633V13.9609H12.4434C13.7285 13.9609 14.7288 13.6169 15.4443 12.9287C16.1598 12.2406 16.5176 11.2653 16.5176 10.0029C16.5176 7.49186 15.1823 6.23633 12.5117 6.23633H10.9805Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TwoDIcon;
