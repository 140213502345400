import React from 'react';

export type VignetteParams = {
  boxHeight: number;
  boxWidth: number;
  height: number;
  width: number;
} | null;

interface VignetteProps {
  vignetteParams: VignetteParams;
}
const Vignette: React.FC<VignetteProps> = ({ vignetteParams }) => {
  return (
    <>
      <div
        className={'absolute bg-black opacity-50 top-0 left-0 z-10'}
        style={{
          width: vignetteParams?.boxWidth,
          height: vignetteParams?.height,
        }}
      />
      <div
        className={'absolute h-full bg-black opacity-50 bottom-0 right-0 z-10'}
        style={{
          width: vignetteParams?.boxWidth,
          height: vignetteParams?.height,
        }}
      />
      <div
        className={'absolute w-full bg-black opacity-50 top-0 right-0 z-10'}
        style={{
          height: vignetteParams?.boxHeight,
          width: vignetteParams?.width,
        }}
      />
      <div
        className={'absolute w-full bg-black opacity-50 bottom-0 left-0 z-10'}
        style={{
          height: vignetteParams?.boxHeight,
          width: vignetteParams?.width,
        }}
      />
    </>
  );
};

export default Vignette;
