import React, { useEffect, useMemo, useRef } from 'react';
import { WindowBorder } from '@/components/WindowCreator/elements/WindowBorder';
import { Group } from 'react-konva';
import {
  View,
  WindowElementType,
} from '@/components/WindowCreator/models/konva-model';
import Konva from 'konva';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getIsWindowNodeSelected,
  resetHoverArea,
  setHoverArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { KonvaEventObject } from 'konva/lib/Node';
import { WindowCreatorModes } from '@/models/shared.model';
import InnerWindow from '@/components/WindowCreator/elements/InnerWindow/InnerWindow';
import { useWindowCreatorSelect } from '@/components/WindowCreator/elements/shared/useWindowCreatorSelect';
import { generateClosedContourPointsFromPointArray } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { UnitSystemTypes } from '@/models';
import { WindowFrame } from '@/models/window-configurator.model';

interface OuterFrameProps {
  data: WindowFrame;
  scale: number;
  mode?: WindowCreatorModes;
  units: UnitSystemTypes;
  viewOnly?: boolean;
  view: View;
}

const OuterFrame = ({
  data,
  mode,
  scale,
  units,
  view,
  viewOnly = false,
}: OuterFrameProps) => {
  const dispatch = useAppDispatch();
  const outerFrameRef = useRef<Konva.Group>(null);

  const isSelected = useAppSelector(
    getIsWindowNodeSelected(outerFrameRef.current?._id)
  );
  const isSelectionMode = mode === WindowCreatorModes.Selection;
  const isAbleToSelect = !viewOnly && isSelectionMode;

  const { selectElementInWindowCreator } = useWindowCreatorSelect(
    outerFrameRef.current?._id ?? 0,
    data.points
  );

  const handleSelect = (event: KonvaEventObject<MouseEvent>) => {
    selectElementInWindowCreator(
      WindowElementType.Frame,
      outerFramePoints,
      event
    );
  };

  const outerFramePoints = useMemo(
    () => generateClosedContourPointsFromPointArray(data.points),
    [data]
  );

  useEffect(() => {
    isSelected &&
      selectElementInWindowCreator(WindowElementType.Frame, outerFramePoints);
  }, [isSelected, outerFramePoints]);

  return (
    <Group
      ref={outerFrameRef}
      onClick={handleSelect}
      onPointerEnter={() =>
        isAbleToSelect && dispatch(setHoverArea(outerFramePoints))
      }
      onPointerLeave={() => isAbleToSelect && dispatch(resetHoverArea())}
    >
      {/*If we have a single window it should be part of whole frame regarding clicks & actions*/}
      {data.innerWindows.length === 1 && (
        <InnerWindow
          view={view}
          independent={false}
          windowData={data.innerWindows[0]}
          outerFramePoints={outerFramePoints.map((p) => p[0])}
          units={units}
          scale={scale}
          frameColor={view === View.Outside ? data.outerColor : data.innerColor}
        />
      )}
      {outerFramePoints.map((point, idx) => {
        return (
          <WindowBorder
            idx={idx}
            points={point}
            color={view === View.Outside ? data.outerColor : data.innerColor}
            key={`outerBorder_${idx}`}
          />
        );
      })}
    </Group>
  );
};

export default OuterFrame;
