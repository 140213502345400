import React, { useEffect } from 'react';

export const useOutsideMouseDown = (
  ref: React.MutableRefObject<HTMLElement>,
  handler: (ev?: MouseEvent) => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    };
    document.addEventListener('mousedown', (e) => handleClickOutside(e));
    return () => {
      document.removeEventListener('mousedown', (e) => handleClickOutside(e));
    };
  }, [ref]);
};
