import React from 'react';

import { NodeType } from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import TotalSurface from '@/shared/elements/TotalSurface/TotalSurface';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import {
  StoreySearchResults,
  useFindNodeData,
} from '@/shared/hooks/useFindNodeData';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

const StoreyFrame = ({ storeysGUID }: { storeysGUID: string[] }) => {
  const { getNodeData } = useFindNodeData();

  const selectedStoreysData = storeysGUID.map(
    (storeyGUID) =>
      getNodeData({
        guid: storeyGUID,
        nodeType: NodeType.Storey,
      }) as StoreySearchResults
  );

  const {
    getFloorHeightMetric,
    getFacadesAreaMetricForStoreys,
    getGrossInternalAreaMetricForStoreys,
  } = useFrameProperties();

  const getStoreyName = (storey: StoreySearchResults) => {
    return storey?.name ?? `Floor ${storey?.storeyNumber}`;
  };

  return (
    <>
      <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {selectedStoreysData.length > 1
            ? `Floor (${selectedStoreysData.length})`
            : getStoreyName(selectedStoreysData[0])}
        </span>
      </div>

      <div
        className={
          'flex flex-col text-xs border border-l-0 border-solid border-light-gray-20 !bg-white overflow-y-auto text-dark-gray-100'
        }
      >
        <MetricsHeader />
        <PropertyList
          alignValueLeft
          properties={[{ name: 'Height', value: getFloorHeightMetric() }]}
        />
      </div>

      <TotalSurface
        facadesArea={getFacadesAreaMetricForStoreys(selectedStoreysData)}
        grossInternalArea={getGrossInternalAreaMetricForStoreys(
          selectedStoreysData
        )}
      />
    </>
  );
};

export default StoreyFrame;
