import React from 'react';
import { EditableMetric } from '../EditableMetric/EditableMetric';
import { InputProps } from 'antd';

export type FrameProperty = {
  name: string;
  value: string;
  units?: string;
  isEditable?: boolean;
  onEdit?: (value: string) => void;
  onSubmit?: () => void;
  inputProps?: InputProps;
  min?: number;
  max?: number;
  staticValue?: boolean;
};

export interface PropertyListProps {
  properties: FrameProperty[];
  alignValueLeft?: boolean;
}

const PropertyList: React.FC<PropertyListProps> = ({
  properties,
  alignValueLeft,
}) => {
  return (
    <div
      className={`flex flex-col justify-center mb-[10px] ${alignValueLeft ? 'mt-2' : 'mt-3'}`}
    >
      {properties.map((property) => {
        return (
          <div
            key={property.name}
            className={`flex flex-wrap justify-between min-h-6 px-3`}
          >
            <span className="font-light mr-2 leading-6 whitespace-nowrap flex mb-[2px]">
              {property.name}
            </span>
            <div
              className={`font-normal mb-[2px] ${
                alignValueLeft ? 'text-left w-24' : 'text-right'
              } ${property.units || alignValueLeft ? 'flex gap-1' : ''} ${
                !alignValueLeft && !property.units ? 'mr-3' : ''
              } break-words flex items-center`}
            >
              {property.isEditable && property.onEdit && property.onSubmit ? (
                <EditableMetric
                  value={property.value}
                  onEdit={property.onEdit}
                  onSubmit={property.onSubmit}
                  inputProps={property.inputProps}
                  min={property.min}
                  max={property.max}
                  staticValue={property.staticValue}
                />
              ) : (
                <>
                  <span
                    className={`inline-block w-full text-left ${alignValueLeft && !property.isEditable ? 'pl-1' : ''}`}
                  >
                    {property.value}
                  </span>
                  {property.units ? (
                    <span className="font-light text-black-40">
                      {property.units}
                    </span>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PropertyList;
