import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Geometry } from '@turf/turf';
import { FormInstance } from 'antd';
import { notification } from 'antd/lib';

import './ProjectMap.scss';
import { IntusIconButton, IntusTooltip } from '@/shared/elements';
import { MapModes, ProjectCreateFormData } from '@/models';
import { AreaIcon, CursorIcon, GeoMarkerIcon } from '@/shared/icons';
import { drawStyle, StaticMode } from './project-map.variables';
import Vignette, { VignetteParams } from './Vignette';

const ProjectMap = ({
  form,
}: {
  form?: FormInstance<ProjectCreateFormData>;
}) => {
  const mapContainer = useRef(null as unknown as HTMLDivElement);
  const map = useRef(null as unknown as mapboxgl.Map);

  const [currentMapMode, setCurrentMapMode] = useState<MapModes>(
    MapModes.MoveMode
  );
  const [vignetteParams, setVignetteParams] = useState<VignetteParams>(null);

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    defaultMode: 'simple_select',
    styles: drawStyle,
    modes: {
      ...MapboxDraw.modes,
      static: StaticMode,
    },
  });

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
    language: 'english',
    marker: false,
  });

  const onDrawCreate = () => {
    const mapBoxGeometry = draw?.getAll()?.features[0]?.geometry as Geometry;
    const coordinates = mapBoxGeometry?.coordinates;
    if (Array.isArray(coordinates[0]) && coordinates?.[0]?.length < 3) {
      draw.deleteAll();
      setCurrentMapMode(MapModes.MoveMode);
      return notification.error({
        message: 'Error',
        description: 'The minimum number of set points must be 3',
      });
    }
    form?.setFieldValue('boundingBox', getBoundingBoxCoordinates());
    form?.setFieldValue('constructionSite', coordinates[0]);
  };

  const clearPolygon = () => {
    form?.setFieldValue('boundariesCheck', false);
    form?.setFieldValue('constructionSite', null);
    draw.deleteAll();
  };

  const enableMapDrawPolygonMode = () => {
    clearPolygon();
    map.current.boxZoom.disable();
    map.current.scrollZoom.disable();
    map.current.dragPan.disable();
    map.current.dragRotate.disable();
    map.current.keyboard.disable();
    map.current.doubleClickZoom.disable();
    map.current.touchZoomRotate.disable();
    draw.changeMode('draw_polygon');
  };

  const enableMapMoveMode = () => {
    clearPolygon();
    draw.changeMode('simple_select');
    map.current.boxZoom.enable();
    map.current.scrollZoom.enable();
    map.current.dragPan.enable();
    map.current.keyboard.enable();
    map.current.doubleClickZoom.enable();
  };

  const onSearchResult = () => {
    enableMapMoveMode();
    setCurrentMapMode(MapModes.MoveMode);
    form?.setFieldValue('zoomCheck', false);
  };

  const calculateVignette = () => setVignetteParams(getZoomVignetteParams());

  const handleAreaMode = () => {
    setCurrentMapMode(MapModes.BoundariesMode);
    map.current.fire('draw.custom__disable_moving');
  };

  const handleMoveMode = () => {
    setCurrentMapMode(MapModes.MoveMode);
    map.current.fire('draw.custom__enable_moving');
  };

  const onDisableDrawMoving = () => {
    draw?.modes?.STATIC && draw.changeMode('static');
  };

  const getDistance = () => {
    const w = mapContainer?.current?.clientWidth;
    const h = mapContainer?.current?.clientHeight;

    const yDistance = map.current
      .getBounds()
      .getSouthEast()
      .distanceTo(map.current.getBounds().getSouthWest());

    const xDistance = map.current
      .getBounds()
      .getNorthWest()
      .distanceTo(map.current.getBounds().getSouthWest());

    return w > h ? xDistance : yDistance;
  };

  const getBoundingBoxCoordinates = () => {
    const bboxParams = getZoomVignetteParams();
    const bottomLeft = {
      x: bboxParams.boxWidth,
      y: mapContainer.current.clientHeight - bboxParams.boxHeight,
    };
    const topRight = {
      x: mapContainer.current.clientWidth - bboxParams.boxWidth,
      y: bboxParams.boxHeight,
    };

    const blCoordinates = map.current.unproject([bottomLeft.x, bottomLeft.y]);
    const trCoordinates = map.current.unproject([topRight.x, topRight.y]);

    return blCoordinates.toArray().concat(trCoordinates.toArray());
  };

  const calculateDistance = () => {
    calculateVignette();
    form?.setFieldValue('zoomCheck', getDistance() <= 1600 || false);
  };

  const onDblClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    e.preventDefault();
  };

  const onResize = () => {
    calculateDistance();
    enableMapMoveMode();
  };

  const onKeydown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape': {
        enableMapMoveMode();
        setCurrentMapMode(MapModes.MoveMode);
        return;
      }
      case 'Delete':
      case 'Backspace': {
        if (
          (event.target as HTMLElement).className !==
          'mapboxgl-ctrl-geocoder--input'
        ) {
          enableMapDrawPolygonMode();
        }
        return;
      }
    }
  };

  const getZoomVignetteParams = () => {
    const w = mapContainer?.current?.getBoundingClientRect()?.width;
    const h = mapContainer?.current?.getBoundingClientRect()?.height;
    const box = w > h ? h * 0.6 : w * 0.6;

    const boxWidth = (w - box) / 2;
    const boxHeight = (h - box) / 2;

    const width = w - boxWidth;
    const height = h - boxHeight;

    return {
      boxHeight,
      boxWidth,
      height,
      width,
    };
  };

  const mapModes = [
    {
      name: 'Move',
      icon: (
        <CursorIcon
          fill={currentMapMode === MapModes.MoveMode ? '#fff' : '#000'}
        />
      ),
      isActive: currentMapMode === MapModes.MoveMode,
      handler: handleMoveMode,
    },
    {
      name: 'Outline',
      icon: (
        <AreaIcon
          fill={currentMapMode === MapModes.BoundariesMode ? '#fff' : '#000'}
        />
      ),
      isActive: currentMapMode === MapModes.BoundariesMode,
      handler: handleAreaMode,
      disabled: !form?.getFieldValue('zoomCheck'),
    },
  ];

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v11',
      maxZoom: 18,
    });

    map.current.addControl(geocoder, 'top-left');
    map.current.addControl(draw, 'top-right');

    map.current.dragRotate.disable();
    geocoder.setFlyTo({
      speed: 2,
    });

    geocoder.on('result', onSearchResult);
    map.current.on('draw.create', onDrawCreate);
    map.current.on('wheel', calculateDistance);
    map.current.on('dblclick', onDblClick);
    map.current.on('moveend', calculateDistance);
    //everything below is a custom events
    map.current.on('draw.custom__disable_moving', enableMapDrawPolygonMode);
    map.current.on('draw.custom__enable_moving', enableMapMoveMode);
    map.current.on('draw.custom__disable_draw_moving', onDisableDrawMoving);

    window.addEventListener('resize', onResize);
    mapContainer.current.addEventListener('keydown', onKeydown);

    return () => {
      mapContainer?.current?.removeEventListener('keydown', onKeydown);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (form?.getFieldValue('constructionSite')) {
      form?.setFieldValue('boundariesCheck', true);
      map.current.fire('draw.custom__disable_draw_moving');
    }
    //here we need a dependence on draw, because without it, draw would not be disabled after creating the polygon
  }, [draw, form]);

  return (
    <div className="h-full relative">
      <div
        ref={mapContainer}
        className="map-container absolute w-full h-full"
      />
      <div className="Project__actions absolute top-2 right-2.5 z-20 flex gap-2">
        {mapModes.map((mode) => (
          <IntusTooltip title={mode.name} placement={'bottom'} key={mode.name}>
            <IntusIconButton
              onClick={mode.handler}
              icon={mode.icon}
              className={
                mode.isActive ? '!bg-light-green-100 !border-none' : ''
              }
              disabled={mode?.disabled}
            />
          </IntusTooltip>
        ))}
      </div>
      <div className="absolute left-0 bottom-0 z-20 flex items-center gap-1 bg-white bg-opacity-50 p-2 leading-6 text-xs">
        Find and outline the site boundaries using
        {<AreaIcon />} tool
      </div>
      {form?.getFieldValue('zoomCheck') && (
        <>
          <Vignette vignetteParams={vignetteParams} />
          {currentMapMode === MapModes.MoveMode && (
            <GeoMarkerIcon
              className={'mapbox-geo-icon absolute top-1/2 left-1/2 z-10'}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProjectMap;
