import React from 'react';
import { Line } from 'react-konva';
import { BUILDING_SELECTED_CONTOUR_COLOR } from '@/shared/materials';

interface WallViewProps {
  wallWidth: number;
  wallHeight: number;
  scale: number;
  initialScale: number;
}

const WallView: React.FC<WallViewProps> = ({
  wallWidth,
  wallHeight,
  scale,
  initialScale,
}) => {
  const wallPoints = [
    [0, 0],
    [wallWidth, 0],
    [wallWidth, wallHeight],
    [0, wallHeight],
  ];
  return (
    <>
      <Line
        points={wallPoints.flat()}
        fill={'#fff'}
        closed
        stroke={`#${BUILDING_SELECTED_CONTOUR_COLOR.getHexString()}`}
        strokeWidth={(initialScale * 20) / scale}
      />
    </>
  );
};

export default WallView;
