import { useState, useEffect } from 'react';
import Konva from 'konva';
import { publish } from '@/core/events';
import { SET_WINDOW_CONTEXT_MENU_POSITION } from '@/core/event-names';
import { subscribe } from '@/core/events';

export const useContextMenu = (isSelected: boolean) => {
  const [shouldPublishContextMenu, setShouldPublishContextMenu] =
    useState(false);

  const handleContextMenu = (event: Konva.KonvaEventObject<MouseEvent>) => {
    event.cancelBubble = true;
    const stage = event.target.getStage();
    const pointerPosition = stage?.getPointerPosition();

    if (pointerPosition && (!isSelected || shouldPublishContextMenu)) {
      publish(SET_WINDOW_CONTEXT_MENU_POSITION, pointerPosition);
    }

    if (isSelected) {
      setShouldPublishContextMenu(!shouldPublishContextMenu);
    }
  };

  useEffect(() => {
    subscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
      if (evt.detail === null) {
        setShouldPublishContextMenu(true);
      }
    });
    return () => {
      subscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
        if (evt.detail === null) {
          setShouldPublishContextMenu(true);
        }
      });
    };
  }, []);

  useEffect(() => {
    setShouldPublishContextMenu(false);
  }, [isSelected]);

  return { handleContextMenu };
};
