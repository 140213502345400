import { Vector2 } from 'three';
import * as THREE from 'three';
import { Camera } from '@react-three/fiber/dist/declarations/src/core/utils';

export const hideSelectionBoxArea = () => {
  const helpers = document.getElementsByClassName('selectBox');
  for (let i = 0; i < helpers.length; i++) {
    (helpers[i] as HTMLElement).style.display = 'none';
  }
};

export const displaySelectionBoxArea = () => {
  const helpers = document.getElementsByClassName('selectBox');
  for (let i = 0; i < helpers.length; i++) {
    (helpers[i] as HTMLElement).style.display = 'initial';
  }
};

export const getCursorCoordinatesOnOrthographicSystem = (
  event: PointerEvent,
  gl: THREE.WebGLRenderer
): Vector2 => {
  const rect = gl.domElement.getBoundingClientRect();
  const pointer = new THREE.Vector2();
  pointer.x = ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
  pointer.y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

  return pointer;
};

export const getObjectCoordinatesOnOrthographicSystem = (
  object: THREE.Mesh,
  camera: Camera,
  gl: THREE.WebGLRenderer
) => {
  let objectPosition = new THREE.Vector3();
  const newObj = object.clone();
  const center = new THREE.Vector3();

  newObj.geometry.boundingBox?.getCenter(center);
  newObj.position.set(center.x, center.y, center.z);
  newObj.updateMatrixWorld(true);
  objectPosition = objectPosition.setFromMatrixPosition(newObj.matrixWorld);
  objectPosition.project(camera);

  const widthHalf = window.innerWidth / 2;
  const heightHalf = window.innerHeight / 2;

  objectPosition.x = objectPosition.x * widthHalf + widthHalf;
  objectPosition.y = -(objectPosition.y * heightHalf) + heightHalf;
  objectPosition.z = 0;

  const rect = gl.domElement.getBoundingClientRect();
  const orthographicPosition = new THREE.Vector2();
  orthographicPosition.x =
    ((objectPosition.x - rect.left) / (rect.right - rect.left)) * 2 - 1;
  orthographicPosition.y =
    -((objectPosition.y - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
  return orthographicPosition;
};
